import { createAsyncThunk } from "@reduxjs/toolkit";
import { ExamResult, AddExamResult } from "../../utils/types/examManager";
import { db } from "../firebase"; // Ensure this is your Firebase config
import { collection, doc, getDocs, setDoc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";

// Fetch exam results
export const getResults = createAsyncThunk<
  ExamResult[] | null,
  void,
  { rejectValue: string[] }
>("exam/results", async (_, { rejectWithValue }) => {
  try {
    const querySnapshot = await getDocs(collection(db, "examResults"));
    const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as ExamResult[];
    console.log("Results ", results);
    return results??[];
  } catch (error) {
    console.error("Failed to fetch results", error);
    return rejectWithValue(["Failed to fetch data"]);
  }
});

// Add exam result
export const addExamResults = createAsyncThunk<
  ExamResult,
  AddExamResult,
  { rejectValue: string[] }
>("exams/add", async (data, { rejectWithValue }) => {
  try {
    const docRef = doc(db, "examResults", data.id);
    await setDoc(docRef, data);
    return {  ...data };
  } catch (error) {
    console.error("Failed to create result", error);
    return rejectWithValue(["Failed to create result"]);
  }
});

// Update exam result
export const updateExamResult = createAsyncThunk<
  ExamResult,
  { data: AddExamResult; id: string },
  { rejectValue: string[] }
>("exam/result/update", async ({ data, id }, { rejectWithValue }) => {
  try {
    const docRef = doc(db, "examResults", id);
    await updateDoc(docRef, data);
    const updatedDoc = await getDoc(docRef);
    return { id, ...updatedDoc.data() } as ExamResult;
  } catch (error) {
    console.error("Failed to update result", error);
    return rejectWithValue(["Failed to update result"]);
  }
});

// Delete exam result
export const deleteResults = createAsyncThunk<
  { message: string; id: string },
  string,
  { rejectValue: string[] }
>("exam/result/delete", async (id, { rejectWithValue }) => {
  try {
    await deleteDoc(doc(db, "examResults", id));
    return { message: "Result deleted successfully", id };
  } catch (error) {
    console.error("Failed to delete result", error); 
    return rejectWithValue(["Failed to delete result"]); 
  }
});