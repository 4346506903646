import React from "react";
import { Heading, HeroContainer } from "./Hero.styles";
// import bg from "../../../assets/About/aboutBg.png";
// import { Box } from "@mui/material";

const Hero = () => {
  return (
    <HeroContainer>
      <Heading variant="h1">About Us</Heading>
    </HeroContainer>
  );
};

export default Hero;
