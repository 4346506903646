import React, { Suspense, useEffect, useState } from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../styles/theme";

import MobileDrawer from "../Drawer/MobileDrawer";


import { useLocation } from "react-router-dom";

import AdministratorLeftSection from "../Dashboard/LeftSection/Administrator";
import LeftSection from "../Dashboard/LeftSection/Teacher";
import Loading from "../../utils/loading";


type WebsiteLayout = {
  children: React.ReactNode;
  toggleTheme: boolean;
  setToggleTheme: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  light: boolean;
  setLight: React.Dispatch<React.SetStateAction<boolean>>;
};

const Layout = ({
  children,
  toggleTheme,
  setToggleTheme,
  light,
  setLight,
}: WebsiteLayout) => {
  const [toggle, setToggle] = useState(false);
  const router = useLocation();
  const pathname = router.pathname.split("/");
  const userType = pathname[2];
  const inDashboard = router.pathname.includes("/dashboard");
  const [shrink, setShrink] = useState(false);

  const [rightWidth, setRightWidth] = useState(9);
  const handleShrink = () => {
    setShrink(!shrink);
  };

  useEffect(() => {
    if (shrink) setRightWidth(11);
    else setRightWidth(9);
  }, [shrink]);
  return (
    <Wrappper>
      <Navbar
        toggle={toggle}
        setToggle={setToggle}
        toggleTheme={toggleTheme}
        setToggleTheme={setToggleTheme}
        light={light}
        setLight={setLight}
      />
      <MobileDrawer toggle={toggle} setToggle={setToggle} />
      {inDashboard ? (
        <BodyContainer>
          <Grid container item spacing={{ sm: 0, md: 0, laptop: 0, lg: 2 }}>
            <Grid item lg="auto" md="auto" sm={12} xs={12}>
              <LeftContainer>
              {userType == "teacher" ? (
                  <>
                    <LeftSection
                      shrink={shrink}
                      handleShrink={handleShrink}
                    />
                  </>
                ) : userType == "administrator" ? (
                  <AdministratorLeftSection
                  shrink={shrink}
                  handleShrink={handleShrink} />
                ): (
                  ""
                )}
              </LeftContainer>
            </Grid>
            <Grid item lg={rightWidth} md={rightWidth} sm={12} xs={12}>
              <RightContainer>
                <Suspense fallback={<Loading />}>
                 
                  <MainContainer>{children}</MainContainer>
                </Suspense>
              </RightContainer>
            </Grid>
          </Grid>
        </BodyContainer>
      ) : (
        <div>{children}</div>
      )}

      <Footer />
    </Wrappper>
  );
};

export default Layout;

export const Wrappper = styled(Box)({
  maxWidth: "100%",
  margin: "0 auto",
  position: "relative",
});
export const BodyContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: "50px 15px 15px 15px",
  // width: "100%",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("lg")]: {
    padding: "50px 5px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 15px",
  },
}));
export const LeftContainer = styled(Box)({
  height: "100%",
  [theme.breakpoints.up("md")]: {
    minHeight: "600px",
  },
});
export const RightContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    padding: "0 0 0 10px",
  },
  color: "#000",
}));
export const MainContainer = styled(Box)({
  padding: "20px 0",
});
