import img1 from "../../../assets/blog/blog1.png";
import img2 from "../../../assets/blog/blog2.png";
import img3 from "../../../assets/blog/blog3.png";
import img4 from "../../../assets/blog/blog4.png";
import author1 from "../../../assets/blog/author1.png";
import author2 from "../../../assets/blog/author2.png";

const Data = [
  {
    img: img1,
    blogHeading: "Impact of management in Nigeria Banking",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "vehicula, sodale. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex.  ",
  },
  {
    img: img2,
    blogHeading: "Impact of management in Nigeria Banking",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex.  ",
  },
  {
    img: img3,
    blogHeading: "Impact of management in Nigeria Banking",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex.  ",
  },
  {
    img: img4,
    blogHeading: "Impact of management in Nigeria Banking",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex.  ",
  },
];
export default Data;

export const TopData = [
  {
    img: author1,
    blogHeading: "Morgan Andrew",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex.  ",
  },
  {
    img: author2,
    blogHeading: "Morgan Andrew",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex.  ",
  },
];
