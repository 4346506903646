import { Box, Button, Card, Icon, Input, styled, TextField, Typography } from "@mui/material";

export const ContactScreenContainer = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',marginBottom:'4%'
}))

export const ContactContainer = styled(Box)(({theme})=>({marginLeft:'15%',backgroundColor:'white',paddingTop:'3%',paddingLeft:'2%',paddingRight:'2%',
    display:'flex',flexDirection:'row',minWidth:'30%',maxWidth:'70%',width:'100%',justifyContent:'space-around',marginTop:'-4%'
  ,  [theme.breakpoints.down('md')]: {
    display:'flex',flexDirection:'column',minWidth:'50%',maxWidth:'100%',width:'90%',marginLeft:'5%'
    }
 
}))

export const ContactContainerLeft = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',minWidth:'30%',maxWidth:'90%',width:'100%',marginRight:'16%',
    [theme.breakpoints.down('md')]: {
    minWidth:'30%',maxWidth:'90%',width:'100%',marginRight:'0%',marginLeft:'2%'
} 
}))

export const ContactCardLeftup = styled(Card)(({theme})=>({
    display:'flex',flexDirection:'row',minWidth:'30%',maxWidth:'100%',width:'100%',padding:'15%',marginBottom:'10%',
   
}))
export const ContactCol = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',marginLeft:'2%'
}))

export const ContactCardLefdown= styled(Card)(({theme})=>({
    display:'flex',flexDirection:'row',minWidth:'30%',maxWidth:'100%',width:'100%',padding:'15%',marginTop:'3%'
}))

export const ContactCardLeftTitle = styled(Typography)(({theme})=>({
   fontSize:'2rem',fontWeight:'bold',color:'blueviolet',
   [theme.breakpoints.down('md')]: {
  fontSize:'1.2rem'
}
}))

export const ContactCardLeftText = styled(Typography)(({theme})=>({
    fontSize:'1rem',fontWeight:'300',color:'black'
 }))

 export const ContactCardLeftIcon = styled(Icon)(({theme})=>({
   color:'blueviolet',border:'2px solid blueViolet',height:'5vh',width:'20%',paddingTop:'2%',borderRadius:'800px',marginRight:'3%'
 }))
 export const ContactCardLeftButton = styled(Button)(({theme})=>({
    fontSize:'1rem',fontWeight:'bold',color:'white',backgroundColor:'blueviolet',marginTop:'10%'
 }))
 export const ContactContainerRight = styled(Card)(({theme})=>({marginLeft:'-10%', 
    display:'flex',flexDirection:'column',minWidth:'50%',maxWidth:'90%',width:'100%',paddingTop:'4%',paddingLeft:'1%',paddingRight:'3%'
    ,[theme.breakpoints.down('md')]: {
        marginTop:'6%',marginBottom:'6%',paddingBottom:'5%',marginLeft:'2%'
      }
}))

export const ContactCardRightTitle = styled(Typography)(({theme})=>({
    fontSize:'2rem',fontWeight:'bold',color:'blueviolet',textAlign:'center',
    [theme.breakpoints.down('md')]: {
      fontSize:'1rem'
    }
 }))
 
 export const ContactCardRightText = styled(Typography)(({theme})=>({
     fontSize:'1rem',fontWeight:'300',color:'black',textAlign:'center' 
    , [theme.breakpoints.down('md')]: {
        fontSize:'0.8rem'
      }   
  }))

  export const ContactCardRightrow = styled(Box)(({theme})=>({
   display:'flex',flexDirection:'row'
 }))
 
 export const ContactCardinput = styled(Input)(({theme})=>({
    height: '5vh',
    marginTop :'3%',
    marginBottom :'1%',
    boxShadow :'0px 2px 5px rgba(0,0,0,0.2)',
    marginRight : '1rem',
    width :'100%',minWidth:'30%',maxWidth:'90%',
    backgroundColor :'white',
    border :'1px solid blueViolet',
    marginLeft :'7%',
    paddingLeft :'1%',
    borderRadius :'0px',
     [theme.breakpoints.down('md')]: {
       borderRadius:'1px',fontSize:'0.5rem',paddingLeft:'2%'
      } 
  }))

  export const ContactCardTextfield = styled(Input)(({theme})=>({
    height: '25vh',
    marginTop :'3%',
    marginBottom :'1%',
    boxShadow :'0px 2px 5px rgba(0,0,0,0.2)',
    marginRight : '1rem',
    width :'90%',
    backgroundColor :'white',
    border :'1px solid blueViolet',
    marginLeft :'7%',
    paddingLeft :'6%', paddingBottom :'5%',paddingTop:'8%',
    borderRadius :'0px',
  }))