import * as React from "react";
import { useState, useEffect } from "react";

import { Box, IconButton, SwipeableDrawer } from "@mui/material";
import { MenuRounded, CloseRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
type DrawerConent = {
  children: React.ReactNode;
};

const LeftDrawer = ({ children }: DrawerConent) => {
  const router = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    setOpenDrawer(false);
  }, [router]);
  return (
    <div>
      <React.Fragment key={"left"}>
        <MenuIconButton onClick={() => setOpenDrawer(true)}>
          <MenuRounded />
        </MenuIconButton>
        <SwipeableDrawer
          anchor={"left"}
          open={openDrawer}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          PaperProps={{
            sx: { minWidth: "270px",maxWidth:'80%' },
          }}
        >
          <CloseContainer>
            {children}
            <InnerCloseContainer>
              <CloseIconButton onClick={() => setOpenDrawer(false)}>
                <CloseRounded />
              </CloseIconButton>
            </InnerCloseContainer>
          </CloseContainer>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default LeftDrawer;

export const MenuIconButton = styled(IconButton)({
  borderRadius: 0,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  width: 40,
  height: 40,
  color: "#2D2D2D",
  cursor: "pointer",
  background: "#EAF0FA",
  ":hover": {
    background: "#accaf9",
  },
});
export const CloseIconButton = styled(IconButton)({
  width: 30,
  height: 30,
  color: "#2D2D2D",
  cursor: "pointer",
  background: "#EAF0FA",
  ":hover": {
    background: "#accaf9",
  },
});
export const CloseContainer = styled(Box)({
  position: "relative",
  padding: "10px 0",
  display: "flex",
  justifyContent: "center",
  width: "100%",
});
export const InnerCloseContainer = styled(Box)({
  position: "absolute",
  top: 0,
  right: 10,
  width: "100%",
  paddingTop: "10px",
  display: "flex",
  justifyContent: "end",
});
