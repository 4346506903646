import { Box, Button, Card, styled, Typography } from "@mui/material";


export const LiveContainer =styled(Box)(({theme})=>({
display:'flex',flexDirection:'column'}))
export const LiveHeroColumn =styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column'
, width:'50%'
}))
export const LiveHero = styled(Card)(({theme})=>({
    display: 'flex',
    flexDirection: 'row',marginBottom:'6%', width: '90%',
        minWidth: '30%',
        maxWidth: '100%',marginLeft:'5%',paddingTop:'3%',paddingLeft:'5%',paddingBottom:'5%',
   

    [theme.breakpoints.down('md')]: {
        overflow: 'hidden',flexDirection:'column',
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '40vh', // Adjust height for smaller screens
        maxHeight: '150vh',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center',  // Center the background image on smaller screens
    }
}))

export const LiveTitle = styled(Typography)(({theme})=>({
    fontSize: '3rem',
    fontWeight: 'bold',
    fontFamily: 'serif',
    textAlign: 'start',
    color: 'blueViolet',
    marginBottom: '1rem',animation:'fadeIn 1s', 
    '@keyframes fadeIn':{
        '0%':{
            opacity:0
        }
        ,   '100%':{
            opacity:1
        }
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem', marginBottom:'1rem'
    }
}))

export const LiveText = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: '400',
    color: 'black',
    
    textAlign: 'start',
    
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));

export const LiveImage = styled(Box)(({theme})=>({
    height:'30vh',
    width:'100%',maxWidth:'50%',minWidth:'20%',backgroundSize:'contain',backgroundRepeat:'no-repeat',borderRadius:'10px'
}))

export const LiveImages = styled(Box)(({theme})=>({
    display:'flex',marginTop:'2%',
   flexDirection:'row',justifyContent:'space-around',width:'100%',maxWidth:'60%',minWidth:'20%',
}))

export const LiveButton = styled(Button)(({ theme }) => ({
    color: 'white',marginTop:'3%',marginBottom:'6%',
    fontSize: '1rem',
    height: '10vh',
    border: '1px solid white',
    width: '50%',
    minWidth: '10%',
    maxWidth: '80%', 
    paddingLeft: '1%',marginLeft:'3%',background:'linear-gradient(to bottom,blueViolet,white)',
    [theme.breakpoints.down('md')]: {
        width: '45%', // Make buttons full-width on smaller screens
        marginLeft:'25%',height:'6vh',fontSize:'0.8rem', paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));

