import EmailVerfication from "../components/emailVerfication/EmailVerfication";


const email = () => {
 
  return (
    <>
      <EmailVerfication />
    </>
  );
};

export default email;
