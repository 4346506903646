import { Box, Card, styled, Typography } from "@mui/material";
import img1 from '../../../../assets/images/rev.jpg';

export const ProphetContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '7%',
}));

export const ProphetCard = styled(Card)(({ theme }) => ({
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    minWidth: '50%',
    width: '70%',
    maxWidth: '100%',
    height: '70vh',
    marginLeft: '15%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack elements vertically on smaller screens
        alignItems: 'center',
        marginLeft: '3%',height: '130vh',minWidth: '50%',
width: '90%',
    maxWidth: '100%',marginTop:'6%'
    },
}));

export const ProphetCardImage = styled(Box)(({ theme }) => ({
    minWidth: '50%',
    width: '50%',
    maxWidth: '60%',
    height: '70vh',
    backgroundSize: 'cover', // Ensure image scales proportionally
    backgroundImage: `url(${img1})`,
    backgroundRepeat: 'no-repeat','&:hover':{transform:'scale(1.1)',},
    [theme.breakpoints.down('md')]: {
        width: '150%', maxWidth: '100%',minWidth:'80%',   marginTop:'3%',   
        height: '80vh',  backgroundSize: '100%',marginBottom:'4%'// Adjust height for smaller screens
    },
}));

export const ProphetTitle = styled(Typography)(({ theme }) => ({
    marginTop: '0%',
    paddingBottom: '10%',
    fontSize: '2.3rem',
    fontWeight: 'bold',
    fontFamily: 'serif',
    textAlign: 'center',
    color: 'black',
    [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem', // Adjust font size for smaller screens
        marginTop: '50%',paddingBottom:'1%'
    },
}));

export const ProphetText = styled(Typography)(({ theme }) => ({
    marginLeft: '3%',
    paddingBottom: '10%',
    fontSize: '1.5rem',
    fontWeight: '500',
    fontFamily: 'serif',
    textAlign: 'start',
    color: 'blueViolet',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
        marginTop: '2rem',
        textAlign: 'center', // Center text on smaller screens
        marginLeft: '0%',
    },
}));

export const ProphetCol = styled(Box)(({ theme }) => ({
    marginTop: '0%',
    paddingBottom: '10%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        marginTop: '-40%',
    },
}));