import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// Import your reducers
import authReducer from "./auth/authSlice";
import ContactFormReducer from "./contactForm/contactFormSlice";
import faqsReducer from "./faqManagement/questions/questionsSlice";
import faqCategoriesReducer from "./faqManagement/categories/categoriesSlice";
import contactsReducer from "./contacts/contactsSlice";
import usersReducer from "./userManagement/userManagementSlice";
import newslettersReducer from "./newsletters/newslettersSlice";
import newsLetterReducer from "./newsLetterSubscription/newsLetterSlice";
import examRestReducer from "./examManager/examManagerSlice"
import blogPostsReducer from "./blogManagement/posts/postsSlice";
import blogCategoriesReducer from "./blogManagement/categories/categoriesSlice";
import blogTagsReducer from "./blogManagement/tags/tagsSlice";
import classReducer from "./classManager/classSlice";
// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  newsLetter: newsLetterReducer,
  users: usersReducer,
  classes: classReducer,
  newsletters: newslettersReducer,
  faqCategories: faqCategoriesReducer,
  faqs: faqsReducer,
  ContactForm: ContactFormReducer,
  contacts: contactsReducer,
  examResults: examRestReducer,
  blogPosts: blogPostsReducer,
  blogCategories: blogCategoriesReducer,
  blogTags: blogTagsReducer,

 });

// Function to load state from local storage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined; // Return undefined to let Redux initialize with default state
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined; // Return undefined in case of errors
  }
};

// Function to save state to local storage
const saveState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (err) {
    // Handle errors if needed
  }
};

// Load initial state from local storage
const preloadedState = loadState();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState, // Use preloaded state if available
}); 

// Subscribe to store changes and save state on every update
store.subscribe(() => {
  saveState(store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Typed versions of useDispatch and useSelector
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
