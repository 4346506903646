import { FormControlLabel, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import img2 from "../../assets/login/message.png";
import img3 from "../../assets/login/star.png";
import img1 from "../../assets/images/tec.png";

import { Link } from "react-router-dom";

import {
  Buttons,
  ColorForm,
  FieldIconContainer,
  ForgetContainer,
  HeroLogin,
  InputChange,
  MyCheckBox,
  MyPasswordField,
  Progress,
  TextGridContainer,
  UserField,
} from "./LoginPage.style";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { LoginData } from "../../utils/types";
import { login } from "../../redux/auth/authService";
import { refreshState } from "../../redux/auth/authSlice";
const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isSuccess, hasError, error } = useAppSelector(
    (state) => state.auth // Use the typed selector hook
  );
  const { user, isLoading, authenticated } = useAppSelector(
    (state) => state.auth
  );
  const [isAuth, setIsAuth] = useState(false);
  const [shouldDisplayPage, setShouldDisplayPage] = useState(false);

  useEffect(() => {
    if (isLoading) return;

    if (!isLoading && user === null) {
      setShouldDisplayPage(true);
    }

    if (authenticated && user) {
      setIsAuth(true);
    }

  }, [authenticated, isAuth, isLoading, user, user?.user_role]);
  const [isSubmitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (authenticated && user) {
      const userRole = user?.role;
      if (userRole === "teacher") {
        navigate("/dashboard/teacher/");
      } else if (userRole === "super_admin") {
        navigate("/dashboard/administrator");
      }
    }
  }, [authenticated, user, navigate]);
  const loginUser = async (values: LoginData) => {
    dispatch(login(values));
  };

  useEffect(() => {
    if (!isLoading && isSubmitting) {
      if (hasError && error) {
        // toast.error(`Login failed. Please try again!`);
        toast.error(`${error}`);
        console.log("logn err", error);
      } else if (isSuccess) {
        if (error == `confirm your email before proceeding`) {
          toast.error(`${error}`);
        } else if (error == "User does not exist") {
          toast.error(`${error}`);
        } else {
          toast.success("Logged in successfully");
          const user_role = user?.role;
          setTimeout(() => {
            if (user_role == "teacher")
              navigate("/dashboard/teacher/");
            else navigate("/dashboard/administrator/");
          }, 1000);
        }
      }
      setSubmitting(false);
      dispatch(refreshState());
    }
  }, [
    dispatch,
    error,
    hasError,
    isLoading,
    isSubmitting,
    isSuccess,

    user?.user_role,
  ]);

  return (
    <>
     {shouldDisplayPage && <HeroLogin>"
        <img src={img1} width={"10%"} height={"10%"} alt="" style={{ margin: "10px auto 30px" }} />
        <ColorForm>
          <Typography color="#FF0000" variant="h6">Login User</Typography>
          <Formik
            initialValues={{
              user_info: "",
              password: "",
              stayLogged: false,
            }}
            onSubmit={async (values) => {
              setSubmitting(true);
              await loginUser(values);
            }}
            validationSchema={Yup.object({
              user_info: Yup.string().required("Required"),
              password: Yup.string().required("Required"),
            })}
          >
            <Form>
              <InputChange>
                <FieldIconContainer>
                  <label htmlFor="inputName">
                    <img src={img2} alt=""  />
                  </label>
                </FieldIconContainer>

                <UserField
                  name="user_info"
                  label=""
                  placeholder="Username/Email/Unique APROPLAT ID"
                />
              </InputChange>

              <InputChange>
                <FieldIconContainer>
                  <label htmlFor="inputPassword">
                    <img src={img3} alt="" />
                  </label>
                </FieldIconContainer>
                <MyPasswordField
                  name="password"
                  placeholder="Password"
                  label=""
                />
              </InputChange>
              <TextGridContainer container>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <MyCheckBox name="stayLogged" label="stay logged in" />
                    }
                    label="Stay login"
                  />
                </Grid>
                <Grid item xs={7}>
                  <ForgetContainer>
                    <Link to="/passwordReset">
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "15px",
                          lineHeight: "18.29px",
                          color: "#1675B7",
                          cursor: "pointer",
                        }}
                      >
                        Forgoten Password?
                      </Typography>
                    </Link>
                  </ForgetContainer>
                </Grid>
              </TextGridContainer>
              <Buttons
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <Progress size={24} /> : "Login"}
              </Buttons>

            </Form>
          </Formik>
        </ColorForm>
      </HeroLogin>}
    </>
  );
};
export default LoginPage;
