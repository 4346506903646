import { FaEnvelope, FaGlobe, FaInfoCircle, FaMoneyBill, FaPersonBooth, FaPhone } from "react-icons/fa";
import { GiveButtontwo, GiveContainer, Giveimage, Giveinput, GiveOption, GiveSelect, GiveTitle } from "./give.styles";
import { SetStateAction, useState } from "react";
import { Box, MenuItem, Typography } from "@mui/material";

export default function GiveScreen(){

    const [selection, setSelection] = useState('');
    const handleSelection =(event: { target: { value: SetStateAction<string>; }; })=>{
        setSelection(event.target.value)
    }

return(<GiveContainer><Giveimage />
    <GiveTitle>Make Giving Here</GiveTitle>
    <Giveinput startAdornment={<FaInfoCircle style={{marginRight:'3%'}} />} placeholder="Full Name" />
    <Giveinput startAdornment={<FaEnvelope style={{marginRight:'3%'}} />} placeholder="Email Address" />
    <Giveinput startAdornment={<FaPhone style={{marginRight:'3%'}} />} placeholder="Phone Number" />
    <Giveinput startAdornment={<FaMoneyBill style={{marginRight:'3%'}} />} placeholder="Amount" />
    <GiveSelect  startAdornment={<FaGlobe style={{marginRight:'3%'}}/>}
    defaultValue={1}
    
    > <GiveOption value={1} >Select Your Currency</GiveOption>
       <GiveOption value={2}>US Dollar</GiveOption>
       <GiveOption value={3}>francs CFA</GiveOption>
       <GiveOption value={4}>Pounds Sterling</GiveOption>
    </GiveSelect>
    <GiveSelect  startAdornment={<FaMoneyBill style={{marginRight:'3%'}}/>}
    defaultValue={1}
    
    > <GiveOption value={1} >Select a Giving Category</GiveOption>
       <GiveOption value={2}>General Offering</GiveOption>
       <GiveOption value={3}>Special Seed</GiveOption>
       <GiveOption value={4}>Global outreach</GiveOption>
    </GiveSelect>
    
    <GiveButtontwo>Make Giving</GiveButtontwo>
    
    
    </GiveContainer>)

}