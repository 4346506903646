import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import {
  AsideGrid,
  AsideGridHeading,
  AsideGridPost,
  AsideGridText,
  BlogDetail,
  BlogDetailItems,
  BlogImage,
  BlogItemWrapper,
  BlogItembg,
  BlogItems,
  BlogText,
  Chip,
  ChipWrapper,
  InputField,
  InputSearch,
  InputSearchBox,
  InputSearchWrapper,
} from "./BlogList.styles";
import magnifier from "../../../assets/blog/magnifier.png";

import PersonIcon from "@mui/icons-material/Person";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import data from "./BlogData";
import Checkbox from "@mui/material/Checkbox";
import TopAuthor from "./TopAuthor";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";


const BlogList = () => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("tablet"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobiledisc = useMediaQuery(theme.breakpoints.up("xs"));
  const matches = useMediaQuery(theme.breakpoints.down("tablet"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid container spacing={3} sx={{ padding: "0 1rem" }}>
        <Grid item xs={12} tablet={12} md={9}>
          <InputSearchWrapper item xs={12}>
            <InputSearch>
              <InputSearchBox>
                <img src={magnifier} alt="magnifier" />
                <InputField
                  sx={{
                    "&:after": { display: "none" },
                    "&:before": { display: "none" },
                  }}
                />
                <Button
                  sx={{
                    borderRadius: "0px 7px 7px 0px",
                    height: "50px",
                    border: "1px solid #1573AE",
                    textTransform: "none",
                    padding: "0 20px",
                  }}
                  disableRipple={true}
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                  variant="contained"
                >
                  Subscribe
                </Button>
              </InputSearchBox>
            </InputSearch>
          </InputSearchWrapper>
          {/*  tablet */}
          {!mobile && matches && (
            <Grid item xs={12}>
              <AsideGrid container>
                <AsideGridHeading item xs={12}>
                  <Typography variant="h2">Recent Post</Typography>
                </AsideGridHeading>
                <AsideGridText xs={12} color="" shadow="round">
                  <Typography variant="body1">
                    Impact of social media in the society
                  </Typography>
                </AsideGridText>
                <AsideGridText xs={12} color="" shadow="round">
                  <Typography variant="body1">
                    Africa and Politics, the way forward
                  </Typography>
                </AsideGridText>
                <AsideGridText xs={12} color="" shadow="round">
                  <Typography variant="body1">
                    How the Ebola Virus was handle by Africans
                  </Typography>
                </AsideGridText>
                <AsideGridText xs={12} color="blue" shadow="round">
                  <Typography variant="body1">More....</Typography>
                </AsideGridText>
                <AsideGridHeading item xs={12}>
                  <Typography variant="h2">Post Category</Typography>
                </AsideGridHeading>
                <AsideGridPost item xs={12} sm={3}>
                  <Checkbox
                    sx={{
                      color: theme.palette.grey[50],
                      "&.Mui-checked": {
                        color: theme.palette.info.light,
                        //   padding: "0",
                      },
                    }}
                  />
                  <Typography variant="body1">Science Courses</Typography>
                </AsideGridPost>
                <AsideGridPost item xs={12} sm={3}>
                  <Checkbox
                    sx={{
                      color: theme.palette.grey[50],
                      "&.Mui-checked": {
                        color: theme.palette.info.light,
                        padding: "0",
                      },
                    }}
                  />
                  <Typography variant="body1">
                    Social Science Courses
                  </Typography>
                </AsideGridPost>
                <AsideGridPost item xs={12} sm={3}>
                  <Checkbox
                    sx={{
                      color: theme.palette.grey[50],
                      "&.Mui-checked": {
                        color: theme.palette.info.light,
                        padding: "0",
                      },
                    }}
                  />
                  <Typography variant="body1">Pure Art Courses</Typography>
                </AsideGridPost>
                <AsideGridPost item xs={12} sm={3}>
                  <Checkbox
                    sx={{
                      color: theme.palette.grey[50],
                      "&.Mui-checked": {
                        color: theme.palette.info.light,
                        padding: "0",
                      },
                    }}
                  />
                  <Typography variant="body1">
                    Social Science Courses
                  </Typography>
                </AsideGridPost>
              </AsideGrid>
              <AsideGrid container>
                <AsideGridHeading item xs={12}>
                  <Typography variant="h2">Featured tags</Typography>
                </AsideGridHeading>
                <ChipWrapper>
                  <Chip>Sport</Chip>
                  <Chip> Social </Chip>
                  <Chip>Science</Chip>
                  <Chip>Literacy</Chip>
                  <Chip>Activities</Chip>
                </ChipWrapper>
              </AsideGrid>
              <AsideGrid container>
                <AsideGridHeading item xs={12}>
                  <Typography variant="h2">Related Posts</Typography>
                </AsideGridHeading>
                <AsideGridText xs={12} color="" shadow="roun">
                  <Typography variant="body1">
                    Impact of social media in the society
                  </Typography>
                </AsideGridText>
                <AsideGridText xs={12} color="" shadow="roun">
                  <Typography variant="body1">
                    Africa and Politics, the way forward
                  </Typography>
                </AsideGridText>
                <AsideGridText xs={12} color="" shadow="roun">
                  <Typography variant="body1">
                    How the Ebola Virus was handle by Africans
                  </Typography>
                </AsideGridText>
                <AsideGridText xs={12} color="blue" shadow="roun">
                  <Typography variant="body1">More...</Typography>
                </AsideGridText>
              </AsideGrid>
            </Grid>
          )}

          <Grid item xs={12}>
            <BlogItems>
              <Typography variant="h1">Post by popular Authors</Typography>
              <BlogItemWrapper container>
                {data.map((elem, ind) => (
                  <Link to={`/blogs/${ind}`}>
                    <BlogItembg key={ind}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} tablet={3}>
                          <BlogImage>
                            <img
                              src={elem.img}
                              alt="item1"
                              className="profile"
                            />
                          </BlogImage>
                        </Grid>
                        <BlogText item xs={9} md={9}>
                          <Typography variant="h2">
                            Impact of management in Nigeria Banking
                          </Typography>
                          <BlogDetail>
                            <BlogDetailItems color="#17637B">
                              <PersonIcon
                                sx={{
                                  color: theme.palette.background.default,
                                  fontSize: "12px",
                                }}
                              />
                              <Typography variant="body1">
                                {elem.postedby}
                              </Typography>
                            </BlogDetailItems>
                            <BlogDetailItems color="#17637B">
                              <WatchLaterIcon
                                sx={{
                                  color: theme.palette.background.default,
                                  fontSize: "10px",
                                }}
                              />
                              <Typography variant="body1">
                                {elem.time}
                              </Typography>
                            </BlogDetailItems>
                            <BlogDetailItems color="">
                              <PeopleAltIcon
                                sx={{
                                  color: theme.palette.background.default,
                                  fontSize: "12px",
                                }}
                              />
                              <Typography variant="body1">
                                {elem.views}
                              </Typography>
                            </BlogDetailItems>
                          </BlogDetail>
                          <Typography variant="body1">
                            {desktop && elem.discreption}
                            {tablet && elem.discreption.slice(0, 349)}
                            {mobiledisc && elem.discreption.slice(0, 221)}
                          </Typography>
                        </BlogText>
                      </Grid>
                    </BlogItembg>
                  </Link>
                ))}
              </BlogItemWrapper>
              {/* <Grid justifyContent="flex-end" alignItems="flex-end">
                hhh
              </Grid> */}
            </BlogItems>
          </Grid>
        </Grid>
        {!matches && (
          <Grid item xs={12} md={3}>
            <AsideGrid container>
              <AsideGridHeading item xs={12}>
                <Typography variant="h2">Recent Post</Typography>
              </AsideGridHeading>
              <AsideGridText xs={12} color="" shadow="round">
                <Typography variant="body1">
                  Impact of social media in the society
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="" shadow="round">
                <Typography variant="body1">
                  Africa and Politics, the way forward
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="" shadow="round">
                <Typography variant="body1">
                  How the Ebola Virus was handle by Africans
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="blue" shadow="round">
                <Typography variant="body1">More....</Typography>
              </AsideGridText>
              <AsideGridHeading item xs={12}>
                <Typography variant="h2">Post Category</Typography>
              </AsideGridHeading>
              <AsideGridPost item xs={12}>
                <Checkbox
                  sx={{
                    color: theme.palette.grey[50],
                    "&.Mui-checked": {
                      color: theme.palette.info.light,
                      //   padding: "0",
                    },
                  }}
                />
                <Typography variant="body1">Science Courses</Typography>
              </AsideGridPost>
              <AsideGridPost item xs={12}>
                <Checkbox
                  sx={{
                    color: theme.palette.grey[50],
                    "&.Mui-checked": {
                      color: theme.palette.info.light,
                      padding: "0",
                    },
                  }}
                />
                <Typography variant="body1">Social Science Courses</Typography>
              </AsideGridPost>
              <AsideGridPost item xs={12}>
                <Checkbox
                  sx={{
                    color: theme.palette.grey[50],
                    "&.Mui-checked": {
                      color: theme.palette.info.light,
                      padding: "0",
                    },
                  }}
                />
                <Typography variant="body1">Pure Art Courses</Typography>
              </AsideGridPost>
              <AsideGridPost item xs={12}>
                <Checkbox
                  sx={{
                    color: theme.palette.grey[50],
                    "&.Mui-checked": {
                      color: theme.palette.info.light,
                      padding: "0",
                    },
                  }}
                />
                <Typography variant="body1">Social Science Courses</Typography>
              </AsideGridPost>
            </AsideGrid>
            <AsideGrid container>
              <AsideGridHeading item xs={12}>
                <Typography variant="h2">Featured tags</Typography>
              </AsideGridHeading>
              <ChipWrapper>
                <Chip>Sport</Chip>
                <Chip> Social </Chip>
                <Chip>Science</Chip>
                <Chip>Literacy</Chip>
                <Chip>Activities</Chip>
              </ChipWrapper>
            </AsideGrid>
            <AsideGrid container>
              <AsideGridHeading item xs={12}>
                <Typography variant="h2">Related Posts</Typography>
              </AsideGridHeading>
              <AsideGridText xs={12} color="" shadow="roun">
                <Typography variant="body1">
                  Impact of social media in the society
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="" shadow="roun">
                <Typography variant="body1">
                  Africa and Politics, the way forward
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="" shadow="roun">
                <Typography variant="body1">
                  How the Ebola Virus was handle by Africans
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="blue" shadow="roun">
                <Typography variant="body1">More...</Typography>
              </AsideGridText>
            </AsideGrid>
          </Grid>
        )}
        <TopAuthor />
        {mobile && (
          <Grid item xs={12}>
            <AsideGrid container>
              <AsideGridHeading item xs={12}>
                <Typography variant="h2">Recent Post</Typography>
              </AsideGridHeading>
              <AsideGridText xs={12} color="" shadow="round">
                <Typography variant="body1">
                  Impact of social media in the society
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="" shadow="round">
                <Typography variant="body1">
                  Africa and Politics, the way forward
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="" shadow="round">
                <Typography variant="body1">
                  How the Ebola Virus was handle by Africans
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="blue" shadow="round">
                <Typography variant="body1">More....</Typography>
              </AsideGridText>
              <AsideGridHeading item xs={12}>
                <Typography variant="h2">Post Category</Typography>
              </AsideGridHeading>
              <AsideGridPost item xs={12} sm={3}>
                <Checkbox
                  sx={{
                    color: theme.palette.grey[50],
                    "&.Mui-checked": {
                      color: theme.palette.info.light,
                      //   padding: "0",
                    },
                  }}
                />
                <Typography variant="body1">Science Courses</Typography>
              </AsideGridPost>
              <AsideGridPost item xs={12} sm={3}>
                <Checkbox
                  sx={{
                    color: theme.palette.grey[50],
                    "&.Mui-checked": {
                      color: theme.palette.info.light,
                      padding: "0",
                    },
                  }}
                />
                <Typography variant="body1">Social Science Courses</Typography>
              </AsideGridPost>
              <AsideGridPost item xs={12} sm={3}>
                <Checkbox
                  sx={{
                    color: theme.palette.grey[50],
                    "&.Mui-checked": {
                      color: theme.palette.info.light,
                      padding: "0",
                    },
                  }}
                />
                <Typography variant="body1">Pure Art Courses</Typography>
              </AsideGridPost>
              <AsideGridPost item xs={12} sm={3}>
                <Checkbox
                  sx={{
                    color: theme.palette.grey[50],
                    "&.Mui-checked": {
                      color: theme.palette.info.light,
                      padding: "0",
                    },
                  }}
                />
                <Typography variant="body1">Social Science Courses</Typography>
              </AsideGridPost>
            </AsideGrid>
            <AsideGrid container>
              <AsideGridHeading item xs={12}>
                <Typography variant="h2">Featured tags</Typography>
              </AsideGridHeading>
              <ChipWrapper>
                <Chip>Sport</Chip>
                <Chip> Social </Chip>
                <Chip>Science</Chip>
                <Chip>Literacy</Chip>
                <Chip>Activities</Chip>
              </ChipWrapper>
            </AsideGrid>
            <AsideGrid container>
              <AsideGridHeading item xs={12}>
                <Typography variant="h2">Related Posts</Typography>
              </AsideGridHeading>
              <AsideGridText xs={12} color="" shadow="roun">
                <Typography variant="body1">
                  Impact of social media in the society
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="" shadow="roun">
                <Typography variant="body1">
                  Africa and Politics, the way forward
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="" shadow="roun">
                <Typography variant="body1">
                  How the Ebola Virus was handle by Africans
                </Typography>
              </AsideGridText>
              <AsideGridText xs={12} color="blue" shadow="roun">
                <Typography variant="body1">More...</Typography>
              </AsideGridText>
            </AsideGrid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BlogList;
