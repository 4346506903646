import { Box, Input, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

export const InputSearchWrapper = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: "1px solid white",
  padding: "2rem 3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "9px",
  marginBottom: "2rem",
  [theme.breakpoints.down("sm")]: {
    padding: "0",
  },
}));
export const InputSearch = styled(Box)(() => ({
  width: "100%",
  background: "rgba(11, 50, 63, 0.22)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  padding: "8px",
}));
export const InputSearchBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#FFFFFF",
  borderRadius: "8px",
  "& img": {
    paddingLeft: "10px",
  },
}));
export const InputField = styled(Input)(({ theme }) => ({
  width: "100%",
  background: "none",
  border: "none",
  paddingLeft: "8px",
  fontSize: "20px",
  fontWeight: "300",
  color: theme.palette.grey[50],
}));
export const BlogItems = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: "2rem",
  padding: "2rem 1.8rem",
  background: theme.palette.background.paper,
  border: "1px solid white",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    justifyContent: "center",
  },
  "& h1": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    color: theme.palette.info.dark,
    marginBottom: "2rem",
  },
}));
export const BlogItemWrapper = styled(Grid)(({ theme }) => ({
  "& h1": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    color: theme.palette.info.dark,
    marginBottom: "2rem",
  },
}));
export const BlogItembg = styled(Paper)(() => ({
  padding: "10px",
  borderRadius: "9px",
  marginBottom: "1rem",
  border: "1px solid white",
  "& .profile": {
    maxWidth: "100%",
    objectFit: "contain",
    height: "max-content",
  },
}));
export const BlogImage = styled(Box)(() => ({
  "& .profile": {
    maxWidth: "100%",
    objectFit: "contain",
    height: "max-content",
  },
}));
export const BlogText = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    gap: "5px",
  },
  "& h2": {
    cursor: "pointer",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.info.dark,
    [theme.breakpoints.down("desktop")]: {
      fontSize: "calc(10px + (16 - 10) * (100vw - 428px) / (1440 - 428))",
    },
  },
  "& p": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "16px",
    color: theme.palette.grey[50],
    [theme.breakpoints.down("desktop")]: {
      fontSize: "calc(9px + (10 - 9) * (100vw - 428px) / (1440 - 428))",
    },
  },
}));
export const BlogDetail = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "2rem",
  [theme.breakpoints.down("sm")]: {
    gap: "15px",
  },
}));
export const BlogDetailItems = styled(Box)<{ color: string }>(({ color }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "& p": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "12px",
    color: color ? color : "#17637B",
  },
}));
export const Aside = styled(Paper)(() => ({
  background: "#FDFDFE",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "9px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const AsideGrid = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "9px",
  padding: "1rem 10px",
  marginBottom: "2rem",
  border: "1px solid white",
}));
export const AsideGridHeading = styled(Grid)(({ theme }) => ({
  padding: "0 15px",
  margin: "8px 0 2rem 0",
  "& h2": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    color: theme.palette.grey[50],
  },
}));
export const AsideGridText = styled(Grid)<{ color: string; shadow: string }>(
  ({ theme, color, shadow }) => ({
    background: theme.palette.background.paper,
    border: "1px solid white",
    boxShadow:
      shadow === "round"
        ? "0px 0px 4px rgba(0, 0, 0, 0.25)"
        : "4px 4px 1px rgba(0, 0, 0, 0.06)",
    borderRadius: shadow === "round" ? "9px" : "0",
    padding: "5px 0px 5px 20px",
    marginBottom: "8px",
    "& p": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "16px",
      color:
        color === "blue" ? theme.palette.info.light : theme.palette.grey[50],
    },
  })
);
export const AsideGridPost = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "5px 0px 5px 20px",
  "& p": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "12px",
    color: theme.palette.text.primary,
  },
  "& span": {
    padding: "0",
  },
}));
export const ChipWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "1rem",
  marginLeft: "30px",
}));
export const Chip = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[300],
  padding: "8px 12px",
  borderRadius: "20px",
  color: theme.palette.grey[200],
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "15px",
}));
export const AuthorWrapper = styled(Box)(({ theme }) => ({
  width: "97%",
  margin: "2rem auto",
  "& h1": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    color: theme.palette.info.dark,
    marginBottom: "2rem",
  },
  "& h3": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: theme.palette.grey[200],
    marginBottom: "10px",
  },
}));
export const Pagenation = styled(Box)(() => ({
  display: "flex",
}));
