import {
  CardsContainer,
  CardsContent,
  CardsContentText,
  CardsIcon,
  CardsText,
  CardsTitle,
} from "./card.styles";
import { FaChurch, FaHome, FaSchool, FaScrewdriver } from "react-icons/fa";
export default function Cards() {
  return (
    <CardsContainer>
      <CardsContent>
        <CardsIcon>
          <FaChurch style={{ height: "5vh", width: "70%", marginTop: "12%" }} />
        </CardsIcon>
        <CardsContentText>
          <CardsTitle>The Empowerment Church International</CardsTitle>
          <CardsText>
            An arena of inextinguishable grace training Kingdom Militants for
            Ministry and Purpose in alignment to God's will{" "}
          </CardsText>
        </CardsContentText>
      </CardsContent>
      <CardsContent>
        <CardsIcon>
          <FaSchool style={{ height: "5vh", width: "70%", marginTop: "12%" }} />
        </CardsIcon>
        <CardsContentText>
          <CardsTitle>
            The Empowerment Bilingual Nursery and Primary School
          </CardsTitle>
          <CardsText>
            A millieu to train our to kids to become future leaders of tommorrow{" "}
          </CardsText>
        </CardsContentText>
      </CardsContent>
      <CardsContent>
        <CardsIcon>
          <FaHome style={{ height: "5vh", width: "70%", marginTop: "12%" }} />
        </CardsIcon>
        <CardsContentText>
          <CardsTitle>The Empowerment Foundation</CardsTitle>
          <CardsText>
            An all in one global interaction millieu to spread God's everlasting
            kindness to mankind{" "}
          </CardsText>
        </CardsContentText>
      </CardsContent>
    </CardsContainer>
  );
}
