import { Box,  Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Box)(() => ({
  width: "90%",
  margin: "2rem auto 0 auto",
}));
export const CardWrapper = styled(Paper)<{ outline: string }>(
  ({ theme, outline }) => ({
    background: theme.palette.background.paper,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "9px",
    padding: "1.5rem 1rem",
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
    marginBottom: "2rem",
    cursor: "pointer",
    border:
      outline === "blue"
        ? `1px solid ${theme.palette.secondary.main}`
        : "1px solid white",
  })
);
export const CardText = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "10px",

  "& h2": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    color: theme.palette.grey[50],
  },
  "& p": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "18px",
    color: theme.palette.grey[50],
  },
}));
export const CardWrapperQuestion = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "9px",
  padding: "1.5rem 1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "10px",
  marginBottom: "2rem",
  border: `1px solid ${theme.palette.secondary.main}`,
}));
export const QuestionHeading = styled(Box)(({ theme }) => ({
  padding: "1.5rem 1rem",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginBottom: "1.2rem",
  "& h2": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    color: theme.palette.grey[50],
  },
}));
export const Questions = styled(Box)<{ align: string }>(({ theme, align }) => ({
  border: "1px solid #E4E4E4",
  background: theme.palette.background.paper,
  padding: "1.5rem 2rem",
  display: "flex",
  flexDirection: align == "column" ? "column" : "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1rem",
  },
  // height: "90px",
  "& h2": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.grey[100],
    [theme.breakpoints.down("desktop")]: {
      fontSize: "calc(14px + (16 - 14) * (100vw - 428px) / (1440 - 428))",
    },
  },
  "& p": {
    marginTop: "1.5rem",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "18.15px",
    color: theme.palette.grey[100],
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.grey[200],
    cursor: "pointer",
    fontSize: "35px",
  },
}));
