import { useEffect, useState, memo, } from "react";

import { Link } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import Main from "./home";

const MemoizedLogPage = memo(Main); // Memoize the LogPage component

export default function Home() {

  const { isLoading, user, authenticated } = useAppSelector(
    (state) => state.auth
  );

  const [currentUser, setCurrentUser] = useState(false);
  useEffect(() => {
    if (isLoading) return;
    if (authenticated && user) {
      setCurrentUser(true);
    }
  }, [authenticated, isLoading, user]);

  useEffect(() => {
    if (currentUser) {
      <Link to={'/dashboard/administrator/'} />
    }
  });

  return (
    <>
      {<MemoizedLogPage />}
    </>
  );
}