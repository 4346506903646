import { AddUser, Student } from "./types/userManagement";
import {
  addUser,
  modifyUser,
} from "../redux/userManagement/userManagementService";
import { generateUUID } from "../pages/dashboard/administrator/settings";
import { updateExamResult, addExamResults } from "../redux/examManager/exammanagerService";
import { UserInformation } from "./types";
import { Class, Subject } from "./types/classManager";
import { ExamResult, AddExamResult } from "./types/examManager";

const saveUser = (
  data: any,
  addEditItem: { type: string; id: string | undefined },
  dispatch: any,
  setAddEditItem: any,
  allSubjects: Subject[],
  editingExamResults?: ExamResult[] | null,
  selectedClass?: Class | null,
  selectedSequence?: string,
  selectedTerm?: string,
  setEditingExamResults?: (arg0: null) => void
) => {
  const info: UserInformation = data;
  let addUserPayload: AddUser = {};

  if (!editingExamResults) {
    switch (info.role_id) {
      case "student":
        addUserPayload.student = {
          password: info.password,
          scomap_id: generateUUID(),
          id: addEditItem.id || generateUUID(),
          fullName: info.fullName,
    
          email: info.email,
          role: info.role_id,
          phone: info.phone,
          avatar_url: info.avatar_url,
          birthday: info.birthday,
          sex: info.sex,
          religion: info.religion,
          bloodGroup: info.bloodGroup,
          address: info.address,
          graduationYear: info.graduationYear,
          fatherName: info.fatherName,
          motherName: info.motherName,
          classId: info.classId,
          matricule: info.matricule
        };
        break;

      case "teacher":
        addUserPayload.teacher = {
          password: info.password,
          id: addEditItem.id || generateUUID(),
          scomap_id: generateUUID(),
          fullName: info.fullName,
     
          email: info.email,
          role: info.role_id,
          phone: info.phone,
          avatar_url: info.avatar_url,
          classes: info.classes,
        };
        break;

      case "administrator":
        addUserPayload.administrator = {
          password: info.password,
          scomap_id: generateUUID(),
          id: addEditItem.id || generateUUID(),
          fullName: info.fullName,

          email: info.email,
          role: info.role_id,
          phone: info.phone,
          avatar_url: info.avatar_url,
        };
        break;

      case "super_admin":
        addUserPayload.superadmin = {
          password: info.password,
          id: addEditItem.id || generateUUID(),
          fullName: info.fullName,

          email: info.email,
          role: info.role_id,
          phone: info.phone,
          avatar_url: info.avatar_url,
          scomap_id: generateUUID(),
        };
        break;

      default:
        console.error("Invalid role selected");
        return;
    }
  }

  if (addEditItem.type === "add") {
    dispatch(addUser({ data: addUserPayload }));
  } else if (addEditItem.id !== undefined) {
    if (editingExamResults) {
      const updatedExamResults =
        allSubjects.map((subject) => {
          const obtainedMarks =
            data[`obtainedMarks-${subject.subjectId}` as keyof typeof data];

          const existingResult = editingExamResults.find(
            (result) =>
              result.subjectId === subject.subjectId.toString() &&
              result.sequence === selectedSequence &&
              result.term === selectedTerm
          );

          const resultData: AddExamResult = {
            id: existingResult ? existingResult.id : generateUUID(),
            classId: selectedClass?.classId || "",
            sequence: selectedSequence || "",
            term: selectedTerm || "",
            studentId: addEditItem.id || "",
            subjectId: subject.subjectId.toString(),
            totalMarks: "100",
            obtainedMarks: obtainedMarks || "",
          };

          if (existingResult) {
            if (existingResult.obtainedMarks !== obtainedMarks) {
              dispatch(
                updateExamResult({
                  data: resultData,
                  id: existingResult.id,
                })
              );
            }
          } else if (obtainedMarks) {
            dispatch(addExamResults(resultData));
          }

          return existingResult
            ? { ...existingResult, obtainedMarks }
            : obtainedMarks
            ? { ...resultData }
            : existingResult;
        }) || [];

      console.log("Updated Exam Results:", updatedExamResults);
    } else {
      dispatch(modifyUser({ data: addUserPayload, id: addEditItem.id }));
    }
  }

  setAddEditItem({ open: false, type: "add", id: undefined });
  setEditingExamResults && setEditingExamResults(null);
};

export { saveUser };