import { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  ButtonsBox,
  InputsContainer,
  StyledDialog,
  StyledDialogContent,
  StyledTab,
  StyledTabs,
  Title,
} from "./AddEditInformationModal.style";

import LabelInput from "../../inputs/LabelInput";
import CustomButton from "../../buttons/CustomButton";
import DatePickerInput from "../../inputs/DatePickerInput";

import Box from "@mui/material/Box";
import ListSelectDirect from "./Select";
import { InputType } from "../../../constants/enums/inputTypes";
import Autocomplete from "../../inputs/Autocomplete";
import BrowseImageInput from "../../inputs/BrowseImageInput";
import { ButtonType } from "../../../constants/enums/buttons";

interface IModalProps<T> {
  open: boolean;
  title: string;
  onClose: () => void;
  onSave: (values: T) => void;
  onEdit: (values: T) => void;
  data: {
    name: string;
    type: InputType;
    label: string;
    placeholder?: string;
    accept?: string;
    multiple?: boolean;
    callback?: (files: File) => void;
    extra?: { value: string | number; label: string }[];
  }[];
  type: "add" | "edit";
  formValues: T;

  autocompleteOptions?: { value: string | number; label: string }[];
}

type ValueTypes = string | number | null | File[] | FileList | string[];

const AddEditInformationModal = <T extends Record<string, ValueTypes>>({
  open,
  title,
  onClose,
  onSave,
  onEdit,
  data,
  type,
  formValues,

  autocompleteOptions,
}: IModalProps<T>) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  const handleSubmitInformation = (values: T) => {


      onSave(values);
    
    onClose();
  };

  const handleChange = (role: string) => {
    setSelectedRole(role);
    console.log(role);
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledTabs value={type} aria-label="add | edit">
        {type === "add" ? (
          <StyledTab value="add" label="Add" sx={{ color: "#595F69" }} />
        ) : (
          <StyledTab value="edit" label="Edit" sx={{ color: "#595F69" }} />
        )}
      </StyledTabs>
      <Formik
        initialValues={formValues}
      //  validationSchema={schema}
        onSubmit={(values) => {
          setSubmitting(true);
          handleSubmitInformation(values);
        }}
      >
        <Form>
          <StyledDialogContent>
            <Title variant="h4">{title}</Title>
            <InputsContainer>
              {data.map((el, index) => {
           
                if (el.type === InputType.labelInput) {
                  return (
                    <LabelInput
                      key={index}
                      label={
                        type === "add" ? `Add ${el.label}` : `Edit ${el.label}`
                      }
                      placeholder={el.placeholder || ""}
                      name={el.name}
                    />
                  );
                } else if (el.type === InputType.datepicker) {
                  return (
                    <DatePickerInput
                      key={index}
                      label={el.label}
                      name={el.name}
                    />
                  );
                } else if (
                  el.type === InputType.autocomplete &&
                  autocompleteOptions
                ) {
                  if (el.name === "role_id") {
                    return (
                      <Autocomplete
                        key={index}
                        label="Role"
                        placeholder="Select Role"
                        name={el.name}
                        data={el.extra ? el.extra : autocompleteOptions}
                        onchange={handleChange}
                      />
                    );
                  } else if (el.name === "classId" || el.name === "sex") {
                    return (
                      <Autocomplete
                        key={index}
                        label={
                          type === "add"
                            ? `Add ${el.label}`
                            : `Edit ${el.label}`
                        }
                        placeholder={el.placeholder || ""}
                        name={el.name}
                        data={el.extra ? el.extra : autocompleteOptions}
                      />
                    );
                  }
                } else if (el.type === InputType.password) {
                  return (
                    <LabelInput
                      key={index}
                      label={
                        type === "add" ? `Add ${el.label}` : `Edit ${el.label}`
                      }
                      placeholder={el.placeholder || ""}
                      name={el.name}
                      type="password"
                    />
                  );
                } else if (el.type === InputType.image) {
                  return (
                    <BrowseImageInput
                      key={index}
                      label={
                        type === "add" ? `Add ${el.label}` : `Edit ${el.label}`
                      }
                      name={el.name}
                      accept={el.accept || "image/*"}
                      multiple={el.multiple || false}
                      callback={el.callback}
                    />
                  );
                } else if (el.type === InputType.select) {
                  return (
                    <Box key={index}>
                      Add {el.name}
                      <ListSelectDirect
                        key={index}
                        data={
                          el.extra?.map((s) => ({
                            id: s.value.toString(),
                            name: s.label.toString(),
                          })) || []
                        }
                        name={el.name}
                        label={el.label}
                       
                        type={'other'}
                   
                      />
                    </Box>
                  );
                }
                return null;
              })}
            </InputsContainer>
            <ButtonsBox>
              <CustomButton
                type={ButtonType.CONFIRMATION}
                content={type === "add" ? "Save" : "Edit"}
                disabled={isSubmitting}
                buttonType="submit"
              />
              <CustomButton
                type={ButtonType.CANCEL}
                content="Cancel"
                actions={{ onClick: onClose }}
              />
            </ButtonsBox>
          </StyledDialogContent>
        </Form>
      </Formik>
    </StyledDialog>
  );
};

export default AddEditInformationModal;
