import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import img1 from '../../../assets/images/2.jpg';
import img2 from '../../../assets/images/3.jpg';
import  img3 from '../../../assets/images/hi.jpg'
import { ChurchHeroButton, ChurchHeroContainer, ChurchHeroContainerOverlay, ChurchHeroSlide, ChurchHeroText, ChurchHeroTitle } from "./churchHero.styles";
import Carousel from "react-material-ui-carousel";
import { EventsSlide } from "../../../components/tec/home/events/events.styles";
interface ChurchHeroProps{
    
        image:string;
        title:string;
        description:string;

    

}
export const data : ChurchHeroProps[]=[
{
    image: `url(${img1})`,
    title: "WELCOME TO THE EMPOWERMENT CHURCH",
    description: "A place of true worshippers,come ,experience and share God's goodness"
},
{
    image: `url(${img2})`,
    title: "WELCOME TO THE EMPOWERMENT CHURCH",
    description: "A place of true worshippers,come ,experience and share God's goodness"
},
{
    image: `url(${img3})`,
    title: "WELCOME TO THE EMPOWERMENT CHURCH",
    description: "A place of true worshippers,come ,experience and share God's goodness"
}
]
const ChurchHero :React.FC =()=>{
    const responsive = {
        desktop:{
            breakpoint :{max :3000, min:1024}
            , items: 3
        },
        tablet :{
            breakpoint:{ max:1024, min: 468},
            items:2
        },
        mobile: {
            breakpoint :{ max:468, min:0},
            items:1,
            
        }
    };
return(
  <div>  <ChurchHeroSlide   >
    
  <Carousel 
  animation="slide"
  autoPlay={true}
  interval={3000}
  indicators={true}

  
swipe={true}
  sx={{
    "& .MuiCarousel-root": {
      height: "auto",
    },
    "& .MuiCarousel-item": {
      display: "flex",flexDirection:'row',
      justifyContent: "center",
      alignItems: "center",
      minWidth: 700,
    },
  }}>
      {data.map((item, index) => (
      <Grid container key={index} justifyContent="center" alignItems="center">
   
      
            <ChurchHeroContainer style={{backgroundImage:item.image}} >
                <ChurchHeroContainerOverlay>
                    <ChurchHeroTitle>{item.title}</ChurchHeroTitle>
                    <ChurchHeroText>{item.description}</ChurchHeroText>
                    <ChurchHeroButton>Explore Now!</ChurchHeroButton>
                </ChurchHeroContainerOverlay>
            </ChurchHeroContainer>
          
   </Grid>
     
      
      ))}
  </Carousel>
</ChurchHeroSlide></div>
)





}
export default ChurchHero;