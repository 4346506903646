import { Box, Card, styled } from "@mui/material";
import img1 from '../../../../assets/images/4.jpg'

export const RevolutionCard = styled(Card)(({theme})=>({borderRadius:'10px',
    display:'flex',flexDirection:'row-reverse',minWidth:'50%',width:'70%',maxWidth:'100%',height:'60vh',marginLeft:'15%'
   , [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack elements vertically on smaller screens
        alignItems: 'center',
        marginLeft: '3%',height: '130vh',minWidth: '50%',
width: '90%',
    maxWidth: '100%',marginTop:'6%'
    },

}))

export const RevCardImage = styled(Box)(({theme})=>({
    minWidth:'50%',width:'60%',maxWidth:'60%',height:'70vh',backgroundSize:'150%',
    backgroundImage:`url(${img1})`,backgroundRepeat:'no-repeat','&:hover':{transform:'scale(1.1)',},
    [theme.breakpoints.down('md')]: {
        width: '150%', maxWidth: '100%',minWidth:'80%',   marginTop:'3%',   
        height: '80vh', marginBottom:"4%", backgroundSize: '100%',// Adjust height for smaller screens
    },
}))