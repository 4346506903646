
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Hero from "../components/Aboutus/HeroSection/Hero";
import Question from "../components/FAQ/Questions/Question";

const faq = () => {
  return (
    <Background>
      <Hero />
      <Question />
    </Background>
  );
};

export default faq;
export const Background = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
}));
