import { Box, Button, styled, Typography } from "@mui/material";
import hero from '../../../../assets/images/hi.jpg';

export const HeroAboutContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(0,0,0,0.2)',
    backgroundImage: `url(${hero})`,
    minHeight: '29vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', // Adjust background size for better responsiveness
    backgroundPositionY: 'center',
    backgroundPositionX: 'right',
    width: '100%',
    minWidth: '70%',
    maxWidth: '100%',

    [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '20vh', // Adjust height for smaller screens
        maxHeight: '30vh',
        height: '20vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const HeroAboutContainerOverlay = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(138,43,226,0.4)',
    minHeight: '29vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',

    [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '20vh', // Adjust height for smaller screens
        maxHeight: '30vh',
        height: '20vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const HeroAboutText = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: '400',
    color: 'white',
    marginTop: '7%',
    textAlign: 'center',
    marginBottom: '5%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));

export const HeroAboutContainerOverlaytext = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(0,0,0,0.6)',
    minHeight: '29vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',
    width: '50%',
    marginTop: '0%',
    marginLeft: '3%',
    [theme.breakpoints.down('md')]: {
        width: '90%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '3%',
        minHeight: '10vh',
        backgroundSize: '40%',
    }
}));

export const HeroAboutTitle = styled(Typography)(({ theme }) => ({
    marginTop: '15%',
    paddingBottom: '10%',
    fontSize: '3.5rem',
    fontWeight: 'bold',
    fontFamily: 'serif',
    textAlign: 'center',
    color: 'white',
    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem', // Adjust font size for smaller screens
        marginTop: '1rem',
    }
}));

export const HeroAboutTexttwo = styled(Typography)(({ theme }) => ({
    marginLeft: '8%',
    borderRadius: '10px',
    fontSize: '2rem',
    fontWeight: '400',
    color: 'white',
    textAlign: 'center',
    height: '7vh',
    backgroundColor: 'blueviolet',
    width: '80%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem', // Adjust font size for smaller screens
        width: '75%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '10%',
    }
}));

export const HeroAboutButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: 'black',
    fontSize: '1rem',
    height: '5%',
    width: '30%',
    minWidth: '10%',
    maxWidth: '80%',
    marginRight: '2%',
    [theme.breakpoints.down('md')]: {
        width: '100%', // Make buttons full-width on smaller screens
        margin: '10px 0',
    }
}));

export const HeroAboutButtontwo = styled(Button)(({ theme }) => ({
    color: 'white',
    fontSize: '1rem',
    height: '5%',
    border: '1px solid white',
    width: '30%',
    minWidth: '10%',
    maxWidth: '80%',
    paddingLeft: '1%',
    [theme.breakpoints.down('md')]: {
        width: '100%', // Make buttons full-width on smaller screens
        margin: '10px 0',
    }
}));

export const HeroAboutRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '20%',
    marginTop: '5%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack buttons vertically on smaller screens
        marginLeft: '0%',
    }
}));