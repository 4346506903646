import { createAsyncThunk } from "@reduxjs/toolkit";
import { Class, Subject } from "../../utils/types/classManager"; // Adjust the import path as needed
import { db } from "../firebase";
import { collection, doc, getDocs, setDoc, deleteDoc, updateDoc, getDoc } from "firebase/firestore";

// Fetch classes
export const getClasses = createAsyncThunk<
  {classes: Class[], subjects: Subject[], }| null,
  void,
  { rejectValue: string[] }
>("classes/get", async (_, { rejectWithValue }) => {
  try {
    const classSnapshot = await getDocs(collection(db, "classes"));
    const classes = classSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as Class[];
   
    const subjectSnapshot = await getDocs(collection(db, "subjects"));
    const subjects = subjectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as Subject[];
   
    return {classes, subjects};
  } catch (error) {
    console.error("Failed to fetch classes", error);
    return rejectWithValue(["Failed to fetch classes"]);
  }
});

// Add a new class
export const addClass = createAsyncThunk<
  Class,
  Class,
  { rejectValue: string[] }
>("classes/add", async (newClass, { rejectWithValue }) => {
  try {
    return { id: newClass.classId, ...newClass };
  } catch (error) {
    console.error("Failed to add class", error);
    return rejectWithValue(["Failed to add class"]);
  }
});

// Add a new subject to a class
export const addSubject = createAsyncThunk<
  Subject,
  {data: Subject},
  { rejectValue: string[] }
>("classes/addSubject", async ({ data}, { rejectWithValue }) => {
  try {
    await setDoc(doc(db, 'subjects', data.subjectId), data);
    return data;
  } catch (error) {
    console.error("Failed to add subject", error);
    return rejectWithValue(["Failed to add subject"]);
  }
});

// Delete a subject from a class
export const deleteSubject = createAsyncThunk<
  { message: string },
  {  subjectId: string },
  { rejectValue: string[] }
>("classes/deleteSubject", async ({ subjectId }, { rejectWithValue }) => {
  try {
    await deleteDoc(doc(db, `subjects`, subjectId));
    return { message: "Subject deleted successfully" };
  } catch (error) {
    console.error("Failed to delete subject", error);
    return rejectWithValue(["Failed to delete subject"]);
  }
});

// Update an existing class
export const updateClass = createAsyncThunk<
  Class,
  { id: string; data: Partial<Class> },
  { rejectValue: string[] }
>("classes/update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const classRef = doc(db, "classes", id);
    await updateDoc(classRef, data);
    const updatedDoc = await getDoc(classRef);
    return { id, ...updatedDoc.data() } as unknown as Class;
  } catch (error) {
    console.error("Failed to update class", error);
    return rejectWithValue(["Failed to update class"]);
  }
});

// Delete a class
export const deleteClass = createAsyncThunk<
  { message: string; id: string },
  string,
  { rejectValue: string[] }
>("classes/delete", async (id, { rejectWithValue }) => {
  try {
    await deleteDoc(doc(db, "classes", id));
    return { message: "Class deleted successfully", id };
  } catch (error) {
    console.error("Failed to delete class", error);
    return rejectWithValue(["Failed to delete class"]);
  }
});