import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { news_Letter } from "../../utils/types";
import {baseUrl} from "../../utils/baseUrl";



export const newsLetter = createAsyncThunk<
news_Letter,
{ email: string }
>("auth/newsLetter", async (email, { rejectWithValue}) => {
  const data = { newsletter_contact: email };
  try {
    const response = await axios.post(`${baseUrl()}/v1/newsletter_contacts`, data);
    console.log("got response", response);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<unknown, AxiosResponse<unknown>>;
    const responseData = axiosError.response?.data as { errors?: string[] };
    const responseMessage = axiosError.response?.data as { message?: string[] };
    const errorMessage = responseMessage?.message;
    return rejectWithValue(responseData?.errors || errorMessage || []);
  }
});

