import { LiveContainer, LiveHero, LiveHeroColumn, LiveImage, LiveImages, LiveText, LiveTitle,LiveButton } from "./live.styles";
import img1 from '../../../assets/images/1.jpg';
import img2 from '../../../assets/images/12.jpg'
import { transform } from "typescript";
export default function LiveScreen(){
    return(<LiveContainer>
<LiveHero><LiveHeroColumn>
    <LiveTitle>The Empowerment TV</LiveTitle>
    <LiveText>Spreading Hope,Inspiring faith, Empowering nations to be God's living testimonies,an evidence of God's goodness</LiveText>
   <LiveButton>Visit Our Website</LiveButton>
    </LiveHeroColumn>
    <LiveImages><LiveImage style={{backgroundImage:`url(${img1})`,transform:'translate(40%)'}}/>
        <LiveImage style={{backgroundImage:`url(${img2})`}}/>
        </LiveImages>
    
    
    
    </LiveHero>


    </LiveContainer>)
}