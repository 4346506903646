import {
  DashboardOutlined,
  MenuRounded,
} from "@mui/icons-material";
import { Grid, List, ListItemText } from "@mui/material";
import Drawer from "../Drawer";
import NavLink from "../NavLink";
import {
  CollapseContainer, 
  ComponentContainer,
  CustomListItemButton,
  CustomListItemIcon,
  CustomListItemText,
  DesktopMenueList,
  Heading,
  InnerContainer,
  LeftDrawerContaner,
  MainContainer,
  MenuIconButton,
  MenueButtonContainer,
} from "./AdministratorLeftSection.styles";

type Props = {
  shrink: boolean;
  handleShrink: () => void;
};

const AdministratorLeftSection = ({ shrink, handleShrink }: Props) => {


  return (
    <ComponentContainer container>
      <Grid item xs={12}>
        <CollapseContainer
          in={!shrink}
          orientation="horizontal"
          collapsedSize={78}
        >
          <MainContainer>
            <Grid container>
              <Grid item xs={12}>
                <InnerContainer>
                  <Grid container>
                    {!shrink ? (
                      <>
                        <Grid item xs={10}>
                          <Heading>Admin Dashboard</Heading>
                        </Grid>
                        <Grid item xs={2}>
                          <MenuIconButton
                            // onClick={() => setShrinkLeft(!shrinkLeft)}
                            onClick={handleShrink}
                          >
                            <MenuRounded />
                          </MenuIconButton>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <MenueButtonContainer>
                            <MenuIconButton
                              // onClick={() => setShrinkLeft(!shrinkLeft)}
                              onClick={handleShrink}
                            >
                              <MenuRounded />
                            </MenuIconButton>
                          </MenueButtonContainer>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </InnerContainer>
              </Grid>

              <Grid item xs={12}>
                <DesktopMenueList>
                  <NavLink path="/dashboard/administrator/">
                    <CustomListItemButton
                      title="Dashboard"
              
                    >
                      <CustomListItemIcon>
                        <DashboardOutlined />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomListItemText>Dashboard</CustomListItemText>
                        }
                      />
                    </CustomListItemButton>
                  </NavLink>
                  <NavLink path="/dashboard/administrator/userManagement">
                    <CustomListItemButton
                      title="User Management"
              
                    >
                      <CustomListItemIcon>
                        <DashboardOutlined />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomListItemText>User Management</CustomListItemText>
                        }
                      />
                    </CustomListItemButton>
                  </NavLink>
          
              
      
        

                </DesktopMenueList>
              </Grid>
            </Grid>
          </MainContainer>
        </CollapseContainer>
        <LeftDrawerContaner>
          <Drawer>
            <Grid container>
              <Grid item xs={12}>
                <List>
                  <NavLink path="/dashboard/administrator/">
                    <CustomListItemButton title="Dashboard">
                      <CustomListItemIcon>
                        <DashboardOutlined />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomListItemText>Dashboard</CustomListItemText>
                        }
                      />
                    </CustomListItemButton>
                  </NavLink>
            
      
                  <NavLink path="/dashboard/administrator/userManagement">
                    <CustomListItemButton
                      title="User Management"
              
                    >
                      <CustomListItemIcon>
                        <DashboardOutlined />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomListItemText>User management</CustomListItemText>
                        }
                      />
                    </CustomListItemButton>
                  </NavLink>

   
                </List>
              </Grid>
            
            </Grid>
          </Drawer>
        </LeftDrawerContaner>
      </Grid>
    </ComponentContainer>
  );
};

export default AdministratorLeftSection;
