import React from "react";
import PasswordReset from "../components/PasswordReset/PasswordReset";

export  default function passwordReset ()  {
  return (
    <div>
      <PasswordReset />
    </div>
  );
};


