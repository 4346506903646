import { FaFacebookMessenger, FaPhone } from "react-icons/fa";
import HeroContact from "../heroContact/heroContact";
import {
  ContactCardinput,
  ContactCardLefdown,
  ContactCardLeftButton,
  ContactCardLeftIcon,
  ContactCardLeftText,
  ContactCardLeftTitle,
  ContactCardLeftup,
  ContactCardRightrow,
  ContactCardRightText,
  ContactCardRightTitle,
  ContactCardTextfield,
  ContactCol,
  ContactContainer,
  ContactContainerLeft,
  ContactContainerRight,
  ContactScreenContainer,
} from "./contactScreen.styles";

export default function ContactScreen() {
  return (
    <ContactScreenContainer>
      <HeroContact />
      <ContactContainer>
        <ContactContainerLeft>
          <ContactCardLeftup>
            <ContactCardLeftIcon>
              <FaPhone />
            </ContactCardLeftIcon>
            <ContactCol>
              <ContactCardLeftText>Call us directly at</ContactCardLeftText>
              <ContactCardLeftTitle>+237 679015081</ContactCardLeftTitle>
            </ContactCol>
          </ContactCardLeftup>
          <ContactCardLefdown>
            {" "}
            <ContactCardLeftIcon>
              <FaFacebookMessenger />
            </ContactCardLeftIcon>
            <ContactCol>
              <ContactCardLeftText>Send us a message</ContactCardLeftText>
              <ContactCardLeftButton>Chat Now!</ContactCardLeftButton>
            </ContactCol>
          </ContactCardLefdown>
        </ContactContainerLeft>
        <ContactContainerRight>
          <ContactCardRightTitle>Book a meeting</ContactCardRightTitle>
          <ContactCardRightText>
            Feel free to book a meeting with the administrators! We are here to
            help you
          </ContactCardRightText>

          <ContactCol>
            {" "}
            <ContactCardRightrow>
              <ContactCardinput placeholder="First Name" />
              <ContactCardinput placeholder="Last Name" />
            </ContactCardRightrow>
            <ContactCardRightrow>
              <ContactCardinput placeholder="Email" />
              <ContactCardinput placeholder="Address" />
            </ContactCardRightrow>
            <ContactCardTextfield placeholder="Leave a message"></ContactCardTextfield>
          </ContactCol>
        </ContactContainerRight>
      </ContactContainer>
    </ContactScreenContainer>
  );
}
