import { Box, Button, Link, styled, Typography } from "@mui/material";

export const StartTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "cursive",
  fontSize: "2.5rem",
  fontWeight: "bold",
  textAlign: "center",
  color: "black",
  animation: "ease-in",
  animationDuration: "200ms",
  transition: "ease-in-out",
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
  },
}));
export const StartLink = styled(Button)(({ theme }) => ({
  marginTop: "2%",
  borderRadius: "10px",
  color: "white",
  fontSize: "1rem",
  fontWeight: "700",
  textAlign: "center",
  backgroundColor: "blueviolet",
  width: "20%",
  minWidth: "10%",
  maxWidth: "30%",
  marginLeft: "40%",
  "&:hover": { transform: "scale(1.1)" },
  [theme.breakpoints.down("md")]: {
    width: "50%",
    marginLeft: "35%",
  },
}));

export const StartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "3%",
  marginBottom: "5%",
  overflow: "hidden",
}));
