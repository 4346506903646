import { Box, styled, Typography } from "@mui/material";

export const IntroContainer = styled(Box)(({theme})=>({
    display:'flex',
    flexDirection:'row',
    minWidth:'50%',width:'90%',maxWidth:'100%',minHeight:'40vh'
}))

export const IntroCol = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column'
}))

export const IntroTitle = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: 'blueViolet',
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const IntroText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    color: 'black',
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '1%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));

export const IntroImage = styled(Box)(({ theme }) => ({
    marginLeft: '2%',
    padding: '3%',
    height: '45vh',
    backgroundSize: 'cover', // Ensure image scales proportionally
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: '10px',
    minWidth: '90%',
    borderBottomLeftRadius: '10px',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        height: '45vh', // Adjust height for smaller screens
    },
}));