import React from 'react'
import img1 from '../../../../assets/images/7.jpg'
import img2 from '../../../../assets/images/6.jpg'
import img3 from '../../../../assets/images/8.jpg'
import Carousel from "react-material-ui-carousel";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { EventCard, EventsColumn, EventsContainer, EventsImage, EventsSlide, EventsText, EventTitle } from './events.styles';
import { Box, Grid } from '@mui/material';
import Slider from 'react-slick';
interface EventsData{
    image:string,
    title:string,
    description:string,
}

export const data: EventsData[]=[
    {image: `url(${img1})`,
        title:'POWER SUNDAY',
        description:'Come ready and prepared to experience an ultimate divine encounter with God through his servants,and testify of his unfailing mercies and love as he sets people free from captivity'
     },
     {image:`url(${img2})`,
        title:'DIVINE ENCOUNTER',
        description:'Come ready and prepared to experience an ultimate divine encounter with God through his servants,and testify of his unfailing mercies and love as he sets people free from captivity'
     },
     {image:`url(${img3})`,
     title:'CAMP MEETING INTERNATIONAL',
     description:'Come ready and prepared to experience an ultimate divine encounter with God through his servants,and testify of his unfailing mercies and love as he sets people free from captivity'
  }
]

const Events : React.FC =()=>{
    const responsive = {
        desktop:{
            breakpoint :{max :3000, min:1024}
            , items: 3
        },
        tablet :{
            breakpoint:{ max:1024, min: 468},
            items:2
        },
        mobile: {
            breakpoint :{ max:468, min:0},
            items:1,
            
        }
    };
    const settings = {
        responsive,
        swipeable:true,
        autoPlay:true,
        autoPlaySpeed: 3000,
        dots: false,
        infinite: true,
        transitionDuration:500,
        focusOnSelect:true,
        
        
    }

return(
<EventCard>
    <EventTitle>Our Latest Events</EventTitle>
    <EventsSlide   >
    
     <Carousel 
     animation="slide"
     autoPlay={true}
     interval={3000}
     indicators={true}
    
     
   swipe={true}
     sx={{
       "& .MuiCarousel-root": {
         height: "auto",
       },
       "& .MuiCarousel-item": {
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         minWidth: 700,
       },
     }}>
         {data.map((item, index) => (
         <Grid container key={index} justifyContent="center" alignItems="center">
         <Grid
           item
           xs={12}
           sm={8}
           md={6}
           lg={4}
           xl={3}
           sx={{ p: 1 }}
         > <EventsContainer  >
         <EventsImage style={{backgroundImage:item.image}}></EventsImage>
         
       <EventsColumn>  <EventTitle>{item.title}</EventTitle>
       <EventsText>{item.description}</EventsText></EventsColumn></EventsContainer>
      </Grid>
        
         
         </Grid> ))}
     </Carousel>
</EventsSlide></EventCard>
)
}

export default Events;