import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";

import { Student, Teacher } from "../../../../utils/types/userManagement";
import { useLocation } from "react-router-dom";
import AddEditInformationModal from "../../../../components/modals/AddEditInformationModal";
import { InputType } from "../../../../constants/enums/inputTypes";
import { getClasses } from "../../../../redux/classManager/classService";
import { updateExamResult, addExamResults } from "../../../../redux/examManager/exammanagerService";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getUsers } from "../../../../redux/userManagement/userManagementService";
import { AddExamResult, ExamResult } from "../../../../utils/types/examManager";
import { Class, Subject } from "../../../../utils/types/classManager";
import { auth } from "../../../../redux/firebase";

export function generateUUID() {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  array[6] = (array[6] & 0x0f) | 0x40;
  array[8] = (array[8] & 0x3f) | 0x80;
  const uuid = Array.from(array, (byte) =>
    byte.toString(16).padStart(2, "0")
  ).join("-");
  return uuid;
}

type AddEditItem = {
  open: boolean;
  type: "edit";
  id: string | undefined;
};

const TeacherManagement = () => {
  const dispatch = useAppDispatch();
  const { user, isLoading, authenticated } = useAppSelector(
    (state) => state.auth
  );
  const [isAuth, setIsAuth] = useState(false);
  const [shouldDisplayPage, setShouldDisplayPage] = useState(false);
  const router = useLocation();
  const { subjects } = useAppSelector((state) => state.classes);
  const [allSubjects, setSubjects] = useState<Subject[] | undefined>([]);
  const { classes } = useAppSelector((state) => {

    return state.classes;
  });


  useEffect(() => {
    if (auth.currentUser?.uid) {
      dispatch(getUsers());
      dispatch(getClasses());
    }
  }, [dispatch]);


  useEffect(() => {
    if (isLoading) return;

    if (!isLoading && user === null) {
      router.pathname = "/login";
    }

    if (authenticated && user) {
      setIsAuth(true);
    }
    if (isAuth && user?.role === "teacher") {
      setShouldDisplayPage(true);
    } else if (user?.role === "super_admin") {
      router.pathname = "/dashboard/administrator/";
    }
  }, [authenticated, isAuth, isLoading, router, user, user?.role]);

  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [students, setStudents] = useState<Student[] | undefined>([]);
  const [editingExamResults, setEditingExamResults] = useState<
    ExamResult[] | null
  >(null);

  const [addEditItem, setAddEditItem] = useState<AddEditItem>({
    open: false,
    type: "edit",
    id: undefined,
  });

  const { results } = useAppSelector((state) => state.examResults);
  const { users } = useAppSelector((state) => state.users);
  const [selectedTerm, setSelectedTerm] = useState<string>("");
  const [selectedSequence, setSelectedSequence] = useState<string>("");

  const handleEditResults = (id: string, examResults?: ExamResult[]) => {
    const currentUser = students?.find((user) => user.id === id);
    if (currentUser && !editingExamResults) {
      setEditingExamResults(examResults || null);
      setAddEditItem({ open: true, type: "edit", id });
    }
  };

  const handleTermChange = (event: { target: { value: any } }) => {
    setSelectedTerm(event.target.value);
  };

  const handleSequenceChange = (event: { target: { value: any } }) => {
    setSelectedSequence(event.target.value);
  };

  const filteredClasses =
    user &&
    classes.filter((c)=> {
      console.log(classes);
      console.log(user);
      console.log(c);
      return user.role === "teacher"
        ? (user as Teacher).classes!.includes(c.classId)
        : false
    }

    );
  useEffect(() => {
    console.log(user)
  }, [user])
  const filteredExamResults = (student: Student) => {
    return (
      results.filter(
        (result) => result.studentId === student.id &&
          result.term === selectedTerm && result.sequence === selectedSequence
      ) || []
    );
  };

  useEffect(() => {
    if (auth.currentUser?.uid) {
      dispatch(getUsers());
      dispatch(getClasses());
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedClass) {
      setStudents(users.filter((u: Student) => u.role === 'student' && u.classId === selectedClass.classId));
      setSubjects(allSubjects?.filter((s) => s.classid === selectedClass.classId));
    }
  }, [selectedClass]);

  const handleClassChange = (event: { target: { value: any } }) => {
    const classId = event.target.value;
    const selected = classes.find((c) => c.classId === classId);
    setSelectedClass(selected || null);
  };

  const calculateAverage = (examresults: ExamResult[]) => {
    const totalObtained = examresults.reduce(
      (sum, result) => sum + parseFloat(result.obtainedMarks),
      0
    );
    return (totalObtained / examresults.length).toFixed(2);
  };

  const rankStudents = (students: Student[]) => {
    return [...students].sort((a, b) => {
      const avgA = parseFloat(calculateAverage(a.examresults || []));
      const avgB = parseFloat(calculateAverage(b.examresults || []));
      return avgB - avgA;
    });
  };

  return (
    <>
      {shouldDisplayPage && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Teacher Management
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Class</InputLabel>
                <Select
                  value={selectedClass?.classId || ""}
                  onChange={handleClassChange}
                  label="Select Class"
                >
                  {filteredClasses &&
                    filteredClasses.map((c) => (
                      <MenuItem key={c.classId} value={c.classId}>
                        {c.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Term</InputLabel>
                <Select
                  value={selectedTerm}
                  onChange={handleTermChange}
                  label="Select Term"
                >
                  <MenuItem value="1st Term">Term 1</MenuItem>
                  <MenuItem value="2nd Term">Term 2</MenuItem>
                  <MenuItem value="3rd Term">Term 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Sequence</InputLabel>
                <Select
                  value={selectedSequence}
                  onChange={handleSequenceChange}
                  label="Select Sequence"
                >
                  <MenuItem value="1">Sequence 1</MenuItem>
                  <MenuItem value="2">Sequence 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {selectedClass && (
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Rank</TableCell>
                      <TableCell>Student Name</TableCell>
                      <TableCell>Sex</TableCell>
                      {subjects?.map((subject) => (
                        <TableCell key={subject.subjectId}>
                          {subject.subjectName}
                        </TableCell>
                      ))}
                      <TableCell>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rankStudents(students || []).map(
                      (student, index) => (
                        <TableRow
                          key={student.id}
                          id={`student-row-${student.id}`}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{`${student.first_name} ${student.middle_name} ${student.last_name}`}</TableCell>
                          <TableCell>{student.sex}</TableCell>
                          {subjects?.map((subject) => {
                            const result = filteredExamResults(student).find(
                              (res) =>
                                res.subjectId === subject.subjectId.toString()
                            );
                            return (
                              <TableCell key={subject.subjectId}>
                                {result ? (
                                  <>
                                    <span>{result.obtainedMarks} (</span>
                                 )
                                  </>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => {
                                const id = student.id;
                                setEditingExamResults(
                                  results.filter((r) => r.studentId == student.id) || null
                                );
                                setAddEditItem({
                                  open: true,
                                  type: "edit",
                                  id,
                                });
                              }}
                            >
                              Edit
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <AddEditInformationModal
            open={addEditItem.open}
            title="Exam Information"
            onClose={() => {
              setAddEditItem({ ...addEditItem, open: false });
              setEditingExamResults(null);
            }}
            onSave={(data) => {
              if (editingExamResults && selectedClass) {
                const updatedExamResults =
                  allSubjects?.map((subject) => {
                    const obtainedMarks =
                      data[
                      `obtainedMarks-${subject.subjectId}` as keyof typeof data
                      ] as string;

                    const existingResult = editingExamResults.find(
                      (result) =>
                        result.subjectId === subject.subjectId.toString() &&
                        result.sequence === selectedSequence &&
                        result.term === selectedTerm
                    );

                    const resultData: AddExamResult = {
                      id: existingResult ? existingResult.id : generateUUID(),
                      classId: selectedClass?.classId || "",
                      sequence: selectedSequence || "",
                      term: selectedTerm || "",
                      studentId: addEditItem.id || "",
                      subjectId: subject.subjectId.toString(),
                      totalMarks: "100",
                      obtainedMarks: obtainedMarks || "",
                    };

                    if (existingResult) {
                      if (existingResult.obtainedMarks !== obtainedMarks) {
                        dispatch(
                          updateExamResult({
                            data: resultData,
                            id: existingResult.id,
                          })
                        );
                      }
                    } else if (obtainedMarks) {
                      dispatch(addExamResults(resultData));
                    }

                    return existingResult
                      ? { ...existingResult, obtainedMarks }
                      : obtainedMarks
                        ? { ...resultData }
                        : existingResult;
                  }) || [];

                console.log("Updated Exam Results:", updatedExamResults);
              }
            }}
            onEdit={() => {
              if (addEditItem.id !== undefined) {
                handleEditResults(addEditItem.id);
              }
            }}
            data={
              subjects?.map((subject) => ({
                name: `obtainedMarks-${subject.subjectId}`,
                type: InputType.labelInput,
                label: subject.subjectName,
                placeholder: "Obtained Marks",
                optional: false,
              })) || []
            }
            type={addEditItem.type}
            formValues={
              editingExamResults
                ? editingExamResults
                  .filter(
                    (result) =>
                      result.sequence === selectedSequence &&
                      result.term === selectedTerm
                  )
                  .reduce((acc, result) => {
                    acc[`obtainedMarks-${result.subjectId}`] =
                      result.obtainedMarks;
                    return acc;
                  }, {} as { [key: string]: string })
                : {}
            }
            autocompleteOptions={[]}
          />
        </Box>
      )}
    </>
  );
};

export default TeacherManagement;