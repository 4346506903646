import { useField } from "formik";

import { CustomAutocomplete } from "./Autocomplete.style";
import { TextField } from "@mui/material";
import { Container, Label } from "../LabelInput/LabelInput.style";

interface ILabelInput {
  name: string;
  label: string;
  placeholder: string;
  data: { label: string; value: string | number }[];
  multiple?: boolean;
  onchange?: (digit: string) => void;
}

const LabelInput = ({
  label,
  placeholder,
  name,
  data,
  multiple,
  onchange,
  ...props
}: ILabelInput) => {
  const [field, meta] = useField(name);

  return (
    <Container>
      <Label>{label}</Label>
      <CustomAutocomplete
        multiple={multiple}
        disablePortal
        clearIcon={false}
        options={data}
        value={data.find((option) => option.value === field.value) || null}
        onChange={(_, newValue) => {
          if (onchange != null) {
            onchange(
              (
                newValue as { label: string; value: string | number } | null
              )?.value.toString() || ""
            );
          }
          field.onChange({
            target: {
              name,
              value: multiple
                ? (newValue as { label: string; value: string | number }[]).map(
                    (option) => option.value
                  )
                : (newValue as { label: string; value: string | number } | null)
                    ?.value || "",
            },
          });
        }}
        onBlur={field.onBlur}
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            placeholder={placeholder}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </Container>
  );
};

export default LabelInput;
