import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import { CardWrapperQuestion, QuestionHeading, Questions } from "./Question.styles";
import cap from "../../../assets/Faq/cap.png"; // Consider replacing this with a more admissions/enrollment-related icon
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const QuestionDetail2 = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <CardWrapperQuestion>
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xs={12}>
          <QuestionHeading>
            <img src={cap} alt="cap" />
            <Typography variant="h2">Admissions & Enrollment FAQs</Typography>
          </QuestionHeading>
        </Grid>

        {/* First Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(0)} align="">
            <Typography variant="h2">What are the admission requirements?</Typography>
            {openIndex === 0 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 0 && (
            <Questions align="column">
              <Typography variant="body1">
                Admission requirements vary depending on the grade level.  Generally, applicants need to submit transcripts, standardized test scores (if applicable), letters of recommendation, and complete an application form.  We also conduct student interviews.
              </Typography>
            </Questions>
          )}
        </Grid>

        {/* Second Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(1)} align="">
            <Typography variant="h2">What is the application deadline?</Typography>
            {openIndex === 1 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 1 && (
            <Questions align="column">
              <Typography variant="body1">
                The application deadline for the [Year - Year] academic year is [Date].  We encourage you to apply early as spaces may fill up quickly.  Please check our website for the most up-to-date information on deadlines.
              </Typography>
            </Questions>
          )}
        </Grid>


        {/* Third Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(2)} align="">
            <Typography variant="h2">How do I enroll my child after being accepted?</Typography>
            {openIndex === 2 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 2 && (
            <Questions align="column">
              <Typography variant="body1">
                After your child is accepted, you will receive an enrollment packet with detailed instructions on completing the enrollment process.  This typically includes submitting required forms, paying enrollment fees, and attending a new student orientation.
              </Typography>
            </Questions>
          )}
        </Grid>
      </Grid>
    </CardWrapperQuestion>
  );
};

export default QuestionDetail2;