import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {baseUrl} from "../../../utils/baseUrl";
import { FaqCategory, AddFaqCategory } from "../../../utils/types/faqManagement";



export const getCategories = createAsyncThunk<
  FaqCategory[] | null,
  string,
  { rejectValue: string[] }
>("faqCategories", async (token, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${baseUrl()}/v1/aproplat/faq_categories`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Failed to fetch categories", error);
    return rejectWithValue(["Failed to fetch data"]);
  }
});

export const addFaqCategory = createAsyncThunk<
  { message: string; faq_category: FaqCategory },
  { data: AddFaqCategory; token: string },
  { rejectValue: string[] }
>("addCategory", async ({ data, token }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${baseUrl()}/v1/aproplat/faq_categories`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to create category", error);
    return rejectWithValue(["Failed to create category"]);
  }
});

export const updateFaqCategory = createAsyncThunk<
  FaqCategory,
  { data: AddFaqCategory; token: string; id: number },
  { rejectValue: string[] }
>("updateCategory", async ({ data, token, id }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(
      `${baseUrl()}/v1/aproplat/faq_categories/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to update category", error);
    return rejectWithValue(["Failed to update category"]);
  }
});

export const deleteFaqCategory = createAsyncThunk<
  { message: string; id: number },
  { id: number; token: string },
  { rejectValue: string[] }
>("deleteCategory", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      `${baseUrl()}/v1/aproplat/faq_categories/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { message: response.data, id };
  } catch (error) {
    console.log("Failed to delete category", error);
    return rejectWithValue(["Failed to delete category"]);
  }
});
