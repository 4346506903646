import Prophet from "../prophet/prophet";
import Revolution from "../revolution/revolution";
import { AboutRevContainer, AboutRevLine, AboutRevRow, AboutRevTitle, MissionContainer } from "./aboutRev.styles";

export default function AboutRev(){

    return(<AboutRevContainer>
<AboutRevRow><AboutRevLine/><AboutRevTitle><i>About the Prophet</i></AboutRevTitle><AboutRevLine></AboutRevLine>
</AboutRevRow><Prophet />
<AboutRevRow><AboutRevLine/><AboutRevTitle><i>About the Revolution</i></AboutRevTitle><AboutRevLine></AboutRevLine>
</AboutRevRow>
<Revolution /> 
<MissionContainer>A place to be empowered<br/> by life changing messages from God's servant, <br/>Prophet Aldrian</MissionContainer>
    </AboutRevContainer>)
} 