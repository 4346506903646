

import { InputType } from "../constants/enums/inputTypes";
import { Class, Subject } from "./types/classManager";
interface InputDefinition {
    name: string;
    type: InputType;
    label: string;
    placeholder?: string;
    optional?: boolean;
    extra?: { label: string; value: string | number }[];
  }
  
 const roleSpecificInputs = (role: string, classes?: Class[]): InputDefinition[] => {


    const commonInputs = [
      {
        name: "fullName",
        type: InputType.labelInput,
        label: "User Name",
        placeholder: "User Name",
      },

      {
        name: "email",
        type: InputType.labelInput,
        label: "Email",
        placeholder: "Email",
      },
      {
        name: "password",
        type: InputType.password,
        label: "Password",
        placeholder: "Password",
      },
      {
        name: "phone",
        type: InputType.labelInput,
        label: "Phone Number",
        placeholder: "Phone Number",
      },
      {
        name: "password_confirmation",
        type: InputType.password,
        label: "Password Confirmation",
        placeholder: "Password Confirmation",
      },
      {
        name: "avatar_url",
        type: InputType.image,
        label: "Profile Picture",
        placeholder: "Profile Picture",
      },
      {
        name: "role_id",
        type: InputType.autocomplete,
        label: "Role",
        extra: [
          { label: "student", value: "student" },
          { label: "administrator", value: "administrator" },
          { label: "teacher", value: "teacher" },
          { label: "super admin", value: "super_admin" },
        ],
      },
    ];

    switch (role) {
      case "student":
        return [
          ...commonInputs,
          {
            name: "birthday",
            type: InputType.datepicker,
            label: "Birthday",
            optional: true,
          },
          {
            name: "sex",
            type: InputType.autocomplete,
            label: "Sex",
            extra: [
              { label: "male", value: "male" },
              { label: "female", value: "female" },
              { label: "other", value: "other" },
            ],
          },
          {
            name: "religion",
            type: InputType.labelInput,
            label: "Religion",
            placeholder: "Religion",
          },         {
            name: "matricule",
            type: InputType.labelInput,
            label: "matricule",
            placeholder: "matricule",
          },
          {
            name: "bloodGroup",
            type: InputType.labelInput,
            label: "Blood Group",
            placeholder: "Blood Group",
          },
          {
            name: "address",
            type: InputType.labelInput,
            label: "Address",
            placeholder: "Address",
          },
          {
            name: "graduationYear",
            type: InputType.datepicker,
            label: "Graduation Year",
            optional: true,
          },
          {
            name: "fatherName",
            type: InputType.labelInput,
            label: "Father's Name",
            placeholder: "Father's Name",
          },
          {
            name: "motherName",
            type: InputType.labelInput,
            label: "Mother's Name",
            placeholder: "Mother's Name",
          },
          {
            name: "classId",
            type: InputType.autocomplete,
            label: "Class",
            extra: classes?.map((c) => ({ label: c.name, value: c.classId })),
          },

        ];
      case "teacher":
        return [
          ...commonInputs,
          {
            name: "classes",
            type: InputType.select,
            label: "Teacher Classes",
            extra: classes?.map((c) => ({ label: c.name, value: c.classId })),
          },
       
        ];
      case "administrator":
      case "super_admin":
        return commonInputs;
      default:
        return commonInputs;
    }
  };

  export {roleSpecificInputs};