
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  getClasses,
  addClass,
  updateClass,
  deleteClass,
  addSubject,
  deleteSubject,
} from "./classService";
import { ClassState } from "../../utils/types/classManager";

const initialState: ClassState = {
  classes: [],
  subjects: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Classes
      .addCase(getClasses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClasses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.classes = action.payload?.classes || [];
        state.subjects = action.payload?.subjects || []
      })
      .addCase(getClasses.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to fetch classes";
      })

      // Add Class
      .addCase(addClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.classes.push(action.payload);
        state.successMessage = "Class added successfully!";
        toast.success("Class added successfully!");
      })
      .addCase(addClass.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to add class";
      })

      // Update Class
      .addCase(updateClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const updatedClass = action.payload;
        const index = state.classes.findIndex(
          (cls) => cls.classId === updatedClass.classId
        );
        if (index !== -1) {
          state.classes[index] = updatedClass;
        }
        state.successMessage = "Class updated successfully!";
        toast.success("Class updated successfully!");
      })
      .addCase(updateClass.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to update class";
      })

      // Delete Class
      .addCase(deleteClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.classes = state.classes.filter(
          (cls) => cls.classId !== action.payload.id
        );
        state.successMessage = "Class deleted successfully!";
        toast.success("Class deleted successfully!");
      })
      .addCase(deleteClass.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to delete class";
      })
      .addCase(addSubject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSubject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        const newSubject = action.payload;
        const classIndex = state.classes.findIndex(
          (cls) => cls.classId === newSubject.classid
        );
        if (classIndex !== -1) {
          state.subjects?.push(newSubject); // Add the new subject to the class
        }
        state.successMessage = "Subject added successfully!";
        toast.success("Subject added successfully!");
      })
      .addCase(addSubject.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to add subject";
        toast.error(state.error);
      })

      // Delete Subject
      .addCase(deleteSubject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSubject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // Assuming the deleteSubject action payload includes the deleted subject's ID
        const deletedSubjectId = action.meta.arg.subjectId;
        state.subjects = state.subjects?.filter(subject => subject.subjectId.toString() !== deletedSubjectId);
        state.successMessage = "Subject deleted successfully!";
        toast.success("Subject deleted successfully!");
      })
      .addCase(deleteSubject.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to delete subject";
        toast.error(state.error);
      });
  },
});

export default classSlice.reducer;
