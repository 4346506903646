import {
  Box,
  Dialog,
  DialogContent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    width: "600px",
    borderRadius: "12px",
    filter: "drop-shadow(0px 8px 24px rgba(44, 63, 88, 0.10))",
    paddingTop: "25px",
    paddingBottom: "59px",
  },
}));

export const StyledTabs = styled(Tabs)(() => ({
  padding: "0px 30px",
  borderBottom: "1px solid #E8E9EB",
  "& .MuiTabs-flexContainer": {
    gap: "70px",
  },
  "& .MuiTabs-indicator": {
    width: "96px",
    minWidth: "96px",
  },
}));

export const StyledTab = styled(Tab)(() => ({
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 500,
  textTransform: "uppercase",
  padding: "16px 16px 16px 0px",
  alignItems: "flex-start",
}));

export const Input = styled(TextField)(() => ({
  width: "100%",
  border: "1px solid white",
  outline: "none",
}));

export const ButtonsBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  marginTop: "44px",
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
  padding: "22px 30px 0px 30px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: "#3F434A",
  fontFamily: "Poppins",
  fontSize: "28px",
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px",
  },
}));

export const InputsContainer = styled(Box)(() => ({
  paddingTop: "23px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "22px",
  mb: "37px",
}));
