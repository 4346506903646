import { Box, Button, styled, Typography } from "@mui/material";
import Slider from "react-slick";

export const ChurchHeroContainer = styled(Box)(({theme})=>({
    display:'flex',
    flexDirection:'column',

 borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
   
    minHeight: '50vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', // Adjust background size for better responsiveness
    backgroundPositionY: 'center',
    backgroundPositionX: 'right',
    width: '100%',
    minWidth: '70%',
    maxWidth: '100%',

    [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '40vh', // Adjust height for smaller screens
        maxHeight: '50vh',
        height: '50vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const ChurchHeroContainerOverlay = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(0,0,0,0.5)',
    minHeight: '50vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',

    [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '40vh', // Adjust height for smaller screens
        maxHeight: '50vh',
        height: '50vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const ChurchHeroTitle = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: 'white',
    marginTop: '7%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const ChurchHeroText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    color: 'white',
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '1%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const ChurchHeroSlide = styled(Box)(({theme}) => ({
    width: "100%",flexDirection:'row',
    minWidth: '90%',
    maxWidth: '100%',
    height: '89vh',
   
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: '0%',
    },
}));
export const ChurchHeroButton = styled(Button)(({ theme }) => ({
    color: 'blueViolet',marginTop:'1%',marginBottom:'6%',
    fontSize: '1rem',
    height: '5%',
    border: '1px solid white',
    width: '30%',
    minWidth: '10%',
    maxWidth: '50%', 
    paddingLeft: '1%',marginLeft:'37%',background:'linear-gradient(to bottom,white,white)',
    [theme.breakpoints.down('md')]: {
        width: '45%', // Make buttons full-width on smaller screens
        marginLeft:'25%',height:'6vh',fontSize:'0.8rem', paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));