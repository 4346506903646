import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {baseUrl} from "../../../utils/baseUrl";
import { Faq, AddFaq } from "../../../utils/types/faqManagement";



export const getFaqs = createAsyncThunk<
  Faq[] | null,
  string,
  { rejectValue: string[] }
>("faqCategories", async (token, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${baseUrl()}/v1/aproplat/faqs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch categories", error);
    return rejectWithValue(["Failed to fetch data"]);
  }
});

export const addFaq = createAsyncThunk<
  { message: string; faq: Faq },
  { data: AddFaq; token: string },
  { rejectValue: string[] }
>("addFaq", async ({ data, token }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${baseUrl()}/v1/aproplat/faqs`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Failed to create Faq", error);
    return rejectWithValue(["Failed to create Faq"]);
  }
});

export const updateFaq = createAsyncThunk<
  Faq,
  { data: AddFaq; token: string; id: number },
  { rejectValue: string[] }
>("updateFaq", async ({ data, token, id }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(
      `${baseUrl()}/v1/aproplat/faqs/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to update Faq", error);
    return rejectWithValue(["Failed to update Faq"]);
  }
});

export const deleteFaq = createAsyncThunk<
  { message: string; id: number },
  { id: number; token: string },
  { rejectValue: string[] }
>("deleteFaq", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`${baseUrl()}/v1/aproplat/faqs/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { message: response.data, id };
  } catch (error) {
    console.log("Failed to delete Faq", error);
    return rejectWithValue(["Failed to delete Faq"]);
  }
});
