import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import { CardWrapperQuestion, QuestionHeading, Questions } from "./Question.styles";
import diamond from "../../../assets/Faq/diamond.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const QuestionDetail = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <CardWrapperQuestion>
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xs={12}>
          <QuestionHeading>
            <img src={diamond} alt="diamond" />
            <Typography variant="h2">About Our School - FAQs</Typography>
          </QuestionHeading>
        </Grid>

        {/* First Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(0)} align="">
            <Typography variant="h2">What is the school's mission and vision?</Typography>
            {openIndex === 0 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 0 && (
            <Questions align="column">
              <Typography variant="body1">
                Our mission is to provide a nurturing and stimulating learning environment where every student can thrive academically, socially, and emotionally. We strive to foster a love of learning, critical thinking skills, and a sense of global citizenship. Our vision is to be a leading educational institution recognized for its excellence in teaching, innovation, and community engagement.
              </Typography>
            </Questions>
          )}
        </Grid>

        {/* Second Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(1)} align="">
            <Typography variant="h2">What programs and extracurricular activities do you offer?</Typography>
            {openIndex === 1 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 1 && (
            <Questions align="column">
              <Typography variant="body1">
                We offer a comprehensive academic program that includes core subjects, as well as elective courses in arts, music, sports, and technology. Our extracurricular activities range from sports teams and clubs to community service projects and leadership development programs.  We believe in providing a well-rounded education that caters to the diverse interests and talents of our students.
              </Typography>
            </Questions>
          )}
        </Grid>

        {/* Third Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(2)} align="">
            <Typography variant="h2">What is the admissions process?</Typography>
            {openIndex === 2 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 2 && (
            <Questions align="column">
              <Typography variant="body1">
                Our admissions process typically involves submitting an application form, providing academic transcripts, and participating in an interview. We encourage prospective students and their families to visit our campus and learn more about our school community.  You can find detailed information about the admissions requirements and deadlines on our website.  Please contact the admissions office for any further inquiries.
              </Typography>
            </Questions>
          )}
        </Grid>
      </Grid>
    </CardWrapperQuestion>
  );
};

export default QuestionDetail;