import { HeroAboutContainer, HeroAboutContainerOverlay, HeroAboutContainerOverlaytext, HeroAboutText, HeroAboutTitle, HeroAboutTexttwo, HeroAboutRow, HeroAboutButton, HeroAboutButtontwo } from "../../about/heroAbout/heroAbout.styles";

export default function HeroContact(){
    return(<HeroAboutContainer><HeroAboutContainerOverlay>
        <HeroAboutContainerOverlaytext><HeroAboutTitle>Contact  Us</HeroAboutTitle>
        
      
</HeroAboutContainerOverlaytext>
       
        </HeroAboutContainerOverlay></HeroAboutContainer>)
}