
import { createSlice } from "@reduxjs/toolkit";
import {

  login,
  registerUser,

  resetPassword,

} from "./authService";
import { AuthState } from "../../utils/types";
import { RUser } from "../../utils/types/userManagement";

const initialState: AuthState = {
  user: null,
  isLoading: false,
  accountState: null,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  authenticated: false,
  successMessage: null,ownerAttributes:null,
  // token: null,
  signupData: null,

  paymentSelected: false,
  payment: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.hasError = false;
      state.isSuccess = false;
      state.hasWarning = false;
      // state.authenticated = false;
      state.error = "";
    },

    logout: () => {
      localStorage.clear();
      return initialState;
    },
    selectPackage: (state, action) => {
      state.payment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.hasWarning = false;
        state.hasError = false;
        state.isSuccess = false;
        state.authenticated = false;

        state.signupData = action.meta.arg;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.isLoading = false;
        // state.user = action.payload;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.accountState = "created";
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        console.log("error to signup", action.payload);
        state.error = action.payload as unknown as string;
        state.accountState = null;
      })

      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.authenticated = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.user = null;
        state.error = (action.payload as string[])[0];
        state.accountState = null;
        state.authenticated = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasWarning = false;
        state.hasError = false;
        state.isSuccess = true;
    
        if (action.payload === null) {
            state.error = "Login failed"; // Or a more specific error message
            state.authenticated = false;
        } else {
            state.error = null; // Clear error on successful login
            state.authenticated = true;
            state.user = action.payload as RUser; // Safe cast after null check
        }
    })
 


      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.user = null;
        state.error = (action.payload as string[])[0];
        state.accountState = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasWarning = false;
        state.hasError = false;
        state.isSuccess = true;
        // state.successMessage = action.payload as string;
        state.successMessage =
          "Password reset link sent. please check your email !";
        console.log("reset has fulfiled with", action.payload);

      })
     
  },
});

export const {
  refreshState,

  selectPackage,
  logout,
} = authSlice.actions;
export default authSlice.reducer;
// updateUser
// updateProfilePicture
//deleteAccount
