import { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddUserButton from "../../../../components/Dashboard/AddUserButton";
import AddEditInformationModal from "../../../../components/modals/AddEditInformationModal";
import { getClasses } from "../../../../redux/classManager/classService";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getUsers, deleteUser } from "../../../../redux/userManagement/userManagementService";
import { roleSpecificInputs } from "../../../../utils/rolesSelect";
import { saveUser } from "../../../../utils/saveUser";
import { Teacher, Administrator, RUser, Student, superAdmin } from "../../../../utils/types/userManagement";
import { auth } from "../../../../redux/firebase";


export function generateUUID() {
  // Create an array to store the bytes
  const array = new Uint8Array(16);

  // Get 16 random bytes
  window.crypto.getRandomValues(array);

  // Convert the bytes to a UUID string (version 4)
  // Set the version bits (4) and the variant bits (2)
  array[6] = (array[6] & 0x0f) | 0x40;
  array[8] = (array[8] & 0x3f) | 0x80;

  // Convert each byte to a hexadecimal string and join them
  const uuid = Array.from(array, (byte) =>
    byte.toString(16).padStart(2, "0")
  ).join("-");

  return uuid;
}

const initialValues = {
  username: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  role_id: "",
  password: "",
  password_confirmation: "",
  phone: "",
  avatar_url: "",
  birthday: new Date().toISOString().split("T")[0],
  sex: "",
  religion: "",
  bloodGroup: "",
  address: "",
  graduationYear: new Date().toISOString().split("T")[0],
  fatherName: "",
  motherName: "",
  classId: "",

  classes: [""],
};

type AddEditItem = {
  open: boolean;
  type: "add" | "edit";
  id: string | undefined;
};

export const UserManagement = () => {
  const dispatch = useAppDispatch();
  const { user, isLoading, authenticated } = useAppSelector(
    (state) => state.auth
  );
  const [isAuth, setIsAuth] = useState(false);
  const [shouldDisplayPage, setShouldDisplayPage] = useState(false);
  const router = useNavigate();
  useEffect(() => {
    if (isLoading) return;

    if (!isLoading && user === null) {
      router("/login");
    }

    if (authenticated && user) {
      setIsAuth(true);
    }
    if (isAuth && user?.role === "super_admin") {
      setShouldDisplayPage(true);
    }else     if (user?.user_role === "teacher") {
      router("/dashboard/teacher/");
    }
  }, [authenticated, isAuth, isLoading, router, user, user?.user_role]);
  const { users } = useAppSelector((state) => state.users);
 
  const { classes , subjects} = useAppSelector((state) => state.classes);

  const [addEditItem, setAddEditItem] = useState<AddEditItem>({
    open: false,
    type: "add",
    id: undefined,
  });
  const [fieldValues, setFieldValues] = useState(initialValues);

  const [AllUser, setAllUsers] = useState<RUser[]>([]);

  useEffect(() => {
    if (auth.currentUser?.uid) {
      dispatch(getUsers());
      setAllUsers(users);
      dispatch(getClasses());
    }
  }, [ dispatch, users]);

  const handleAddUser = () => {
    setFieldValues(initialValues);
    setAddEditItem({ open: true, type: "add", id: undefined });
  };

  const handleEditUser = (id: string) => {
    const currentUser = users?.find((u: { id: string; }) => u.id === id);
    if (currentUser) {
      if (currentUser.role === "student") {
        const student = currentUser as Student;
        setFieldValues({
          ...initialValues,
          username: student.username,
          first_name: student.first_name,
          middle_name: student.middle_name,
          last_name: student.last_name,
          email: student.email,
          role_id: student.role,
          phone: student.phone,
          avatar_url: student.avatar_url || "",
          birthday: student.birthday || initialValues.birthday,
          sex: student.sex || "",
          religion: student.religion || "",
          bloodGroup: student.bloodGroup || "",
          address: student.address || "",
          graduationYear:
            student.graduationYear || initialValues.graduationYear,
          fatherName: student.fatherName || "",
          motherName: student.motherName || "",
          classId: student.classId || "",
      
        });
      } else if (currentUser.role === "teacher") {
        const teacher = currentUser as Teacher;
        setFieldValues({
          ...initialValues,
          username: teacher.username,
          first_name: teacher.first_name,
          middle_name: teacher.middle_name,
          last_name: teacher.last_name,
          email: teacher.email,
          role_id: teacher.role,
          phone: teacher.phone,
          avatar_url: teacher.avatar_url || "",
          classes: teacher.classes || [""],
       
        });
      } else if (currentUser.role === "super_admin") {
        const superAdmin = currentUser as superAdmin;
        setFieldValues({
          ...initialValues,
          username: superAdmin.username,
          first_name: superAdmin.first_name,
          middle_name: superAdmin.middle_name,
          last_name: superAdmin.last_name,
          email: superAdmin.email,
          role_id: superAdmin.role,
          phone: superAdmin.phone,
          avatar_url: superAdmin.avatar_url || "",
        });
      } else {
        const administrator = currentUser as Administrator;
        setFieldValues({
          ...initialValues,
          username: administrator.username,
          first_name: administrator.first_name,
          middle_name: administrator.middle_name,
          last_name: administrator.last_name,
          email: administrator.email,
          role_id: administrator.role,
          phone: administrator.phone,
          avatar_url: administrator.avatar_url || "",
          classes: administrator.classes || [""],
        });
      }
      setAddEditItem({ open: true, type: "edit", id });
    }
  };
  const data = [
    { label: "student", value: "student" },
    { label: "administrator", value: "administrator" },
    { label: "teacher", value: "teacher" },
    { label: "super admin", value: "super_admin" },
  ];
  const handleSaveUser = (data: any) => {
    saveUser(data, addEditItem, dispatch, setAddEditItem, subjects );
  };

  const handleRemoveUser = (id: string) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      console.log("hi");
      dispatch(deleteUser({ id }));
    }
  };

  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  const handleChange = (event: { target: { value: any } }) => {
    const role = event.target.value;
    setSelectedRole(role);
  };
  useEffect(() => {
    if (selectedRole !== null && addEditItem.type === "add") {
      fieldValues.role_id = selectedRole;
    }
  }, [addEditItem.type, fieldValues, selectedRole]);

  return (
    <>
 
   { shouldDisplayPage &&  <Box sx={{ p: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel>Select Role</InputLabel>
            <Select
              value={selectedRole || ""}
              onChange={handleChange}
              label="Select role"
            >
              {data.map((c) => (
                <MenuItem key={c.value} value={c.value}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            {selectedRole && (
              <AddUserButton text="User" onClick={handleAddUser} />
            )}
          </Box>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AllUser &&
                AllUser.map(
                  (s) =>
                    s.role !== "student" && (
                      <TableRow key={s.id}>
                        <TableCell>{s.username}</TableCell>
                        <TableCell>{`${s.first_name} ${s.middle_name} ${s.last_name}`}</TableCell>
                        <TableCell>{s.email}</TableCell>
                        <TableCell>{s.role}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleEditUser(s.id)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => handleRemoveUser(s.id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <AddEditInformationModal
          open={addEditItem.open}
          title="User Information"
          onClose={() => setAddEditItem({ ...addEditItem, open: false })}
          onSave={handleSaveUser}
          onEdit={() => {
            if (addEditItem.id !== undefined) {
              handleEditUser(addEditItem.id);
            }
          }}
          data={roleSpecificInputs(
            fieldValues.role_id?.toString() || "",
            classes
          )}
          type={addEditItem.type}
          formValues={fieldValues}
          autocompleteOptions={[]}
        />
      </Box>}
    </>
  );
};

