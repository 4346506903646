import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FieldAttributes, useField } from "formik";
import React, { useState } from "react";

interface InputProps extends FieldAttributes<any> {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
}

interface Option {
  label: string;
  id: number;
}

interface AutocompleteProps extends FieldAttributes<any> {
  // country_name: string;
  name: string;
  placeholder: string;
  options: Option[];
}



export const AutocompleteField: React.FC<AutocompleteProps> = ({
  label,
  name,
  options,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  return (
    <div>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.name}
        value={field.value}
        defaultValue={options[0]}
        // onChange={(event, value) => {
        //   console.log("new val in formik is", value);
        //   helpers.setValue(value);
        // }}
        onBlur={() => helpers.setTouched(true)}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            label={label}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            variant="standard"
            placeholder="Select"
          />
        )}
      />
    </div>
  );
};

export const InputField: React.FC<InputProps> = ({
  // label,
  name,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <div>
      {/* <label htmlFor={name}>{label}</label> */}
      <TextField
        {...field}
        {...props}
        // label={label}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        variant="standard"
      />
    </div>
  );
};

export const NumberField: React.FC<InputProps> = ({
  // label,
  name,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <div>
      {/* <label htmlFor={name}>{label}</label> */}
      <TextField
        {...field}
        {...props}
        // label={label}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        variant="standard"
        inputMode="numeric"
        type="number"
        // pattern="[0-9]*"
      />
    </div>
  );
};

export const DateField: React.FC<InputProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...field}
          {...props}
          value={field.value || null}
          onChange={(date) => {
            const formattedDate =
              date instanceof Date ? date.toISOString() : null;
            field.onChange({
              target: {
                name: field.name,
                value: formattedDate,
              },
            });
          }}
        />
      </LocalizationProvider>
      {meta.touched && meta.error ? (
        <p
          className="error"
          style={{
            color: "red",
            display: "flex",
            justifyContent: "start",
            fontSize: "14px",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </div>
  );
};

export const PasswordField: React.FC<InputProps> = ({
  // label,
  name,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <TextField
        {...field}
        {...props}
        // label={label}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        variant="standard"
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {meta.touched && meta.error ? (
        <p className="error">{meta.error}</p>
      ) : null}
    </div>
  );
};

export const OutlinedInputField: React.FC<InputProps> = ({
  // label,
  name,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <div>
      {/* <label htmlFor={name}>{label}</label> */}
      <TextField
        {...field}
        {...props}
        // label={label}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        variant="standard"
        sx={{
          width: "100%",
          margin: meta.touched && !!meta.error ? "40px 0 20px 0" : "",
        }}
      />

      {meta.touched && meta.error ? (
        <p
          className="error"
          style={{
            color: "red",
            display: "flex",
            justifyContent: "end",
            fontSize: "14px",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </div>
  );
};

export const OutlinedPasswordField: React.FC<InputProps> = ({
  // label,
  name,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      {/* <label htmlFor={name}>{label}</label> */}

      <TextField
        {...field}
        {...props}
        // label={label}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        // helperText={meta.touched && meta.error}
        sx={{
          width: "100%",
          margin: meta.touched && !!meta.error ? "40px 0 20px 0" : "",
        }}
        variant="standard"
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {meta.touched && meta.error ? (
        <p
          className="error"
          style={{
            color: "red",
            display: "flex",
            justifyContent: "end",
            fontSize: "14px",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </div>
  );
};

export const CheckBox: React.FC<InputProps> = ({
  children,
  // label,
  name,
  // placeholder,
  ...props
}) => {
  const [field, meta] = useField(name);
  return (
    <div>
      <label className="checkbox-input">
        <Checkbox {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div>
          <FormControl
            required
            error={true}
            component="fieldset"
            variant="standard"
            sx={{ pl: 1 }}
          >
            <FormHelperText>{meta.error}</FormHelperText>
          </FormControl>
        </div>
      ) : null}
    </div>
  );
};

const formikFields = {
  InputField,
  CheckBox,
  PasswordField,
  OutlinedPasswordField,
  NumberField,
  AutocompleteField,
};
export default formikFields;
