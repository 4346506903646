import { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

import SimpleCheckbox from "./simplchechbox";
import { Class, Subject } from "../../../utils/types/classManager";
import { generateUUID } from "../../../pages/dashboard/administrator/settings";
import { Student } from "../../../utils/types/userManagement";

export interface DataItem {
  id: string;
  name: string;
  [key: string]: any;
}

interface Props {
  data: DataItem[];
  open: boolean;
  name: string;
  label: string;
  onsave: (items: DataItem[]) => void;
  onClose: () => void;
  endAdornment?: string;
  type?: "students" | "subjects" | "other";
  classData: Class;
  searchProperty?: string;
  addNewItem?: (newItemName: string) => DataItem | undefined;
  Astudents: Student[],
  Asubjects: Subject[]
}

const ListSelect = ({
  data,
  open,
  label,
  onsave,
  onClose,
  type,
  classData,
  searchProperty = "name",
  addNewItem,Astudents}: Props) => {

  const [items, setItems] = useState<DataItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<DataItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [newItemName, setNewItemName] = useState<string>("");
const [students]=useState<Student[]>([]);
const [subjects]=useState<Subject[]>([]);
  useEffect(() => {
    setItems([...data]);
  
    // Pre-select items already included in the relevant data, handling potential undefined
    const initialSelectedItems = data.filter(item => {
      if (type === "students") {
        return students?.some(student => student.id === item.id) ?? false;
      } else if (type === "subjects") {
        return subjects.some(subject => subject.subjectId.toString() === item.id && subject.classid===classData.classId) ?? false;
      } else { 
        return false; // No pre-selection for other types by default
      }
    });
  
    setSelectedItems(initialSelectedItems);
  }, [data, type, students, subjects,classData.classId ]);

  const handleToggle = (item: DataItem) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.some((selectedItem) => selectedItem.id === item.id)) {
        return prevSelected.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSearch = () => {
    if (type === "students") {
      const foundStudent = Astudents.filter((u)=>u.role==='student').map((u)=>u as Student)?.find(
        (student) =>
          student.first_name.toLowerCase() === searchTerm.toLowerCase()
      );
      if (foundStudent && !items.some((item) => item.id === foundStudent.id)) {
        const newDataItem: DataItem = {
          id: foundStudent.id,
          name: `${foundStudent.first_name} ${foundStudent.middle_name} ${foundStudent.last_name}`,
        };
        setItems([...items, newDataItem]);
      }
    } else if (type === "other" && searchProperty) {
      const filteredItems = data.filter(
        (item) =>
          item[searchProperty]
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) &&
          !items.some((existingItem) => existingItem.id === item.id)
      );
      setItems([...items, ...filteredItems]);
    }
    setSearchTerm("");
  };
  const handleAddNewItem = () => {
    if (type === "subjects" && newItemName.trim()) {
      const [subjectName,  coefficientStr] = newItemName.split(',');

      const coefficient = coefficientStr?.trim() || "5"; // Default to "5" if not provided

      const newSubjectObj: Subject = {
        subjectId: generateUUID(),
        subjectName: subjectName.trim(),
        classid: classData.classId,
        coefficient: coefficient,

      };

      const newDataItem: DataItem = {
        id: newSubjectObj.subjectId.toString(),
        name: newItemName.trim() // Keep the full string here if needed
      };

      setItems([...items, newDataItem]);
    } else if (type === "other" && addNewItem && newItemName.trim()) {
      const newItem = addNewItem(newItemName.trim());
      if (newItem) {
        setItems([...items, newItem]);
      }
    }
    setNewItemName("");
};
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        {(type === "students" || type === "other") && (
          <TextField
            label={`Search ${type === "students" ? "Students" : ""}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onBlur={handleSearch}
            fullWidth
          />
        )}
        {(type === "subjects" || type === "other") && addNewItem && (
          <TextField
            label={type === "subjects" ? "Add New Subject" : "Add New Item"}
            value={newItemName}
            onChange={(e) => setNewItemName(e.target.value)}
            onBlur={handleAddNewItem}
            fullWidth
          />
        )}
        <List>
          {items.map((item) => (
            <ListItem
              key={item.id}
              disablePadding
              
              onClick={() => handleToggle(item)}
            >
              <ListItemAvatar>
                <Avatar>{item.name.charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.name} />
              <SimpleCheckbox
                checked={selectedItems.some(
                  (selectedItem) => selectedItem.id === item.id
                )}
                onChange={() => handleToggle(item)}
                label=""
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onsave(selectedItems)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ListSelect;
