import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Input, Typography } from "@mui/material";
import bg from "../../../assets/Footer.png";


export const FooterContainer = styled(Box)(() => ({
  backgroundImage: `url(${bg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: "40px 30px",
}));
export const FooterWrapper = styled(Box)(({ theme }) => ({
  background: 'rgba(138,43,226,0.6)',
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  height: "76px",
  [theme.breakpoints.down('md')]: {
   textAlign: 'center',
  } 
}));
export const FotterItems = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "10px",
  fontFamily: "Inter",
  color: "white",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    gap: "1rem",

    marginBottom: "2rem",
  },
}));
export const FooterHeadings = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "19px",
  fontFamily: "Inter",
  marginBottom: "10px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "1.5rem",
  },
}));
export const SocialLinks = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",
}));
export const FooterLinks = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "12px",
  fontFamily: "Inter",
  cursor: "pointer",
  "& a": {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "12px",
    fontFamily: "Inter",
    cursor: "pointer",
  },
}));
export const FooterAddress = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "17px",
  fontFamily: "Inter",
  cursor: "pointer",
}));
export const NewsInput = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));
export const InputField = styled(Input)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: "1px solid #05BCF5",
  borderRadius: "5px 0px 0px 5px",
  paddingLeft: "5px",
  color: theme.palette.text.primary,
}));

export const Progress = styled(CircularProgress)({
  width: "10px",
  height: "10px",
});
