
import { Grid, Typography } from "@mui/material";

import schoolLogo from "../assets/logo.jpg"; 
import { Class } from "./types/classManager";


const Letterhead: React.FC<{ classData: Class | null; selectedTerm?: string; }> = ({
  classData,
  selectedTerm,
}) => {

  // Get the current date
  const currentDate = new Date().toLocaleDateString('en-GB', { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  }); 

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      sx={{
        marginBottom: 3,
        padding: 2,
      }}
    >
      <Grid item xs={4} sx={{ textAlign: 'center' }}> 
        {/* Left Section Content */}
        <Typography variant="body1" sx={{ textAlign: 'center' }}> 
          REPUBLIQUE DU CAMEROUN <br />
          Paix - Travail - Patrie <br />
          <br />
          MINISTERE DES ENSEIGNEMENTS SECONDAIRES <br />
          DELEGATION REGIONALE DU SUD-OUEST <br />
          {/* Make the school name bold */}
          <strong>VOLUNTEERS FOR EXCELLENCE SCHOOL</strong> <br />
          Discipline de Travail Acharne et Succes <br />
          P.O. Box 195 Kumba <br />
          Tel: +237677798613 <br />
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="center">
        {/* Center Section (Logo) */}
        <img 
          src={schoolLogo} 
          alt="School Logo" 
          width={150} 
          height={150} 
        /> 
      </Grid>
      <Grid item xs={4} sx={{ textAlign: "center" }}>
        {/* Right Section Content */}
        <Typography variant="body1">
          REPUBLIC OF CAMEROON <br />
          Peace - Work - Fatherland <br />
          <br />
          MINISTRY OF BASIC EDUCATION <br />
          REGIONAL DELEGATION FOR THE SOUTH WEST <br />
          {/* Make the school name bold */}
          <strong>VOLUNTEERS FOR EXCELENCE SCHOOL</strong> <br />
          HARDWORK DISCIPLINE AND SUCCESS <br />
          P.O. Box 195 Kumba <br />
          Email: info@ves.org <br />
          Website: ves.org <br />
       
          Class: {classData?.name} <br />
          Printed Date: {currentDate}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Letterhead;