import { styled } from "@mui/material/styles";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import img1 from "../../assets/emailVerified/background.jpg";
export const HeroEmail = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  backgroundImage: `url(${img1})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  padding: "7rem 0px 5px",
}));
export const BackgroundFlex = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  gap: "80px",
  width: "70%",
  margin: "9rem auto 17px",
  background: " rgba(211, 255, 210, 0.78)",
  border: "3px solid #FFFFFF",
  borderRadius: "10px",
  padding: "26px 30px",
  [theme.breakpoints.down("lg")]: {
    width: "89%",
  },
  [theme.breakpoints.down("md")]: {
    gap: "42px",
  },
  [theme.breakpoints.down("tablet")]: {
    gap: "42px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "74%",
    gap: "12px",
    margin: "10px auto",
    padding: "21px 28px",
  },
  "& img": {
    objectFit: "contain",
    width: "50px",
    height: "50px",
  },
  "& h2": {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "39px",
    color: " #4BA849",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "6px",
      marginBottom: "10px",
    },
  },
  "& h6": {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4A7C8C",
  },
}));

export const FailedBackgroundFlex = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  gap: "80px",
  width: "70%",
  margin: "9rem auto 17px",
  marginTop: "0px",
  background: "#ff3232",
  border: "3px solid #FFFFFF",
  color: "#fff",
  borderRadius: "10px",
  padding: "26px 30px",
  [theme.breakpoints.down("lg")]: {
    width: "89%",
  },
  [theme.breakpoints.down("md")]: {
    gap: "42px",
  },
  [theme.breakpoints.down("tablet")]: {
    gap: "42px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "74%",
    gap: "12px",
    margin: "10px auto",
    padding: "21px 28px",
  },
  "& img": {
    objectFit: "contain",
    width: "50px",
    height: "50px",
  },
  "& h2": {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "39px",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "6px",
      marginBottom: "10px",
    },
  },
  "& h6": {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#ffffff",
  },
}));

export const Centering = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  " & .css-9mgopn-MuiDivider-root": {
    width: "100%",
    margin: "60px auto 50px",
    border: "1px solid #C2C2C2",
  },

  "& h1": {
    width: "98%",
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "25px",
    lineHeight: "30px",
    // color: "#000000",
    color: theme.palette.text.primary,

    margin: " 30px auto 100px",
  },
  "& h3": {
    width: "73%",
    margin: " 30px auto 100px",
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#3F3F3F",
  },
  "& h4": {
    width: "730px",
    margin: "40px auto 100px",
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    // color: "#3F3F3F",
    color: theme.palette.text.primary,

    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
}));
export const ColorForm = styled(Box)(({ theme }) => ({
  width: "48%",
  background: theme.palette.warning.main,
  border: "4px solid #FFFEFE",
  boxShadow:
    "0px 0px 61px 24px rgba(255, 255, 255, 0.5), inset 0px 0px 21px #FFFFFF",
  backdropFilter: "blur(12px)",
  borderRadius: "8px",
  padding: "20px 100px",
  [theme.breakpoints.down("lg")]: {
    width: "51%",
  },
  [theme.breakpoints.down("md")]: {
    width: "62%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: "10px 10px",
  },
  // [theme.breakpoints.down("tablet")]: {
  //   width: "49%",
  // },
}));
export const LastHeading = styled(Typography)(() => ({
  width: "40%",
  margin: " 40px auto 100px",
  textAlign: "center",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "24px",
  color: "#C9C9C9",
}));
export const FlexOne = styled(Box)(() => ({}));
export const Buttons = styled(Button)(() => ({
  background: " #1976BB",
  border: "3px solid #FFFFFF",
  borderRadius: "8px",
  minWidth: "200px",
  height: "80px",
  textTransform: "capitalize",
  textAlign: "center",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "26px",
  lineHeight: "44px",
  color: "#ffffff",
}));

export const VerificationContainer = styled(Box)(() => ({
  height: 600,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ResendContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Progress = styled(CircularProgress)({
  width: "10px",
  height: "10px",
});
