import { Box, Typography, styled } from "@mui/material";

export const Label = styled(Typography)(() => ({
  color: "#8A9099",
  fontFamily: "Poppins",
  fontSize: "14px",
  marginBottom: "13px",
}));

export const ImagesContainer = styled(Box)(() => ({
  marginTop: "12px",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
}));
