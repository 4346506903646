import { useField } from "formik";
import {
  Label,
  Container,
  Input,
  InputAdornmentText,
} from "./LabelInput.style";

interface ILabelInput {
  name: string;
  label: string;
  placeholder: string;
  endAdornment?: string;
  type?: string;
}

const LabelInput = ({
  label,
  placeholder,
  endAdornment,
  name,
  type,
  ...props
}: ILabelInput) => {
  const [field, meta] = useField(name);

  return (
    <Container>
      <Label>{label}</Label>
      <Input
        {...field}
        {...props}
        name={name}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        type={type || "text"}
        InputProps={
          endAdornment
            ? {
                endAdornment: (
                  <InputAdornmentText position="end">
                    {endAdornment}
                  </InputAdornmentText>
                ),
              }
            : {}
        }
      />
    </Container>
  );
};

export default LabelInput;
