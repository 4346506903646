import React from "react";

const Map = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.8112508486633!2d9.42039567445493!3d4.627738995347008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1060c7c3c291f0a7%3A0x2df6ec20298b53f0!2sThe%20Empowerment%20Church%20International%20Headquarters!5e0!3m2!1sen!2scm!4v1731777318911!5m2!1sen!2scm"
      width="100%"
      height="100%"
      style={{ border: 0 }} // Changed to an object
      allowFullScreen={true}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default Map;