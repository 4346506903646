
import { Background } from "../components/HomePage/HomeContainer.style";
import HeroSection from "../components/HomePage/heroSection/HeroSection";
import Unique from "../components/Aboutus/Unique/Unique";
import ImageBar from "../components/HomePage/imageBar/ImageBar";
import ChooseUs from "../components/Aboutus/WhyChoose/ChooseUs";
import Team from "../components/Aboutus/Founder/Team";
import User from "../components/HomePage/userCard/User";
import Started from "../components/Aboutus/Journey/Started";
import Question from "../components/FAQ/Questions/Question";
import HomePage from "../components/tec/home/HomePage";

export default function Main() {

  return (
    <>
     <Background>
      <HomePage />
     </Background>
  
    </>
  );
}