
import { ButtonType } from "../../../constants/enums/buttons";
import { StyledButton } from "./CustomButton.style";

interface ICustomButton {
  type: ButtonType;
  content: string;
  disabled?: boolean;
  buttonType?: "button" | "submit" | "reset";

  actions?: any;
}

const buttons = {
  [ButtonType.CONFIRMATION]: {
    backgroundColor: "#1573AE",
    color: "#fff",
    ":hover": {
      opacity: "0.8",
      backgroundColor: "#1573AE",
    },
  },
  [ButtonType.CANCEL]: {
    backgroundColor: "trancparent",
    color: "#595F69",
    border: "1px solid #E8E9EB",
  },
};

const CustomButton = ({
  type,
  content,
  disabled,
  buttonType = "button",
  actions,
}: ICustomButton) => {
  const currentBtn = buttons[type];

  return (
    <StyledButton
      sx={{
        ...currentBtn,
      }}
      type={buttonType}
      disabled={disabled}
      {...actions}
    >
      {content}
    </StyledButton>
  );
};

export default CustomButton;
