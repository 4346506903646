import React from "react";
import HeroSec from "../components/contactPage/heroSection/HeroSec";
import GetTouch from "../components/contactPage/getTouch/GetTouch";
import ContactScreen from "../components/tec/contact/contactScreen/contactScreen";

export default function Home() {
  return (
    <>
  <ContactScreen />
       
    </>
  );
}
