import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../assets/logo.jpg"; 
import { generateUUID } from "../pages/dashboard/administrator/userManagement";
import { Student } from "./types/userManagement";



 const downloadPDF = async (
  reportCardRef: HTMLDivElement | null,
  selectedStudent: Student | null
) => {
  if (reportCardRef) {
    const downloadButton = document.querySelector(".download-pdf-button");
 
    if (downloadButton) {
      downloadButton.classList.add("hidden");
    }

    // Temporarily adjust styles
    const originalStyles = { ...reportCardRef.style };
    reportCardRef.style.backgroundColor = "white";
    reportCardRef.style.boxShadow = "none";

    // Load the logo image
    const logos = new window.Image();
    logos.src = logo;

    logos.onload = async () => {
      const canvas = await html2canvas(reportCardRef, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png", );
      const pdf = new jsPDF("p", "mm", "a4", true);

      const margin = 10;


      const yOffset = margin  + 10;
      pdf.addImage(imgData, "PNG", margin, yOffset, 190, 0);
      pdf.setDisplayMode("fullpage", undefined, "FullScreen");

      pdf.save( selectedStudent?
        `${selectedStudent?.first_name}-${selectedStudent?.last_name}-report-card.pdf` : generateUUID()
      );

      if (downloadButton) {
        downloadButton.classList.remove("hidden");
      }

      // Restore original styles
      Object.assign(reportCardRef.style, originalStyles);
    };

    logos.onerror = () => {
      console.error("Failed to load logo image.");
    };
  } else {
    console.error("reportCardRef not found.");
  }
};

export {downloadPDF};