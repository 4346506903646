import { Divider, Grid, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import img2 from "../../assets/emailVerified/good.png";
import img1 from "../../assets/emailVerified/logo.png";

import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import {
  BackgroundFlex,
  Buttons,
  Centering,
  ColorForm,
  FailedBackgroundFlex,
  FlexOne,
  HeroEmail,
  LastHeading,
  VerificationContainer,
  ResendContainer,
} from "./Email.style";

const EmailPage = () => {
  const {
    signupData,

    isLoading,
    isSuccess,
    accountState,
    hasError,
    error,
    user,
    successMessage,
    authenticated,
  } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const [verifying, setVerifying] = useState(true);

  const continueLogin = async () => {
    if (authenticated && user) {
      const user_role = user?.user_role;
      if (user_role === "general") window.location.href = "/";
      else window.location.href = "/selectPackage";
    } else {
      window.location.href = "/login";
    }
  };

  const handleresendVerification = async () => {
    const email = signupData?.email || '';

  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const confirmation = urlParams.get('confirmation');
    if (confirmation) {

    }
  }, [dispatch]);

  useEffect(() => {
    setVerifying(isLoading);
    if (!isLoading) {
      if (hasError && error) {
        toast.error(`Failed to verify email. Please try again!`);
        console.log("here error is in verification ", error);
        const errors = error?.length && error?.split(";");
        errors &&
          errors.map((error: any) => {
            toast.error(`${error}`);
          });
      } else if (isSuccess) {
        toast.success(successMessage);
        if (
          successMessage ===
          `Email verification sent successfully. Check your email !`
        ) {
          window.location.href = "/accountPage";
        }
      }
    }
  }, [error, hasError, isLoading, isSuccess, successMessage]);

  return (
    <>
      <HeroEmail>
        <img src={img1} alt="" style={{ margin: "10px auto 30px" }} />
        <ColorForm>
          <Centering>
            {verifying ? (
              <VerificationContainer>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      Verifying your Email....
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FlexOne>
                      <Typography variant="h4">
                        Need help?
                        <Link to="" style={{ color: "#1573AE" }}>
                          Contact our support team
                        </Link>{" "}
                        or hit us up on Twitter
                        <Link to="" style={{ color: "#1573AE" }}>
                          @aproplat.
                        </Link>
                        Want to give us feedback? Let us know what you think on
                        our{" "}
                        <Link to="" style={{ color: "#1573AE" }}>
                          feedback site
                        </Link>
                      </Typography>
                    </FlexOne>
                  </Grid>
                </Grid>
              </VerificationContainer>
            ) : hasError ? (
              <VerificationContainer>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h4">Email Verification</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FailedBackgroundFlex>
                      Failed to verify email. please try again !
                    </FailedBackgroundFlex>
                    <ResendContainer>
                      {error == "Confirmation token expired" && (
                        <Buttons
                          variant="contained"
                          onClick={handleresendVerification}
                        >
                          Resend Verification
                        </Buttons>
                      )}
                    </ResendContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <FlexOne>
                      <Typography variant="h4">
                        Need help?
                        <Link to="" style={{ color: "#1573AE" }}>
                          Contact our support team
                        </Link>{" "}
                        or hit us up on Twitter
                        <Link to="" style={{ color: "#1573AE" }}>
                          @aproplat.
                        </Link>
                        Want to give us feedback? Let us know what you think on
                        our{" "}
                        <Link to="" style={{ color: "#1573AE" }}>
                          feedback site
                        </Link>
                      </Typography>
                    </FlexOne>
                  </Grid>
                </Grid>
              </VerificationContainer>
            ) : (
              accountState == "verified" && (
                <>
                  <BackgroundFlex>
                    <img src={img2} alt="" />
                    <FlexOne>
                      <Typography variant="h2">
                        {" "}
                        Email Address Verify{" "}
                      </Typography>
                      <Typography variant="h6"> Successfully</Typography>
                    </FlexOne>
                  </BackgroundFlex>
                  <Typography variant="h1">
                    Thank you for verifying your email. Return to sign in or
                    dashboard to continue.
                  </Typography>
                  <Buttons variant="contained" onClick={continueLogin}>
                    Continue
                  </Buttons>
                  <Divider />
                  <Typography variant="h4">
                    Need help?
                    <Link to="" style={{ color: "#1573AE" }}>
                      Contact our support team
                    </Link>
                    or hit us up on Twitter
                    <Link to="" style={{ color: "#1573AE" }}>
                      @aproplat.
                    </Link>
                    Want to give us feedback? Let us know what you think on our{" "}
                    <Link to="" style={{ color: "#1573AE" }}>
                      feedback site
                    </Link>
                  </Typography>
                </>
              )
            )}
          </Centering>
        </ColorForm>
        <LastHeading>
          Sent by APROPLAT check our website 444, Something Street, Suite 200,
          San Francisco, CA 94108
        </LastHeading>
      </HeroEmail>
    </>
  );
};

export default EmailPage;
