import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody, 
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import AddUserButton from "../../../../components/Dashboard/AddUserButton";
import ListSelect, { DataItem } from "../../../../components/inputs/ListSelect";
import AddEditInformationModal from "../../../../components/modals/AddEditInformationModal";
import { InputType } from "../../../../constants/enums/inputTypes";
import { getClasses, addSubject, deleteSubject } from "../../../../redux/classManager/classService";
import { getUsers, deleteUser } from "../../../../redux/userManagement/userManagementService";
import ClassList from "../../../../utils/classList";
import { roleSpecificInputs } from "../../../../utils/rolesSelect";
import { saveUser } from "../../../../utils/saveUser";
import { Student } from "../../../../utils/types/userManagement";
import { Class, Subject } from "../../../../utils/types/classManager";
import { ExamResult } from "../../../../utils/types/examManager";
import StudentReportCard from "../../../../utils/dialogue";
import { auth } from "../../../../redux/firebase";
import { RUser } from "../classManagement";
import { getResults } from "../../../../redux/examManager/exammanagerService";

export function generateUUID() {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  array[6] = (array[6] & 0x0f) | 0x40;
  array[8] = (array[8] & 0x3f) | 0x80;
  const uuid = Array.from(array, (byte) =>
    byte.toString(16).padStart(2, "0")
  ).join("-");
  return uuid;
}

export const initialValues = {
  fullName: "",

  email: "",
  role_id: "",
  password: "",
  password_confirmation: "",
  phone: "",
  avatar_url: "",
  birthday: new Date().toISOString().split("T")[0],
  sex: "",
  religion: "",
  bloodGroup: "",
  address: "",
  graduationYear: new Date().toISOString().split("T")[0],
  fatherName: "",
  motherName: "",
  classId: "",

  classes: [""],
  matricule: "",
};

type AddEditItem = {
  open: boolean;
  type: "add" | "edit";
  id: string | undefined;
};

const UserManagement = () => {
  const dispatch = useAppDispatch();
  const { user, isLoading, authenticated } = useAppSelector(
    (state) => state.auth
  );

  const { users } = useAppSelector((state) => state.users);
  const { results} = useAppSelector((state)=>state.examResults);
  const [isAuth, setIsAuth] = useState(false);
  const [shouldDisplayPage, setShouldDisplayPage] = useState(false);
  const router = useNavigate();
  useEffect(() => {
    if (isLoading) return;

    if (!isLoading && user === null) {
      router("/login");
    }

    if (authenticated && user) {
      setIsAuth(true);
    }

    if (isAuth && user?.role === "super_admin") {
      setShouldDisplayPage(true);
    } else if (user?.user_role === "teacher") {
      router("/dashboard/teacher/");
    }
  }, [authenticated, isAuth, isLoading, router, user, user?.user_role]);
  const [showVReportCard, setShowVReportCard] = useState(false);

  const [reportCardRef, setReportCardRef] = useState<HTMLDivElement | null>(
    null
  );

  
  const [classListRef, setClassListRef] = useState<HTMLDivElement | null>(null);
  const { classes, subjects } = useAppSelector((state) => state.classes);

  const [showClassList, setShowClassList] = useState(false);
  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [students, setStudents] = useState<Student[] | undefined>([]);
  const [allSubjects, setSubjects] = useState<Subject[] | undefined>([]);
  const [editingExamResults, setEditingExamResults] = useState<
    ExamResult[] | null
  >(null);

  const [addEditItem, setAddEditItem] = useState<AddEditItem>({
    open: false,
    type: "add",
    id: undefined,
  });
  const [fieldValues, setFieldValues] = useState(initialValues);

  const [SubjectSelectOpen, setSubjectsSelectOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState<string>("");
  const [selectedSequence, setSelectedSequence] = useState<string>("");

  const [selectedStudentForReport, setSelectedStudentForReport] =
    useState<Student | null>(null);

  const handleViewReport = (student: Student) => {
    setSelectedStudentForReport(student);
    setShowVReportCard(true);
  };

  const handleViewClassList = () => {
    setShowClassList(true);
  };
  // Handle term change
  const handleTermChange = (event: { target: { value: any } }) => {
    setSelectedTerm(event.target.value);
  };

  // Handle sequence change
  const handleSequenceChange = (event: { target: { value: any } }) => {
    setSelectedSequence(event.target.value);
  };

  // Filter exam results based on term and sequence
  const filteredExamResults = (student: Student) => {
    return (
      results.filter((r)=>r.classId===selectedClass?.classId && r.studentId===student.id).filter(
        (result) =>
          result.term === selectedTerm && result.sequence === selectedSequence
      ) || []
    );
  };
  useEffect(() => {
    if (auth.currentUser?.uid) {
      dispatch(getUsers());

      dispatch(getClasses());
      dispatch(getResults);
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedClass) {
      setStudents(users.filter((u: Student) => u.classId == selectedClass.classId));
      setSubjects(subjects.filter((s) => s.classid == selectedClass.classId));
    }
  }, [selectedClass]);
  const handleClassChange = (event: { target: { value: any } }) => {
    const classId = event.target.value;
    const selected = classes.find((c) => c.classId === classId);
    setSelectedClass(selected || null);
  };

  const handleAddUser = () => {
    setFieldValues(initialValues);
    setAddEditItem({ open: true, type: "add", id: undefined });
  };

  const handleEditUser = (id: string, examResults?: ExamResult[]) => {
    const currentUser = students?.find((user) => user.id === id);
    console.log("Current user is ", currentUser);
    console.log("Students are ", students);
    if (currentUser && !editingExamResults) {
      setFieldValues({
        fullName: currentUser.username,

        email: currentUser.email,
        role_id: currentUser.role,
        password: currentUser.password,
        password_confirmation: currentUser.password,
        birthday:
          currentUser.birthday || new Date().toISOString().split("T")[0],
        phone: currentUser.phone,
        avatar_url: currentUser.avatar_url || "",

        sex: currentUser.sex || "",
        religion: currentUser.religion || "",
        bloodGroup: currentUser.bloodGroup || "",
        address: currentUser.address || "",
        graduationYear:
          currentUser.graduationYear || new Date().toISOString().split("T")[0],
        fatherName: currentUser.fatherName || "",
        motherName: currentUser.motherName || "",
        classId: currentUser.classId || "",


        classes: [""],
        matricule: currentUser.matricule || "",
      });
      console.log("The passed results are ", examResults);
      // Ensure editingExamResults is set correctly
      setEditingExamResults(examResults || null);
      setAddEditItem({ open: true, type: "edit", id });
    }
  };

  const data = [
    { label: "student", value: "student" },
    { label: "administrator", value: "administrator" },
    { label: "teacher", value: "teacher" },
    { label: "super admin", value: "super_admin" },
  ];
  const handleSaveUser = (data: any) => {
    saveUser(
      data,
      addEditItem,
      dispatch,

      setAddEditItem, // Pass it here
      allSubjects!,
      editingExamResults,
      selectedClass,
      selectedSequence,
      selectedTerm,

      setEditingExamResults,
   
    );
  };

  const handleRemoveUser = (id: string) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      console.log("hi");
      dispatch(deleteUser({ id }));
    }
  };

  const handleSubjectSelectOpen = () => {
    setSubjectsSelectOpen(true);
  };

  const handleSubjectSelectClose = () => {
    setSubjectsSelectOpen(false);
  };

  const handleSubjectSelectSave = (selectedItems: DataItem[]) => {
    if (!selectedClass || !allSubjects) {
      console.error("No selected class or subjects found.");
      return;
    }

    const initialSubjectIds = allSubjects.map((s) =>
      s.subjectId.toString()
    );
    const newSubjectIds = selectedItems.map((item) => item.id);

    const subjectsToAdd = selectedItems.filter(
      (item) => !initialSubjectIds.includes(item.id)
    );
    const subjectsToRemove = allSubjects.filter(
      (subject) => !newSubjectIds.includes(subject.subjectId.toString())
    );

    subjectsToAdd.forEach((subject) => {
      const [subjectName, coefficientStr] =
        subject.name.split(",");
   
      const coefficient = coefficientStr?.trim() || "5"; // Default to "5" if not provided

      const newSubject: Subject = {
        subjectId: subject.id,
        subjectName: subjectName.trim(),
        classid: selectedClass.classId,
        coefficient: coefficient,

      };

      console.log(newSubject);
      dispatch(addSubject({ data: newSubject }));
    });

    subjectsToRemove.forEach((subject) => {
      dispatch(deleteSubject({ subjectId: subject.subjectId.toString() }));
    });

    console.log("Selected Items:", selectedItems);
    setSubjectsSelectOpen(false);
  };

  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  const handleChange = (event: { target: { value: any } }) => {
    const role = event.target.value;
    setSelectedRole(role);
  };

  useEffect(() => {
    if (selectedRole !== null && addEditItem.type === "add") {
      fieldValues.role_id = selectedRole;
    }

    // Clear editingExamResults when adding a new user or closing the modal
    if (addEditItem.type === "add" || !addEditItem.open) {
      setEditingExamResults(null);
    }
  }, [addEditItem.open, addEditItem.type, fieldValues, selectedRole]);

  // State for the StudentReportCard
  const [showReportCard, setShowReportCard] = useState(true);

  // Function to calculate average marks (moved from StudentReportCard)// Function to calculate average marks
  const calculateAverage = (
    examresults: ExamResult[],
    selectedTerm: string,
    selectedSequence: string,

  ) => {
    // Filter results based on term, sequence, and registered courses
    const filteredResults = examresults.filter((result) => {
      return (
        result.term === selectedTerm &&
        result.sequence === selectedSequence 
 
      );
    });

    if (filteredResults.length === 0) {
      return "N/A"; // Or any other suitable indicator if no relevant results found
    }

    const totalObtained = filteredResults.reduce(
      (sum, result) => sum + parseFloat(result.obtainedMarks),
      0
    );

    // Calculate average based on the number of registered courses with results
    return (totalObtained / filteredResults.length).toFixed(2);
  };

  const rankStudents = (
    students: Student[],
    selectedTerm: string,
    selectedSequence: string
  ) => {
    return [...students].sort((a, b) => {
      const avgA = parseFloat(
        calculateAverage(
          a.examresults || [],
          selectedTerm,
          selectedSequence,

        )
      );
      const avgB = parseFloat(
        calculateAverage(
          b.examresults || [],
          selectedTerm,
          selectedSequence,
 
        )
      );

      // Handle cases where average is "N/A"
      if (isNaN(avgA) && isNaN(avgB)) return 0; // Both have no results, consider them equal
      if (isNaN(avgA)) return 1; // a has no results, consider it lower
      if (isNaN(avgB)) return -1; // b has no results, consider it lower

      return avgB - avgA;
    });
  };
  // Function to rank students (moved from StudentReportCard)
  const rankedStudents = rankStudents(
    students || users.filter((u) => u.role == 'student').map((u) => u as Student).filter((u) => u.classId == selectedClass?.classId),
    selectedTerm,
    selectedSequence
  ); 
  return (
    <>

      {shouldDisplayPage && <Box sx={{ p: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Class</InputLabel>
                <Select
                  value={selectedClass?.classId || ""}
                  onChange={handleClassChange}
                  label="Select Class"
                >
                  {classes.map((c) => (
                    <MenuItem key={c.classId} value={c.classId}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Term</InputLabel>
                <Select
                  value={selectedTerm}
                  onChange={handleTermChange}
                  label="Select Term"
                >
                  <MenuItem value="1st Term">Term 1</MenuItem>
                  <MenuItem value="2nd Term">Term 2</MenuItem>
                  <MenuItem value="3rd Term">Term 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Sequence</InputLabel>
                <Select
                  value={selectedSequence}
                  onChange={handleSequenceChange}
                  label="Select Sequence"
                >
                  <MenuItem value="1">Sequence 1</MenuItem>
                  <MenuItem value="2">Sequence 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Role</InputLabel>
                <Select
                  value={selectedRole || ""}
                  onChange={handleChange}
                  label="Select role"
                >
                  {data.map((c) => (
                    <MenuItem key={c.value} value={c.value}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowReportCard(!showReportCard)}
                sx={{ mr: 1 }}
              >
                {showReportCard ? "Hide Report Card" : "Show Report Card"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleViewClassList()}
              >
                View Class List
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {selectedRole && (
                <AddUserButton text="User" onClick={handleAddUser} />
              )}
            </Grid>
            <Grid item xs={12} md={3} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubjectSelectOpen}
                startIcon={
                  <span role="img" aria-label="add">
                    ➕
                  </span>
                }
              >
                Manage Subjects
              </Button>
            </Grid>
          </Grid>
        </Box>
        {showReportCard && selectedClass && (
          <Box>
            <Typography variant="h5" component="h2" gutterBottom>
              Student Report Card for {selectedClass.name}
            </Typography>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Rank</TableCell>
                    <TableCell>Student Name</TableCell>
                    <TableCell>Sex</TableCell>

                    {allSubjects?.map((subject) => (
                      <TableCell key={subject.subjectId}>
                        {subject.subjectName}
                      </TableCell>
                    ))}
                    <TableCell>Average Marks</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rankedStudents.map((student, index) => (
                    <TableRow key={student.id} id={`student-row-${student.id}`}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{`${student.first_name} ${student.middle_name} ${student.last_name}`}</TableCell>
                      <TableCell>{student.sex}</TableCell>

                      {allSubjects?.map((subject) => {
                        const result = filteredExamResults(student).find(
                          (res) =>
                            res.subjectId === subject.subjectId.toString()
                        );
                        return (
                          <TableCell key={subject.subjectId}>
                            {result ? (
                              <>
                                <span className="math-inline">
                                  {result.obtainedMarks} (
                                </span>
                           )
                              </>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        {calculateAverage(
                          results.filter((r)=>r.studentId===student.id) || [],
                          selectedTerm,
                          selectedSequence,
                       
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() =>
                            handleEditUser(student.id, results.filter((r)=>r.studentId==student.id))
                          }
                        >
                          Edit
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleViewReport(student)}
                        >
                          View Report
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {!showReportCard && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rankedStudents?.map((student) => (
                  <TableRow key={student.id}>
                    <TableCell>{student.username}</TableCell>
                    <TableCell>{`${student.first_name} ${student.middle_name} ${student.last_name}`}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.role}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEditUser(student.id)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleRemoveUser(student.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <ClassList
          open={showClassList}
          onClose={() => setShowClassList(false)}
          selectedClass={selectedClass}
          selectedTerm={selectedTerm}
          classListRef={classListRef}
          setClassListRef={setClassListRef}
        />

        <StudentReportCard
          open={showVReportCard}
          onClose={() => setShowVReportCard(false)}
          selectedClass={selectedClass}
          selectedStudent={selectedStudentForReport}
          selectedTerm={selectedTerm}
          reportCardRef={reportCardRef}
          setReportCardRef={setReportCardRef}
        />

        <AddEditInformationModal
          open={addEditItem.open}
          title="User Information"
          onClose={() => {
            setAddEditItem({ ...addEditItem, open: false });
            setEditingExamResults(null);
          }}
          onSave={(data) => {
            handleSaveUser(data);
          }}
          onEdit={() => {
            if (addEditItem.id !== undefined) {
              handleEditUser(addEditItem.id);
            }
          }}
          data={
            editingExamResults
              ? allSubjects?.map((subject) => ({
                name: `obtainedMarks-${subject.subjectId}`, // Unique name for each subject
                type: InputType.labelInput,
                label: subject.subjectName,
                placeholder: "Obtained Marks",
                optional: false,
              })) || [] // Provide default empty array if no subjects
              : roleSpecificInputs(
                fieldValues.role_id?.toString() || "",
                classes
              )
          }
          type={addEditItem.type}
          formValues={
            editingExamResults
              ? editingExamResults
                .filter(
                  (result) =>
                    result.sequence === selectedSequence &&
                    result.term === selectedTerm
                ) // Filter exam results here
                .reduce((acc, result) => {
                  acc[`obtainedMarks-${result.subjectId}`] =
                    result.obtainedMarks;
                  return acc;
                }, {} as { [key: string]: string })
              : fieldValues
          }
          autocompleteOptions={[]}
        />

        {selectedClass && (
          <ListSelect
            data={
              allSubjects?.map((subject) => ({
                id: subject.subjectId.toString(),
                name: subject.subjectName,
              })) || []
            }
            open={SubjectSelectOpen}
            name="class-subjects"
            label="Manage Class Subjects"
            onsave={handleSubjectSelectSave}
            onClose={handleSubjectSelectClose}
            type="subjects"
            searchProperty="name"
            classData={selectedClass}
            addNewItem={(newItemName) => {
              return {
                id: (Math.random() * 1000).toString(),
                name: newItemName,
                subjectId: Math.random() * 1000,
                classid: selectedClass.classId,
                coefficient: "5",
              };
            }} Astudents={students!} Asubjects={allSubjects!}
          />
        )}
      </Box>}
    </>
  );
};

export default UserManagement;
