import { ChangeEvent, useState } from "react";

import { useField } from "formik";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";

import { ImagesContainer, Label } from "./BrowseImageInput.style";


interface ICustomCheckbox {
  name: string;
  label: string;
  multiple?: boolean;
  accept?: string; // Add this line
  callback?: (file: File) => void;
  currentImage?: string;
}

const BrowseImageInput = ({
  label,
  name,
  multiple,accept,
  callback,
  currentImage,
  ...props
}: ICustomCheckbox) => {
  const [field, meta] = useField(name);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const newImages = Array.from(files);
      if (multiple) {
        setSelectedImages([...selectedImages, ...newImages]);

        const newPreviews = newImages.map((image) =>
          URL.createObjectURL(image)
        );
        setImagePreviews([...imagePreviews, ...newPreviews]);
      } else {
        setSelectedImages([...newImages]);
        const newPreviews = newImages.map((image) =>
          URL.createObjectURL(image)
        );
        setImagePreviews([...newPreviews]);
        if (callback) {
          callback(newImages[0]);
        }
      }
    }
  };

  return (
    <Box>
      <Label>{label}</Label>
      <div>
        <input
          {...field}
          {...props}
          name={name}
          type="file"
          id="image-input"
          accept={accept}
          style={{ display: "none" }}
          onChange={handleImageChange}
          multiple={multiple}
        />
        <label htmlFor="image-input">
          <Button
            sx={{
              background: "#1573AE",
              width: "212px",
              height: "40px",
              color: "#fff",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontWeight: 500,
              borderRadius: "14px",
              ":hover": {
                opacity: "0.8",
                backgroundColor: "#1573AE",
              },
            }}
            variant="contained"
            component="span"
          >
            Browse
          </Button>
        </label>
        {selectedImages.length > 0 ? (
          <ImagesContainer>
            {selectedImages.map((image, index) => (
              <div key={index}>
                <Typography mb={2}>{image.name}</Typography>
                {imagePreviews[index] && (
                  <img width={50} height={50}
                    src={imagePreviews[index]}
                    alt={`Preview ${index}`}
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                )}
              </div>
            ))}
          </ImagesContainer>
        ) : (
          currentImage && (
            <ImagesContainer>
              <img src={currentImage} alt="browsedImg" />
            </ImagesContainer>
          )
        )}
      </div>
      {meta.touched && meta.error ? (
        <div>
          <FormControl
            required
            error={true}
            component="fieldset"
            variant="standard"
            sx={{ pl: 1 }}
          >
            <FormHelperText>{meta.error}</FormHelperText>
          </FormControl>
        </div>
      ) : null}
    </Box>
  );
};

export default BrowseImageInput;
