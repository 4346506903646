// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAKGJljR6QCHkhXEmZH4epI00tap4MxRqc",
  authDomain: "tecglobal-e4019.firebaseapp.com",
  projectId: "tecglobal-e4019",
  storageBucket: "tecglobal-e4019.appspot.com",
  messagingSenderId: "202566397955",
  appId: "1:202566397955:web:b4e3d104e3330f75099e36",
  measurementId: "G-7VFP7KWKDL"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
 const storage = getStorage(app);
export { auth, db, storage };