import { Box, Button, styled, Typography } from "@mui/material";
import hero from '../../../../assets/images/hi.jpg';

export const HeroContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(0,0,0,0.2)',
    backgroundImage: `url(${hero})`,
    minHeight: '89vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', // Adjust background size for better responsiveness
    backgroundPositionY: 'center',
    backgroundPositionX: 'right',
    width: '100%',
    minWidth: '70%',
    maxWidth: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
    
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '40vh', // Adjust height for smaller screens
        maxHeight: '50vh',
        height: '50vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const HeroContainerOverlay = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(138,43,226,0.4)',
    minHeight: '89vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',

    [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '40vh', // Adjust height for smaller screens
        maxHeight: '50vh',
        height: '50vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const HeroText = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: '400',
    color: 'white',
    marginTop: '7%',
    textAlign: 'center',
    marginBottom: '5%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));

export const HeroContainerOverlaytext = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(0,0,0,0.6)',
    minHeight: '80vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',
    width: '50%',
    marginTop: '0%',
    marginLeft: '3%',
    [theme.breakpoints.down('md')]: {
        width: '95%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '3%',
        minHeight: '10vh',
        backgroundSize: '40%',paddingBottom:'8vh',  borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
    }
}));

export const HeroTitle = styled(Typography)(({ theme }) => ({
    fontSize: '3.5rem',
    fontWeight: 'bold',
    fontFamily: 'serif',
    textAlign: 'center',
    color: 'white',
    marginBottom: '3rem',animation:'fadeIn 1s', 
    '@keyframes fadeIn':{
        '0%':{
            opacity:0
        }
        ,   '100%':{
            opacity:1
        }
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem', marginBottom:'1rem'
    }
}));

export const HeroTexttwo = styled(Typography)(({ theme }) => ({
    marginLeft: '8%',
    borderRadius: '10px',
    fontSize: '2rem',
    fontWeight: '400',
    color: 'white',
    textAlign: 'center',
    height: '7vh',
    backgroundColor: 'blueviolet',
    width: '80%',transition:'transform 0.5ms',
    
    animation:'bounce 1s', 
    '@keyframes bounce':{
        '0%':{
            opacity:0
        }
        ,   '100%':{
            opacity:1
        }
    },
    [theme.breakpoints.down('md')]: { 
        fontSize: '1rem', height:'8vh',width:'95%',marginLeft:'2%',paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));

export const HeroButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: 'black',
    fontSize: '1rem',
    height: '5%',
    width: '30%',
    minWidth: '10%',
    maxWidth: '80%',
    marginRight: '2%',
    [theme.breakpoints.down('md')]: {
        width: '40%',height:'6vh' ,// Make buttons full-width on smaller screens
        marginLeft:'10%',fontSize:'0.8rem', paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));

export const HeroButtontwo = styled(Button)(({ theme }) => ({
    color: 'white',
    fontSize: '1rem',
    height: '5%',
    border: '1px solid white',
    width: '30%',
    minWidth: '10%',
    maxWidth: '80%',
    paddingLeft: '1%',
    [theme.breakpoints.down('md')]: {
        width: '45%', // Make buttons full-width on smaller screens
        marginLeft:'1%',height:'6vh',fontSize:'0.8rem', paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));

export const HeroRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '20%',
    marginTop: '5%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'row', // Stack buttons vertically on smaller screens
        marginLeft: '-10%', 
    }
}));
  
 