
import userImage from "../../../../assets/user.png";

import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Box, Menu } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";

import {
  AccountImage,
  ButtonContainer,
  CustomMenuItem,
  DropdownButton,
  NavLink,
} from "./UserDropdown.styles";
import { UserRole } from "../../../../constants/enums/user";
import { logout } from "../../../../redux/auth/authSlice";
import { useAppDispatch, useAppSelector, RootState } from "../../../../redux/store";


const UserDropdown = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const { user } = useAppSelector((state: RootState) => state.auth);
  const userAvatar = user?.user?.avatar_url || userImage;
  const username = user?.user?.username;

  const anchorRef = useRef(null); 

  const handleLogout = () => {
    dispatch(logout());
    router("/login");
  };

  const dropdownIcon = <KeyboardArrowDownOutlined />;

  return (
    <Box>
      <ButtonContainer>
        <DropdownButton
          endIcon={dropdownIcon}
          onClick={() => setOpenDropdown(true)}
          disableRipple
          ref={anchorRef} 
        >
          <img src={userAvatar} alt={"user"} width={40} height={40} />
          {username}
        </DropdownButton>
      </ButtonContainer>
      <Menu
        id="account-menu"
        open={openDropdown}
        onClose={() => setOpenDropdown(false)}
        onClick={() => setOpenDropdown(false)}
        PaperProps={{
          elevation: 0,
          style: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            marginTop: "50px",
            marginRight: "20px",
            width: 180,
            height: "auto",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        anchorEl={anchorRef.current} 
      >
        <CustomMenuItem>
          <NavLink
            to={`/dashboard/${
              user?.role === UserRole.SUPERADMIN ||
              user?.role === UserRole.ADMIN
                ? "administrator/"
                : "teacher/"
            }`}
          >
            Dashboard
          </NavLink>
        </CustomMenuItem>
        <CustomMenuItem>Profile</CustomMenuItem>
        <CustomMenuItem onClick={handleLogout}>Logout</CustomMenuItem>
      </Menu>
    </Box>
  );
};

export default UserDropdown;