import { Autocomplete, styled } from "@mui/material";

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  minWidth: "104px",
  "& .MuiOutlinedInput-root": {
    padding: "2px 4px !important",
    color: "#595959",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 300,
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#c4c4c4",
  },
}));
