import Cards from "../../../components/tec/home/Cards/card";
import Events from "../../../components/tec/home/events/events";
import Hero from "../../../components/tec/home/Hero/hero";
import HeroTwo from "../../../components/tec/home/herotwo/herotwo";
import Start from "../../../components/tec/home/Start/start";

export default function HomePage() {
  return (
    <div>
      <Hero />
      <Cards />
      <Events />
      <HeroTwo />
      <Start />
    </div>
  );
}
