import bg from "../../../assets/About/aboutBg.jpg";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeroContainer = styled(Box)(() => ({
  backgroundImage: `url(${bg})`,
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10px",
  height: "80px",
  backgroundSize: "cover",
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontWeight: "700",
  fontSize: "34px",
  lineHeight: "77px",
  color: theme.palette.primary.dark,
  position: "relative",

  "&::before": {
    content: `""`,
    position: "absolute",
    top: "55px",
    left: "32%",
    width: "50%",
    height: "8px",
    borderRadius: "5px",
    background: theme.palette.primary.dark,
    [theme.breakpoints.down("tablet")]: {
      background: theme.palette.text.primary,
    },
    [theme.breakpoints.down("sm")]: {
      top: "70%",
      left: "23%",
      width: "53%",
      height: "7px",
      background: theme.palette.primary.dark,
    },
  },
  [theme.breakpoints.down("tablet")]: {
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
    color: theme.palette.primary.dark,
  },
}));
