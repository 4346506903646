import { Box, Card, styled, Typography } from "@mui/material";
import herotwo from '../../../../assets/images/3.jpg'
export const HeroTwoContainer = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',borderBottomRightRadius:'20px',borderBottomLeftRadius:'20px',marginBottom:'5vh',
    backgroundColor:'rgba(0,0,0,0.2)',backgroundImage:`url(${herotwo})`,
     minHeight:'86vh',backgroundRepeat:'no-repeat',backgroundSize:'100%',backgroundPositionY:'center',backgroundPositionX:'right',width:'100%',minWidth:'70%',maxWidth:'100%'
   ,
   overflow: 'hidden',  [theme.breakpoints.down('md')]: {
    overflow: 'hidden',
    width: '100%',
    minWidth: '30%',
    maxWidth: '100%',
    marginLeft: '0%',
    minHeight: '90vh', // Adjust height for smaller screens
    maxHeight: '340vh',
    height: '340vh',
    backgroundSize: 'cover',
    backgroundPositionY: 'center',
    backgroundPositionX: 'center' // Center the background image on smaller screens
}
    

}

))

export const HeroTwoContainerOverlay = styled(Box)(({theme})=>({display:'flex',flexDirection:'column',borderBottomRightRadius:'20px',borderBottomLeftRadius:'20px',
    backgroundColor:'rgba(138,43,226,0.6)', minHeight:'86vh',backgroundRepeat:'no-repeat',backgroundSize:'100%',backgroundPositionY:'bottom',backgroundPositionX:'right'

    , [theme.breakpoints.down('md')]:{overflow: 'hidden',
      width: '100%',
      minWidth: '30%',
      maxWidth: '100%',
      marginLeft: '0%',
      minHeight: '90vh', // Adjust height for smaller screens
      maxHeight: '340vh',
      height: '340vh',
      backgroundSize: 'cover',
      backgroundPositionY: 'center',
      backgroundPositionX: 'center'
        }
        
}))


export const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '4%',
  marginLeft: '15%',
  marginBottom: '5%',
  [theme.breakpoints.down('md')]: {
      marginLeft: '1%', // Adjust margin for smaller screens
  },
}));

export const CardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',   
  marginTop: '3%',
  marginLeft: '3%',
  [theme.breakpoints.down('md')]: {
      flexDirection: 'column', // Stack cards vertically on smaller screens
      width: '100%',
      marginLeft: '0%',
  },
}));

export const CardContent = styled(Card)(({ theme }) => ({
  marginLeft: '2%',
  padding: '3%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'creamWhite',
  borderRadius: '10px',
  minWidth: '10%',
  maxWidth: '30%',
  width: '25%',
  [theme.breakpoints.down('md')]: {
    minWidth: '50%',
    maxWidth: '100%',
    width: '95%',marginBottom:'6%'
  },
}));

export const CardContentText = styled(Card)(({ theme }) => ({
  marginLeft: '0%',
  padding: '3%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'creamWhite',
  borderRadius: '10px',
  minWidth: '30%',
  maxWidth: '100%',
  width: '95%',
  [theme.breakpoints.down('md')]: {
      width: '100%', // Make cards full-width on smaller screens
      margin: '10px 0',
  },
}));

export const CardImage = styled(Box)(({ theme }) => ({
  backgroundSize: 'cover', // Ensure image scales proportionally
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'blueviolet',
  color: 'white',
  borderRadius: '10px',
  height: '23vh',
  width: '100%',
  marginLeft: '0%',
  marginTop: '2%',
  zIndex: '1','&:hover':{transform:'scale(1.1)',},
  [theme.breakpoints.down('md')]: {
      height: '20vh', // Adjust height for smaller screens
  },
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  backgroundColor: 'blueviolet',
  [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem', // Adjust font size for smaller screens
  },
}));

export const CardHeaderTitle = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '2.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'serif',
  marginTop: '4%',animation:'fadeIn 2s', 
  '@keyframes fadeIn':{
      '0%':{
          opacity:0
      }
      ,   '100%':{
          opacity:1
      }
  },
  [theme.breakpoints.down('md')]: {
      fontSize: '2rem', // Adjust font size for smaller screens
  },
}));