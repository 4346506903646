import { Box, IconButton, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Box)(({ theme }) => ({
  // padding: "50px 30px",
}));

export const ViewButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: 5,
  transform: "scale(0.7)",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
}));

export const EditButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: 5,
  transform: "scale(0.7)",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
}));

export const AddButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  borderRadius: 5,
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.04)",
  fontFamily: "Inter",
  fontSize: "15px",
  padding: "0px 10px",
  textTransform: "unset",
  minWidth: "203px",
  minHeight: "59px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
  },
  [theme.breakpoints.down("lg")]: {
    minHeight: "40px",
    minWidth: "auto",
  },
}));
