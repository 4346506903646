import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import { CardWrapperQuestion, QuestionHeading, Questions } from "./Question.styles";
import badge from "../../../assets/Faq/badge.png"; // Consider replacing this with a more academic icon
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const QuestionDetail1 = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <CardWrapperQuestion>
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xs={12}>
          <QuestionHeading>
            <img src={badge} alt="badge" />
            <Typography variant="h2">Academics & Programs FAQs</Typography>
          </QuestionHeading>
        </Grid>

        {/* First Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(0)} align="">
            <Typography variant="h2">What core academic subjects are offered?</Typography>
            {openIndex === 0 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 0 && (
            <Questions align="column">
              <Typography variant="body1">
                Our curriculum includes a strong foundation in core subjects such as English Language Arts, Mathematics, Science, Social Studies, and Foreign Languages. We also offer specialized courses in STEM fields, the arts, and humanities.
              </Typography>
            </Questions>
          )}
        </Grid>

        {/* Second Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(1)} align="">
            <Typography variant="h2">What extracurricular activities are available?</Typography>
            {openIndex === 1 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 1 && (
            <Questions align="column">
              <Typography variant="body1">
                Students can participate in a wide range of extracurricular activities, including sports teams, music ensembles, drama club, debate team, robotics club, and various student-led organizations.  We encourage students to explore their interests and develop their talents outside the classroom.
              </Typography>
            </Questions>
          )}
        </Grid>

        {/* Third Question with Answer */}
        <Grid item xs={12}>
          <Questions onClick={() => handleClick(2)} align="">
            <Typography variant="h2">Are there any special programs or learning support services offered?</Typography>
            {openIndex === 2 ? <RemoveIcon /> : <AddIcon />}
          </Questions>
          {openIndex === 2 && (
            <Questions align="column">
              <Typography variant="body1">
                We offer specialized programs for gifted and talented students, as well as learning support services for students with diverse learning needs. Our dedicated staff provides individualized attention and resources to ensure that every student reaches their full potential. We also offer ESL programs for students whose first language is not English.
              </Typography>
            </Questions>
          )}
        </Grid>
      </Grid>
    </CardWrapperQuestion>
  );
};

export default QuestionDetail1;