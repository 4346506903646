import React from 'react'
import LogPage from '../components/login/LoginPage';
import img1 from "../assets/blog/blog2.png";
export const login = () => {
  return (
    <>
      <LogPage/>
    </>
  )
}


