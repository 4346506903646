import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  RUser,
  AddUser,
  Student,
  Administrator,
  Teacher,
} from "../../utils/types/userManagement";
import { collection, deleteDoc, doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";

const userdata = (addinfo: AddUser) => {
  // Use optional chaining and nullish coalescing to directly return the first non-null value
  return (
    addinfo.administrator ??
    addinfo.student ??
    addinfo.teacher ??
    addinfo.superadmin
  );
};
export const getUsers = createAsyncThunk<
  (RUser | Student | Administrator | Teacher)[] | null,
 void,
  { rejectValue: string[] }
>(
  "users",
  async (_,{ rejectWithValue }) => {
    try {
      const usersCollection = collection(db, 'users');

      return new Promise<(RUser | Student | Administrator | Teacher)[] | null>((resolve, reject) => {
        onSnapshot(usersCollection, (snapshot) => {
          const users = snapshot.docs.map(doc => doc.data() as RUser);

          const typedUsers = users.map((user) => {
            switch (user.role) {
              case "student":
                return user as Student;
              case "administrator":
                return user as Administrator;
              case "teacher":
                return user as Teacher;
              default:
                return user; // Fallback to the base RUser type
            }
          });

          resolve(typedUsers);
        }, (error) => {
          rejectWithValue([error.message]);
          reject(null);
        });
      });
    } catch (error: any) {
      return rejectWithValue([error.message]);
    }
  }
);
export const addUser = createAsyncThunk<
  RUser,
  { data: AddUser; },
  { rejectValue: string[] }
>("addUser", async ({ data }, { rejectWithValue }) => {
  try {
    const info = userdata(data);
    console.log(data);

    if (!info?.id) {
      throw new Error("User ID is undefined");
    }
    
    const credential = await createUserWithEmailAndPassword(auth, info.email, info.password);

    const docRef = doc(db, 'users', credential.user.uid);
    info.id=credential.user.uid;
    await setDoc(docRef, info);

    return info; // Assuming you want to return the user info
  } catch (error) {
    console.log("Failed to create user", error);
    return rejectWithValue(["Failed to create user"]);
  }
});
export const modifyUser = createAsyncThunk<
  RUser,
  { data: Partial<AddUser>; id: string },
  { rejectValue: string[] }
>("modifyUser", async ({ data,  id }, { rejectWithValue }) => {
  try {
    const info = userdata(data);
    
    if (!info?.id) {
      throw new Error("User ID is undefined");
    }
    const docRef = doc(db, 'users', info?.id);
    await updateDoc(docRef, info);
    const docSnap = await getDoc(doc(db, 'users', info.id));
    if (docSnap.exists()) {
      return docSnap.data() as RUser;
    } else {
      return rejectWithValue(["Document does not exist"]);
    }

  
  } catch (error) {
    console.log("Failed to modify user", error);
    return rejectWithValue(["Failed to modify user"]);
  }
});




export const deleteUser = createAsyncThunk<
  { message: string; id: string },
  { id: string; },
  { rejectValue: string[] }
>("deleteUser", async ({ id }, { rejectWithValue }) => {
  try {
    const docRef = doc(db, 'users', id);
    await deleteDoc(docRef);

    return { message: "User successfully deleted", id };
  } catch (error) {
    console.log("Failed to delete user", error);
    return rejectWithValue(["Failed to delete user"]);
  }
});
