import { createAsyncThunk } from "@reduxjs/toolkit";

import { SignupData, LoginData } from "../../utils/types";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { RUser } from "../../utils/types/userManagement";

export const registerUser = createAsyncThunk<
  RUser | null,
  SignupData,
  { rejectValue: string[] }
>("auth/signup", async (signupData, { rejectWithValue }) => {
  try {
    const credential = await createUserWithEmailAndPassword(auth, signupData.email, signupData.password);
    await setDoc(doc(db, 'users', credential.user.uid), signupData);
    const docSnap = await getDoc(doc(db, 'users', credential.user.uid));

    if (docSnap.exists()) {
      return docSnap.data() as RUser;
    } else {
      return rejectWithValue(["Document does not exist"]);
    }
  } catch (error) {
    console.error("Error during signup:", error);
    return rejectWithValue(["An error occurred during signup"]);
  }
});

export const login = createAsyncThunk<
  RUser | null,
  LoginData,
  { rejectValue: string[] }
>("auth/signin", async (signinData, { rejectWithValue }) => {
  console.log(signinData);
  try {
    const credential = await signInWithEmailAndPassword(auth, signinData.user_info, signinData.password);
    console.log(credential.user.email);
    const docSnap = await getDoc(doc(db, 'users', credential.user.uid));
    if (docSnap.exists()) {
      return docSnap.data() as RUser;
    } else {
      return rejectWithValue(["Document does not exist"]);
    }
  } catch (error) {
    console.log(error);
    console.error("Error during login:", error);
    return rejectWithValue(["An error occurred during login"]);
  }
});





// Reset Password
export const resetPassword = createAsyncThunk<
  void,
  string,
  { rejectValue: string[] }
>("auth/resetPassword", async (email, { rejectWithValue }) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error("Error during password reset:", error);
    return rejectWithValue(["An error occurred during password reset"]);
  }
});






