import React from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Team from "../components/Aboutus/Founder/Team";
import Hero from "../components/Aboutus/HeroSection/Hero";
import Started from "../components/Aboutus/Journey/Started";
import Unique from "../components/Aboutus/Unique/Unique";
import ValuesBelief from "../components/Aboutus/Values/ValuesBelief";
import ChooseUs from "../components/Aboutus/WhyChoose/ChooseUs";
import AboutRev from "../components/tec/about/aboutRev/aboutRev";
export const Background = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: {
    background: theme.palette.background.paper,
  },
  [theme.breakpoints.down("sm")]: {
    background: theme.palette.background.paper,
  },
}));

const About = () => {
  return (
    <><AboutRev /></>
  );
};

export default About;
