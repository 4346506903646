
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify"; 

import { 
    getResults, 
    addExamResults, 
    updateExamResult,

    deleteResults
} from "./exammanagerService"; // Assuming this is where your service functions are
import { ExamResultState } from "../../utils/types/examManager";

const initialState: ExamResultState = {
    results: [], 
    isLoading: false,
    isSuccess: false,
    hasError: false,
    hasWarning: false,
    error: null,
    successMessage: null,
}

export const examSlice = createSlice({
    name: "exam", 
    initialState, 
    reducers: {
        refreshState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.hasError = false;
            state.hasWarning = false;
            state.error = null;
            state.successMessage = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Get Results
            .addCase(getResults.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getResults.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.results = action.payload || []; 
            })
            .addCase(getResults.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true;
                state.error = action.payload?.join(", ") || "Failed to fetch results"; 
            })

            // Add Exam Results
            .addCase(addExamResults.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addExamResults.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.results.push(action.payload); 
                state.successMessage = "Exam result added successfully!";
                toast.success("Exam result added successfully!"); 
            })
            .addCase(addExamResults.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true;
                state.error = action.payload?.join(", ") || "Failed to add exam result"; 
            })

            // Update Exam Result
            .addCase(updateExamResult.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateExamResult.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                const updatedResult = action.payload;
                const index = state.results.findIndex(result => result.id === updatedResult.id);
                if (index !== -1) {
                    state.results[index] = updatedResult;
                }
                state.successMessage = "Exam result updated successfully!";
                toast.success("Exam result updated successfully!"); 
            })
            .addCase(updateExamResult.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true;
                state.error = action.payload?.join(", ") || "Failed to update exam result"; 
            })

            // Delete Exam Result (Assuming you meant to delete an exam result, not a newsletter)
            .addCase(deleteResults.pending, (state) => { // Change to the correct action 
                state.isLoading = true;
            })
            .addCase(deleteResults.fulfilled, (state, action) => { // Change to the correct action
                state.isLoading = false;
                state.isSuccess = true;
                state.results = state.results.filter(result => result.id !== action.payload.id); 
                state.successMessage = "Exam result deleted successfully!";
                toast.success("Exam result deleted successfully!"); 
            })
            .addCase(deleteResults.rejected, (state, action) => { // Change to the correct action
                state.isLoading = false;
                state.hasError = true;
                state.error = action.payload?.join(", ") || "Failed to delete exam result"; 
            });
    },
})

export default examSlice.reducer;
