import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


import {
  deletePostCategory,
  getCategories,
  updatePostCategory,
  addPostCategory,
} from "./categoriesService";
import { BlogCategoriesState } from "../../../utils/types/blogManagement";

const initialState: BlogCategoriesState = {
  postCategories: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const blogCategoriesSlice = createSlice({
  name: "blogCategories",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })

      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = null;
        state.postCategories = action.payload || [];
      })
      .addCase(getCategories.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to fetch posts!";
      })
      .addCase(addPostCategory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(addPostCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Category created successfully!";
        toast.success("Category created successfully!");
        const newCategory = action.payload;

        state.postCategories = [
          ...state.postCategories,
          newCategory.post_category,
        ];
      })
      .addCase(addPostCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to create category!";
      })
      .addCase(updatePostCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Category updated successfully!";
        toast.success("Category updated successfully!");

        const updatedCategory = action.payload;
        const index = state.postCategories.findIndex(
          (category) => category.id === updatedCategory.id
        );
        if (index !== -1) {
          state.postCategories[index] = updatedCategory;
        }
      })
      .addCase(updatePostCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to update category";
      })
      .addCase(deletePostCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Category deleted successfully!";

        const deletedCategoryId = action.payload?.id;

        if (deletedCategoryId) {
          state.postCategories = state.postCategories.filter(
            (category) => category.id !== deletedCategoryId
          );
        }
      })
      .addCase(deletePostCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to delete category";
      });
  },
});

export default blogCategoriesSlice.reducer;
