import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/tec.png";
import mobilelogo from "../../../assets/images/tec.png";
import login from "../../../assets/login/logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  AppbarBrand,
  AppbarButton,
  AppbarCTA,
  AppbarContainer,
  AppbarItems,
  AppbarLinks,
  Hamburger,
} from "./Navbar.styles";


import PersonIcon from "@mui/icons-material/Person";

import { CustomButton } from "../../NavButton/NavButton";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import { Link } from "react-router-dom";
import { RootState } from "../../../redux/store";
import UserDropdown from "./userDropDown";
import useTheme from "@mui/material/styles/useTheme";
import { FaHandshake, FaHome, FaInfoCircle, FaPhone, FaPhoneSquare, FaVideo } from "react-icons/fa";

type NavbarProps = {
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  light: boolean;
  setLight: React.Dispatch<React.SetStateAction<boolean>>;
  toggleTheme: boolean;
  setToggleTheme: React.Dispatch<React.SetStateAction<boolean>>;
};
const Navbar: React.FC<NavbarProps> = ({
  toggle,
  setToggle,
  toggleTheme,
  setToggleTheme,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("tablet"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [light, setLight] = useState(true);

  const { authenticated, user } = useSelector((state: RootState) => state.auth);
const [authen, setauthen] = useState(false);
  const handelThemeMode = () => {
    setLight(!light);
    setToggleTheme(!toggleTheme);
  };

  useEffect(()=>{
    if( authenticated && user ){
      setauthen(true)
    }
   
  }, [authenticated, user])
  return (
    <AppbarContainer>
      <AppbarBrand>
        {mobile ? (
          <Link to={'/'}> <img src={mobilelogo} alt="Tec Global" style={{height:'10vh',width:'70%',}} /></Link>
        ) : (
          <Link to={'/'}> <img src={logo} alt="Tec Global"  /></Link>
        )}
      </AppbarBrand>
      <AppbarCTA>
        {!matches && (
          <AppbarLinks>
            <AppbarItems variant="body1">
              <Link to="/"><FaHome />Home</Link>
            </AppbarItems>
            <AppbarItems variant="body1">
              <Link to="/about"><FaInfoCircle />About</Link>
            </AppbarItems>
           
            <AppbarItems variant="body1">
              <Link to="/Contact"> <FaPhoneSquare  />Contact</Link>
            </AppbarItems> 
             <AppbarItems variant="body1">
              <Link to="/live"><FaVideo />Live TV</Link>
            </AppbarItems>
             <AppbarItems variant="body1">
              <Link to="/give"><FaHandshake />Give</Link>
            </AppbarItems>
       
          </AppbarLinks>
        )}
        <AppbarButton>
          {!authen && (
            <>
         

              <Link to="/login">
                <CustomButton
                  sx={{
                    background: "#000000",
                    border: "none",
                    "&:hover": { background: "#FF0000" },
                    width: "89px",
                  }}
                  // disableRipple={true}
                  // disableFocusRipple={true}
                >
                  <img
                    src={login}
                    alt="login"
                    style={{ width: "18px", height: "13px" }}
                  />
                  Login
                </CustomButton>
              </Link>
            </>
          )}
          {!mobile &&
            (light ? (
              <CustomButton
                sx={{
                  background: "#082637",
                  border: "none",
                  "&:hover": { background: "#082637" },
                }}
                onClick={() => handelThemeMode()}
              >
                {/* <Image src={login} alt="login" /> */}
                Light Mode
                <LightModeIcon sx={{ fontSize: "18px" }} />
                {/* dark theme Colors are not define thats why the dark icon is commented */}
                {/* <DarkModeIcon sx={{ fontSize: "18px" }} /> */}
                {/* <Image src={down} alt="arrow" /> */}
              </CustomButton>
            ) : (
              <CustomButton
                sx={{
                  background: "#082637",
                  border: "none",
                  "&:hover": { background: "#082637" },
                }}
                onClick={() => handelThemeMode()}
              >
                {/* <Image src={login} alt="login" /> */}
                Dark Mode
                <DarkModeIcon sx={{ fontSize: "15px", color: "white" }} />
                {/* dark theme Colors are not define thats why the dark icon is commented */}
                {/* <DarkModeIcon sx={{ fontSize: "18px" }} /> */}
                {/* <Image src={down} alt="arrow" /> */}
              </CustomButton>
            ))}
          {authen && <UserDropdown />}
        </AppbarButton>
        {matches && (
          <>
            {mobile ? (
              <Hamburger border="" onClick={() => setToggle(!toggle)}>
                <MenuIcon style={{ color: "black" }} />
              </Hamburger>
            ) : (
              <Hamburger onClick={() => setToggle(!toggle)}>
                <MenuIcon />
              </Hamburger>
            )}
          </>
        )}
      </AppbarCTA>
    </AppbarContainer>
  );
};

export default Navbar;
