import React, { useState } from "react";
import { CardText, CardWrapper, Container } from "./Question.styles";
import Grid from "@mui/material/Grid";
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ElderlyWomanIcon from '@mui/icons-material/ElderlyWoman';

import Typography from "@mui/material/Typography";
import QuestionDetail from "./QuestionDetail";
import QuestionDetail1 from "./QuestionDetail1";
import QuestionDetail2 from "./QuestionDetail2";

const FAQSection = () => {
  const [toggle, setToggle] = useState(1);

  const handleClick = (index: React.SetStateAction<number>) => {
    setToggle(toggle === index ? 0 : index); // Set to 0 to close if already open
  };

  return (
    <Container>
    <Grid container spacing={5}>
      <Grid item xs={12} md={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CardWrapper onClick={() => handleClick(1)} outline={toggle === 1 ? "blue" : "white"}>
              <FamilyRestroomIcon sx={{ fontSize: 40, color: toggle === 1 ? "blue" : "inherit" }} />
              <CardText>
                <Typography variant="h5">About Our School</Typography>
                <Typography variant="body2">
                  General information about our school's mission, values, and history.
                </Typography>
              </CardText>
            </CardWrapper>
          </Grid>
          <Grid item>
            <CardWrapper onClick={() => handleClick(2)} outline={toggle === 2 ? "blue" : "white"}>
              <LocalHospitalIcon sx={{ fontSize: 40, color: toggle === 2 ? "blue" : "inherit" }} />
              <CardText>
                <Typography variant="h5">Academics & Programs</Typography>
                <Typography variant="body2">
                  Learn about our curriculum, extracurricular activities, and special programs.
                </Typography>
              </CardText>
            </CardWrapper>
          </Grid>
          <Grid item>
            <CardWrapper onClick={() => handleClick(3)} outline={toggle === 3 ? "blue" : "white"}>
              <ElderlyWomanIcon sx={{ fontSize: 40, color: toggle === 3 ? "blue" : "inherit" }} />
              <CardText>
                <Typography variant="h5">Admissions & Enrollment</Typography>
                <Typography variant="body2">
                  Information and FAQs about the admissions process and how to enroll your child.
                </Typography>
              </CardText>
            </CardWrapper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        {toggle === 1 && <QuestionDetail />}
        {toggle === 2 && <QuestionDetail1 />}
        {toggle === 3 && <QuestionDetail2 />}
      </Grid>
    </Grid>
  </Container>
  );
};

export default FAQSection;

