import { ProphetContainer, ProphetCard, ProphetCardImage, ProphetCol, ProphetTitle, ProphetText } from "../prophet/prophet.styles";
import { RevCardImage, RevolutionCard } from "./revolution.styles";

export default function Revolution(){
    return(<ProphetContainer><RevolutionCard><RevCardImage></RevCardImage>
        <ProphetCol>
            <ProphetTitle>The Empowerment Revolution</ProphetTitle>
            <ProphetText>Prophet Tembeck Tiku Aldrian is a man called by God to be his representative on earth. He is an agent of God gifted by God to declare His counsel, to be an envoy,a watchman and a bearer of royal authority. Through the grace of God bestowed on him, many are delivered from the shackles of captivity through deliverance ,healing and by prophecy</ProphetText>
        </ProphetCol>
        
        </RevolutionCard></ProphetContainer>)
}