import { styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const StyledDatePicker = styled(DatePicker)(() => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    width: "100%",
    borderColor: "#E8E9EB",
    borderRadius: "14px",
    height: "40px",
    paddingLeft: "16px",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#3F434A",
  },
}));
