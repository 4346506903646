import {
  DashboardOutlined,
  MenuRounded,
} from "@mui/icons-material";
import { Grid, List, ListItemText } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import NavLink from "../NavLink";
import {
  ComponentContainer,
  CollapseContainer,
  CustomListItemButton,
  CustomListItemIcon,
  CustomListItemText,
  Heading,
  InnerContainer,
  LeftDrawerContaner,
  MainContainer,
  MenuIconButton,
  MenueButtonContainer,
} from "./LeftSection.styles";

import Drawer from "../Drawer";

type Props = {
  shrink: boolean;
  handleShrink: () => void;
};

const LeftSection = ({ shrink, handleShrink }: Props) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const router = useLocation();
  const path = router.pathname;
  useEffect(() => {
    if (path.includes("thesis")) setOpenDropdown(true);
  }, [path]);
  return (
    <ComponentContainer container>
      <Grid item xs={12}>
        <CollapseContainer
          in={!shrink}
          orientation="horizontal"
          collapsedSize={78}
        >
          <MainContainer>
            <Grid container>
              <Grid item xs={12}>
                <InnerContainer>
                  <Grid container>
                    {!shrink ? (
                      <>
                        <Grid item xs={10}>
                          <Heading>Teacher Dashboard</Heading>
                        </Grid>
                        <Grid item xs={2}>
                          <MenuIconButton
                            // onClick={() => setShrinkLeft(!shrinkLeft)}
                            onClick={handleShrink}
                          >
                            <MenuRounded />
                          </MenuIconButton>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <MenueButtonContainer>
                            <MenuIconButton
                              // onClick={() => setShrinkLeft(!shrinkLeft)}
                              onClick={handleShrink}
                            >
                              <MenuRounded />
                            </MenuIconButton>
                          </MenueButtonContainer>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </InnerContainer>
              </Grid>
              <Grid item xs={12}>
                <List>
                  <NavLink path="/dashboard/teacher/"> 
                    <CustomListItemButton title="Dashboard">
                      <CustomListItemIcon>
                        <DashboardOutlined />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomListItemText>Dashboard</CustomListItemText>
                        }
                      />
                    </CustomListItemButton>
                  </NavLink>
                 
        
                </List>
              </Grid>
            </Grid>
          </MainContainer>
        </CollapseContainer>

        <LeftDrawerContaner>
          <Drawer>
            <Grid container>
              <Grid item xs={12}>
                <List>
                  <NavLink path="/dashboard/teacher/settings">
                    <CustomListItemButton title="Dashboard">
                      <CustomListItemIcon>
                        <DashboardOutlined />
                      </CustomListItemIcon>
                      <ListItemText
                        primary={
                          <CustomListItemText>Dashboard</CustomListItemText>
                        }
                      />
                    </CustomListItemButton>
                  </NavLink>
 
        
                </List>
              </Grid>
            </Grid>
          </Drawer>
        </LeftDrawerContaner>
      </Grid>
    </ComponentContainer>
  );
};

export default LeftSection;
