import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { baseUrl as baseUrl } from "../../../utils/baseUrl";
import { AddPost, Post } from "../../../utils/types/blogManagement";

export const getPosts = createAsyncThunk<
  { message: string; data: Post[] | null },
  string,
  { rejectValue: string[] }
>("blogPosts", async (token, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${baseUrl()}/v1/aproplat/posts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch posts", error);
    return rejectWithValue(["Failed to fetch data"]);
  }
});

export const addPost = createAsyncThunk<
  { data: Post },
  { token: string; data: AddPost },
  { rejectValue: string[] }
>("addPost", async ({ token, data }, { rejectWithValue }) => {
  const { title, content, post_category_ids, tag_ids, featured_image } = data;
  const formData = new FormData();
  formData.append("title", title);
  formData.append("content", content);

  if (post_category_ids && post_category_ids.length > 0) {
    formData.append("post_category_ids", post_category_ids.toString());
  }

  if (tag_ids && tag_ids.length > 0) {
    formData.append("tag_ids", tag_ids.toString());
  }

  if (featured_image) {
    formData.append("featured_image", featured_image);
  }

  try {
    const response = await axios.post(
      `${baseUrl()}/v1/aproplat/posts`,
      { post: data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to create post", error);
    return rejectWithValue(["Failed to create post"]);
  }
});

export const updatePost = createAsyncThunk<
  { data: Post },
  { data: AddPost; token: string; id: number },
  { rejectValue: string[] }
>("updatePost", async ({ data, token, id }, { rejectWithValue }) => {
  const { title, content, post_category_ids, tag_ids, featured_image } = data;
  const formData = new FormData();
  formData.append("title", title);
  formData.append("content", content);

  if (post_category_ids && post_category_ids.length > 0) {
    formData.append("post_category_ids", post_category_ids.toString());
  }

  if (tag_ids && tag_ids.length > 0) {
    formData.append("tag_ids", tag_ids.toString());
  }

  if (featured_image) {
    formData.append("featured_image", featured_image);
  }

  try {
    const response = await axios.patch(
      `${baseUrl()}/v1/aproplat/posts/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to update post", error);
    return rejectWithValue(["Failed to update post"]);
  }
});

export const deletePost = createAsyncThunk<
  { message: string; id: number },
  { id: number; token: string },
  { rejectValue: string[] }
>("deletePost", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      `${baseUrl()}/v1/aproplat/posts/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { message: response.data, id };
  } catch (error) {
    console.log("Failed to delete post", error);
    return rejectWithValue(["Failed to delete post"]);
  }
});
