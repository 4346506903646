import { createSlice } from "@reduxjs/toolkit";

import {
  addPostTag,
  deletePostTag,
  getTags,
  updatePostTag,
} from "./tagsService";
import { toast } from "react-toastify";
import { BlogTagesState } from "../../../utils/types/blogManagement";

const initialState: BlogTagesState = {
  postTags: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const blogTagsSlice = createSlice({
  name: "blogTags",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTags.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })

      .addCase(getTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = null;
        state.postTags = action.payload || [];
      })
      .addCase(getTags.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to fetch tags!";
      })
      .addCase(addPostTag.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(addPostTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Tag created successfully!";
        toast.success("Tag created successfully!");
        const newCategory = action.payload;
        state.postTags = [...state.postTags, newCategory.tag];
      })
      .addCase(addPostTag.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to create tag!";
      })
      .addCase(updatePostTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Tag updated successfully!";
        toast.success("Tag updated successfully!");
        const updatedTag = action.payload.tag;
        const index = state.postTags.findIndex(
          (tag) => tag.id === updatedTag.id
        );
        if (index !== -1) {
          state.postTags[index] = updatedTag;
        }
      })
      .addCase(updatePostTag.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to update category";
      })
      .addCase(deletePostTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Tag deleted successfully!";

        const deletedTagId = action.payload?.id;

        if (deletedTagId) {
          state.postTags = state.postTags.filter(
            (tag) => tag.id !== deletedTagId
          );
        }
      })
      .addCase(deletePostTag.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to delete tag";
      });
  },
});

export default blogTagsSlice.reducer;
