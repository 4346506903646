import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { downloadPDF } from "./generateDocument";
import Letterhead from "./letterHead";
import { Class } from "./types/classManager";
import { ExamResult } from "./types/examManager";
import { Student } from "./types/userManagement";
import { useAppSelector } from "../redux/store";

interface StudentReportCardProps {
  open: boolean;
  onClose: () => void;
  selectedClass: Class | null;
  selectedStudent: Student | null;
  selectedTerm: string;
  reportCardRef: HTMLDivElement | null;
  setReportCardRef: (ref: HTMLDivElement | null) => void;
}

const StudentReportCard: React.FC<StudentReportCardProps> = ({
  open,
  onClose,
  selectedClass,
  selectedStudent,
  selectedTerm,
  reportCardRef,
  setReportCardRef,
}) => {
  const handleDownloadPDF = async () => {
    await downloadPDF(reportCardRef, selectedStudent);
  };

  const { results } = useAppSelector((state) => state.examResults);
  const { subjects } = useAppSelector((state) => state.classes);

  const calculateAverage = (examresults: ExamResult[]) => {
    const filteredResults = examresults.filter((result) => {
      return (
        result.term === selectedTerm &&
        (result.sequence === "1" || result.sequence === "2")
      );
    });

    if (filteredResults.length === 0) {
      return "N/A";
    }

    const totalObtained = filteredResults.reduce(
      (sum, result) => sum + parseFloat(result.obtainedMarks),
      0
    );

    return (totalObtained / filteredResults.length).toFixed(2);
  };

  const filteredReportExamResults = (student: Student) => {
    return (
      results?.filter(
        (result: { studentId: string; term: string; sequence: string }) =>
          result.studentId === student.id &&
          result.term === selectedTerm &&
          (result.sequence === "1" || result.sequence === "2")
      ) || []
    );
  };

  const calculateOverallResult = (
    seq1Result: ExamResult | undefined,
    seq2Result: ExamResult | undefined
  ) => {
    if (!seq1Result || !seq2Result) {
      return { grade: "-", remarks: "-" };
    }

    const avgMarks =
      (parseFloat(seq1Result.obtainedMarks) +
        parseFloat(seq2Result.obtainedMarks)) /
      2;

    let grade = "-";
    if (avgMarks >= 80) {
      grade = "A";
    } else if (avgMarks >= 70) {
      grade = "B";
    } else if (avgMarks >= 60) {
      grade = "C";
    } else if (avgMarks >= 50) {
      grade = "D";
    } else {
      grade = "F";
    }

    const remarks = avgMarks >= 50 ? "Pass" : "Fail";

    return { grade, remarks };
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen fullWidth>
      <DialogTitle>Student Report Card</DialogTitle>
      <DialogContent>
        {selectedStudent && (
          <div ref={setReportCardRef}>
            <Letterhead classData={selectedClass} selectedTerm={selectedTerm} />
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              align="center"
              sx={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginBottom: 3,
                marginTop: 3,
              }}
            >
              Report Card for {selectedStudent.first_name}{" "}
              {selectedStudent.last_name}
            </Typography>
            <Typography
              variant="h5"
              component="h4"
              gutterBottom
              align="center"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                marginBottom: 3,
                marginTop: 3,
              }}
            >
              {selectedTerm} - Sequences 1 & 2
            </Typography>

            <TableContainer component={Paper}>
              <Table sx={{ borderCollapse: "collapse" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ border: "1px solid black", padding: "8px" }}
                    >
                      Subject
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid black", padding: "8px" }}
                    >
                      Sequence 1 Marks
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid black", padding: "8px" }}
                    >
                      Sequence 2 Marks
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid black", padding: "8px" }}
                    >
                      Grade
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid black", padding: "8px" }}
                    >
                      Coef
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid black", padding: "8px" }}
                    >
                      Remark
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subjects
                    .filter((s) => s.classid === selectedClass?.classId)
                    .map((subject) => {
                      const results = filteredReportExamResults(
                        selectedStudent
                      );
                      const seq1Result = results.find(
                        (res) =>
                          res.subjectId === subject.subjectId.toString() &&
                          res.sequence === "1"
                      );
                      const seq2Result = results.find(
                        (res) =>
                          res.subjectId === subject.subjectId.toString() &&
                          res.sequence === "2"
                      );

                      const { grade, remarks } = calculateOverallResult(
                        seq1Result,
                        seq2Result
                      );

                      return (
                        <TableRow key={subject.subjectId}>
                          <TableCell
                            sx={{ border: "1px solid black", padding: "8px" }}
                          >
                            {subject.subjectName}
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: "8px" }}
                          >
                            {seq1Result ? seq1Result.obtainedMarks : "-"}
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: "8px" }}
                          >
                            {seq2Result ? seq2Result.obtainedMarks : "-"}
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: "8px" }}
                          >
                            {grade}
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: "8px" }}
                          >
                            {subject.coefficient}
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: "8px" }}
                          >
                            {remarks}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{ border: "1px solid black", padding: "8px" }}
                    >
                      Average Marks
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid black", padding: "8px" }}
                    >
                      {calculateAverage(
                        results.filter((r)=>r.studentId===selectedStudent.id) || []
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadPDF}
                className="download-pdf-button"
              >
                Download PDF
              </Button>
            </Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentReportCard;