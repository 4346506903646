
import React from "react";
import data from "../../components/Blog/BlogList/BlogData";
import Hero from "../../components/Aboutus/HeroSection/Hero";
import BlogList from "../../components/Blog/BlogList/BlogList";
import { Background } from "../about";


export const getStaticProps = async () => {
  return {
    props: {data} 
  }
}

const Blog = () => {
  return (
    <Background>
      <Hero />
      <BlogList />
    </Background>
  );
};


export default Blog;