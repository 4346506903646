import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { baseUrl as baseUrl } from "../../../utils/baseUrl";
import { AddPostTag, PostTag } from "../../../utils/types/blogManagement";

export const getTags = createAsyncThunk<
  PostTag[] | null,
  string,
  { rejectValue: string[] }
>("blogTags", async (token, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${baseUrl()}/v1/aproplat/tags`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch tags", error);
    return rejectWithValue(["Failed to fetch data"]);
  }
});

export const addPostTag = createAsyncThunk<
  { message: string; tag: PostTag },
  { data: AddPostTag; token: string },
  { rejectValue: string[] }
>("addTag", async ({ data, token }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${baseUrl()}/v1/aproplat/tags`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Failed to create tag", error);
    return rejectWithValue(["Failed to create tag"]);
  }
});

export const updatePostTag = createAsyncThunk<
  { tag: PostTag },
  { data: AddPostTag; token: string; id: number },
  { rejectValue: string[] }
>("updateTag", async ({ data, token, id }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(
      `${baseUrl()}/v1/aproplat/tags/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to update tag", error);
    return rejectWithValue(["Failed to update tag"]);
  }
});

export const deletePostTag = createAsyncThunk<
  { message: string; id: number },
  { id: number; token: string },
  { rejectValue: string[] }
>("deleteTag", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`${baseUrl()}/v1/aproplat/tags/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { message: response.data, id };
  } catch (error) {
    console.log("Failed to delete tag", error);
    return rejectWithValue(["Failed to delete tag"]);
  }
});
