import { Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import img2 from "../../assets/login/message.png";
import img1 from "../../assets/logo.jpg";

import {
  Buttons,
  ColorForm,
  FieldIconContainer,
  HeroLogin,
  InputChange,
  TextGridContainer,
  UserField,
} from "./PasswordReset.style";

const PasswordReset = () => {
  return (
    <>
      <HeroLogin>
        <img src={img1} alt="" style={{ margin: "10px auto 30px" }} />
        <ColorForm>
          <Typography variant="h6">Forgotten Password</Typography>

          <Typography pb={5} width="60%">
            Enter your email and we will send you instruction to rest your
            password
          </Typography>

          <Formik
            initialValues={{
              user_info: "",
              password: "",
              stayLogged: false,
            }}
            onSubmit={(values) => {
              console.log(values);
              // setSubmitting(true);
              // loginUser(values);
            }}
            validationSchema={Yup.object({
              user_info: Yup.string().required("Required"),
              password: Yup.string().required("Required"),
            })}
          >
            <Form>
              <InputChange>
                <FieldIconContainer>
                  <label htmlFor="inputName">
                    <img src={img2} alt="" />
                  </label>
                </FieldIconContainer>

                <UserField
                  name="user_info"
                  label=""
                  placeholder="Email@gmail.com"
                />
              </InputChange>

              {/* <InputChange>
                <FieldIconContainer>
                  <label htmlFor="inputPassword">
                    <Image src={img3} alt="" />
                  </label>
                </FieldIconContainer>
                <MyPasswordField
                  name="password"
                  placeholder="Password"
                  label=""
                />
              </InputChange> */}
              <TextGridContainer container>
                {/* <Grid item xs={5}>

                  <FormControlLabel
                    control={
                      <MyCheckBox name="stayLogged" label="stay logged in" />
                    }
                    label="Stay login"
                  />
                </Grid> */}
                {/* <Grid item xs={7}>
                  <ForgetContainer>
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "15px",
                        lineHeight: "18.29px",
                        color: "#1675B7",
                        cursor: "pointer",
                      }}
                    >
                      Forgoten Password?
                    </Typography>
                  </ForgetContainer>
                </Grid> */}
              </TextGridContainer>
              <Buttons
                variant="contained"
                type="submit"
                // disabled={isSubmitting}
              >
                {/* {isSubmitting ? <Progress size={24} /> : "Login"} */}
                Send
              </Buttons>
            </Form>
          </Formik>
        </ColorForm>
      </HeroLogin>
    </>
  );
};
export default PasswordReset;
