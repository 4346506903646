import { Checkbox, FormControlLabel } from "@mui/material";

interface SimpleCheckboxProps {
  checked: boolean;
  onChange: () => void;
  label: string;
}

const SimpleCheckbox = ({ checked, onChange, label }: SimpleCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};

export default SimpleCheckbox;