import { ProphetCard, ProphetCardImage, ProphetCol, ProphetContainer, ProphetText, ProphetTitle } from "./prophet.styles";

export default function Prophet(){
    return(<ProphetContainer><ProphetCard><ProphetCardImage></ProphetCardImage>
    <ProphetCol>
        <ProphetTitle>Prophet T.T Aldrian</ProphetTitle>
        <ProphetText>Prophet Tembeck Tiku Aldrian is a man called by God to be his representative on earth. He is an agent of God gifted by God to declare His counsel, to be an envoy,a watchman and a bearer of royal authority. Through the grace of God bestowed on him, many are delivered from the shackles of captivity through deliverance ,healing and by prophecy</ProphetText>
    </ProphetCol>
    
    </ProphetCard></ProphetContainer>)
}