import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { downloadPDF } from "./generateDocument";
import Letterhead from "./letterHead";
import { Class } from "./types/classManager";
import { useAppSelector } from "../redux/store";
import { Student } from "./types/userManagement";

interface ClassListProps {
  open: boolean;
  onClose: () => void;
  selectedClass: Class | null;
  selectedTerm: string;
  classListRef: HTMLDivElement | null;
  setClassListRef: (ref: HTMLDivElement | null) => void;
}

const ClassList: React.FC<ClassListProps> = ({
  open,
  onClose,
  selectedClass,
  classListRef: reportCardRef,
  setClassListRef: setReportCardRef,
}) => {
  const handleDownloadPDF = async () => {
    await downloadPDF(reportCardRef, null);
  };
  const { users } = useAppSelector((state) => state.users);

  const sequenceHeaders = [...Array(6)].map((_, index) => {
    const ordinalSuffix =
      index === 0 ? "st" :
        index === 1 ? "nd" :
          index === 2 ? "rd" :
            "th";

    return (
      <TableCell key={index} sx={{ border: "1px solid black", padding: "8px" }}>
        {index + 1}{ordinalSuffix} Seq
      </TableCell>
    );
  });
  return (
    <Dialog open={open} onClose={onClose} fullWidth
      fullScreen>
      <DialogTitle>Class List</DialogTitle>
      <DialogContent>
        {selectedClass && (
          <div ref={setReportCardRef}>
            <Letterhead classData={selectedClass} />
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              align="center"
              sx={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginBottom: 3,
                marginTop: 3,
              }}
            >
              Class List for {selectedClass.name}
            </Typography>

            <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
              <Table sx={{ minWidth: '100%', borderCollapse: 'collapse' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                      S/N
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                      Matricule
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                      Sex
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                      Date of Birth
                    </TableCell>
                    {sequenceHeaders}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users && users.filter((u: Student) => u.role === 'student' && u.classId === selectedClass.classId).map((s, index) => (
                    <TableRow key={s.scomap_id}>
                      <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                        {s.first_name} {s.middle_name} {s.last_name}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                        {s.matricule || ""}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                        {s.sex || ""}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                        {s.birthday
                          ? new Date(s.birthday).toLocaleDateString() // Format the date if available
                          : ''
                        }
                      </TableCell>
                      {sequenceHeaders.map((_, seqIndex) => (
                        <TableCell
                          key={seqIndex}
                          sx={{ border: "1px solid black", padding: "8px" }}
                        >

                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                  {[...Array(10)].map((_, index) => (
                    <TableRow key={`extra-${index}`}>
                      <TableCell sx={{ border: "1px solid black", padding: "8px" }}>
                        {users && users.filter((u: Student) => u.role === 'student' && u.classId === selectedClass.classId).length + index + 1}
                      </TableCell>
                      {[...Array(4 + 6)].map((_, cellIndex) => (
                        <TableCell
                          key={cellIndex}
                          sx={{ border: "1px solid black", padding: "8px" }}
                        />
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadPDF}
                className="download-pdf-button"
              >
                Download PDF
              </Button>
            </Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClassList;