import { createSlice } from "@reduxjs/toolkit";

import { addPost, deletePost, getPosts, updatePost } from "./postsService";
import { toast } from "react-toastify";
import { BlogPostsState } from "../../../utils/types/blogManagement";

const initialState: BlogPostsState = {
  posts: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const blogPostsSlice = createSlice({
  name: "blogPosts",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPosts.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })

      .addCase(getPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = null;
        state.posts = action.payload.data || [];
      })
      .addCase(getPosts.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to fetch posts!";
      })
      .addCase(addPost.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(addPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Post created successfully!";

        toast.success("Post created successfully!");
        const newPost = action.payload;

        state.posts = [...state.posts, newPost.data];
      })
      .addCase(addPost.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to add Post!";
        toast.error("Failed to add Post!");
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Post updated successfully!";
        toast.success("Post updated successfully!");

        const updatedPost = action.payload.data;

        const index = state.posts.findIndex(
          (post) => post.id === updatedPost.id
        );

        if (index !== -1) {
          state.posts[index] = updatedPost;
        }
      })
      .addCase(updatePost.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to update post";
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Post deleted successfully!";

        const deletedPostId = action.payload?.id;

        if (deletedPostId) {
          state.posts = state.posts.filter((post) => post.id !== deletedPostId);
        }
      })
      .addCase(deletePost.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to delete Post";
      });
  },
});

export default blogPostsSlice.reducer;
