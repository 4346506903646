import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import {TcontactForm,TcontactMessage} from  "../../utils/types";
import { baseUrl as baseUrl } from "../../utils/baseUrl";


export const contactForm = createAsyncThunk<
TcontactForm,
TcontactMessage
>("auth/contactForm", async (contact_messages, { rejectWithValue}) => {
  const data = { contact_message: contact_messages };
  try {
    const response = await axios.post(`${baseUrl()}/v1/contact_messages`, data);
    console.log("got response", response);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<unknown, AxiosResponse<unknown>>;
    const responseData = axiosError.response?.data as { errors?: string[] };
    const responseMessage = axiosError.response?.data as { message?: string[] };
    const errorMessage = responseMessage?.message;
    return rejectWithValue(responseData?.errors || errorMessage || []);
  }
});

