
import { CardContainer, CardContentText, CardImage, CardRow, CardTitle, HeroTwoContainer, HeroTwoContainerOverlay,CardContent, CardHeaderTitle } from "./herotwo.styles";
import img1 from '../../../../assets/images/3.jpg';
import img2 from '../../../../assets/images/7.jpg';
import img3 from '../../../../assets/images/coll.jpeg';
import img4 from '../../../../assets/images/10.jpg';
export default function HeroTwo(){
    return(<HeroTwoContainer><HeroTwoContainerOverlay>
        <CardHeaderTitle>Our Acheivements</CardHeaderTitle>
        <CardContainer>
            <CardRow>
                <CardContent><CardTitle>TEC GLOBAL</CardTitle>
                <CardImage style={{backgroundImage:`url(${img1})`}}></CardImage><CardContentText>An all in one application that makes the world a global village by reconnecting christians from all over the globe into one app</CardContentText></CardContent>
           
                <CardContent><CardTitle>SPECIAL MESSAGES</CardTitle>
                <CardImage style={{backgroundImage:`url(${img2})`}}></CardImage><CardContentText>Building up kingdom militants through teachings of God's word,moreso enlightening us about the unknown day and hour of the coming of Our Savior</CardContentText></CardContent>
                <CardContent><CardTitle>PATNERSHIPS</CardTitle>
                <CardImage style={{backgroundImage:`url(${img3})`}}></CardImage><CardContentText> The Empowerment Foundation envisions people from all phases of life to be able to fulfill their God-given potential,thus providing them with basic needs and training them to be fruitful agents of change</CardContentText></CardContent>
           
           
           
            </CardRow>
            <CardRow>
                <CardContent><CardTitle>MINISTRY NEWS</CardTitle>
                <CardImage style={{backgroundImage:`url(${img1})`}}></CardImage><CardContentText> Stay connected to the latest events and happenings taking place in The Empowerment Revolution</CardContentText></CardContent>
           
                <CardContent><CardTitle>UNENDING PRAISE</CardTitle>
                <CardImage style={{backgroundImage:`url(${img4})`}}></CardImage><CardContentText> Glorious voices in appraisal of God's unfailing mercy and goodness upon mankind.Don't miss this great opportunity to experience God in another dimension</CardContentText></CardContent>
                <CardContent><CardTitle>TEC GLOBAL</CardTitle>
                <CardImage style={{backgroundImage:`url(${img1})`}}></CardImage><CardContentText>An all in one application that makes the world a global village by reconnecting christians from all over the globe into one app</CardContentText></CardContent>
           
           
           
            </CardRow>
        </CardContainer>
        
        
        
        
        
        
        
        
        
        
        
        </HeroTwoContainerOverlay></HeroTwoContainer>)
}