export enum InputType {
  labelInput,
  datepicker,
  checkbox,
  countryInput,
  cityInput,
  stateInput,
  autocomplete,
  password,select, image
}
