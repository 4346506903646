import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {baseUrl} from "../../utils/baseUrl";
import { Newsletter, AddNewsletter } from "../../utils/types/newsletters";


export const getNewsletters = createAsyncThunk<
  Newsletter[] | null,
  string,
  { rejectValue: string[] }
>("newsletters", async (token, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${baseUrl()}/v1/aproplat/newsletter_contacts`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Failed to fetch newsletter", error);
    return rejectWithValue(["Failed to fetch data"]);
  }
});

export const addNewsletter = createAsyncThunk<
  Newsletter,
  { data: AddNewsletter; token: string },
  { rejectValue: string[] }
>("addNewsletter", async ({ data, token }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${baseUrl()}/v1/aproplat/newsletter_contacts`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to create newsletter", error);
    return rejectWithValue(["Failed to create newsletter"]);
  }
});

export const updateNewsletter = createAsyncThunk<
  Newsletter,
  { data: AddNewsletter; token: string; id: number },
  { rejectValue: string[] }
>("updateNewsletter", async ({ data, token, id }, { rejectWithValue }) => {
  try {
    const response = await axios.patch(
      `${baseUrl()}/v1/aproplat/newsletter_contacts/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Failed to update newsletter", error);
    return rejectWithValue(["Failed to update newsletter"]);
  }
});

export const deleteNewsletter = createAsyncThunk<
  { message: string; id: number },
  { id: number; token: string },
  { rejectValue: string[] }
>("deleteNewsletter", async ({ id, token }, { rejectWithValue }) => {
  try {
    const response = await axios.delete(
      `${baseUrl()}/v1/aproplat/newsletter_contacts/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { message: response.data, id };
  } catch (error) {
    console.log("Failed to delete newsletter", error);
    return rejectWithValue(["Failed to delete newsletter"]);
  }
});
