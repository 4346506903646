import { StyledDatePicker } from "./DatePickerInput.style";
import { Container, Label } from "../LabelInput/LabelInput.style";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useField } from "formik";
import dayjs from "dayjs";

interface IDatePickerInput {
  label: string;
  name: string;
}

const DatePickerInput = ({ label, name, ...props }: IDatePickerInput) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Container>
      <Label>{label}</Label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          {...field}
          {...props}
          value={field.value ? dayjs(field.value) : null}
          onChange={(date) => {
            console.log(date);
            const formattedDate = date ? date.format("YYYY-MM-DD") : null; // Format to YYYY-MM-DD
            helpers.setValue(formattedDate);
          }}
        />
      </LocalizationProvider>
      {meta.touched && meta.error ? (
        <p
          className="error"
          style={{
            color: "red",
            display: "flex",
            justifyContent: "start",
            fontSize: "14px",
          }}
        >
          {meta.error}
        </p>
      ) : null}
    </Container>
  );
};

export default DatePickerInput;
