import { Box, Card, Icon, styled, Typography } from "@mui/material";

export const CardsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    marginTop: '-4%',
    marginLeft: '10%',
    marginBottom: '5%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack cards vertically on smaller screens
        alignItems: 'center',
        marginLeft: '1%',
    },
}));

export const CardsRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

export const CardsContent = styled(Card)(({ theme }) => ({
    marginLeft: '2%',
    padding: '3%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'creamWhite',
    borderRadius: '10px',
    minWidth: '10%',
    maxWidth: '30%',
    width: '25%','&:hover':{transform:'scale(1.1)',},
    [theme.breakpoints.down('md')]: {
      minWidth: '50%',
      maxWidth: '100%',
      width: '85%',marginBottom:'6%' // Make cards full-width on smaller screens
        
    },
}));

export const CardsContentText = styled(Card)(({ theme }) => ({
    marginLeft: '2%',
    padding: '3%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'creamWhite',
    borderRadius: '10px',
    minWidth: '40%',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        width: '100%', // Make cards full-width on smaller screens
        margin: '10px 0',
    },
}));

export const CardsIcon = styled(Icon)(({ theme }) => ({
    backgroundColor: 'blueviolet',
    color: 'white',
    borderRadius: '200px',
    height: '60px',
    width: '60px',
    marginLeft: '40%',
    marginTop: '2%',
    zIndex: '1',
    [theme.breakpoints.down('md')]: {
        marginLeft: '45%', // Adjust icon position for smaller screens
    },
}));

export const CardsTitle = styled(Typography)(({ theme }) => ({
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem', // Adjust font size for smaller screens
    },
}));

export const CardsText = styled(Typography)(({ theme }) => ({
    color: 'black',
    fontSize: '1rem',
    fontWeight: '200',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem', // Adjust font size for smaller screens
    },
}));