import faceBook from "../../../assets/facebook.png";
import insta from "../../../assets/instagram.png";
import linkdin from "../../../assets/linkdin.png";
import tweet from "../../../assets/twiter.png";
import whatsapp from "../../../assets/whatup.png";

import { Box, Button, Grid, Typography } from "@mui/material";
import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Map from "../Map";
import {
  FooterAddress,
  FooterContainer,
  FooterHeadings,
  FooterLinks,
  FooterWrapper,
  FotterItems,
  InputField,
  NewsInput,
  Progress,
  SocialLinks,
} from "./Footer.styles";
import { Link } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { refreshState } from "../../../redux/newsLetterSubscription/newsLetterSlice";
import { newsLetter } from "../../../redux/newsLetterSubscription/newsLetterService";

const Footer = () => {
  const [email, setEmail] = useState({ email: "" });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<RootState, void, UnknownAction>>();

  const { isLoading, hasError, error, successMessage } = useSelector(
    (state: RootState) => state.newsLetter
  );
  useEffect(() => {
    if (!isLoading && isSubmitting) {
      if (hasError && error) {
        toast.error(`${error}`);
      } else if (successMessage) {
        const { message } = successMessage;
        if (message === "Validation failed!") {
          toast.error("Your Email already subscribed!");
        } else {
          toast.success(message);
        }

        setSubmitting(false);
        dispatch(refreshState());
      }
    }
  }, [dispatch, error, hasError, isLoading, isSubmitting, successMessage]);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputEmail = e.target.value;
    setEmail({ email: inputEmail });

    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(inputEmail));
  };

  const handleSubmit = () => {
    if (!isValidEmail) {
      return null;
    } else {
      setSubmitting(true);
      dispatch(newsLetter(email));
      setEmail({ email: "" });
    }
  };
  return (
    <Box>
      <FooterContainer>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={2.4} sm={4}>
            <FotterItems>
              <FooterHeadings variant="h3">
                Social Media Sections
              </FooterHeadings>
              <SocialLinks>
                <img
                  src={faceBook}
                  alt="faceBook"
                  style={{
                    width: "16px",
                    height: "16px",
                    objectFit: "contain",
                  }}
                />
                <FooterLinks variant="body1">Facebook</FooterLinks>
              </SocialLinks>
              <SocialLinks>
                <img
                  src={insta}
                  alt="Instagram"
                  style={{
                    width: "17px",
                    height: "17px",
                    objectFit: "contain",
                  }}
                />
                <FooterLinks variant="body1">Instagram</FooterLinks>
              </SocialLinks>
              <SocialLinks>
                <img
                  src={tweet}
                  alt="Twitter"
                  style={{
                    width: "16px",
                    height: "16px",
                    objectFit: "contain",
                  }}
                />
                <FooterLinks variant="body1">Twitter</FooterLinks>
              </SocialLinks>
              <SocialLinks>
                <img
                  src={whatsapp}
                  alt="Whatsapp"
                  style={{
                    width: "17px",
                    height: "17px",
                    objectFit: "contain",
                  }}
                />
                <FooterLinks variant="body1" ><Link to={"https://wa.link/tlaieo"}>Whatsapp</Link></FooterLinks>
              </SocialLinks>
              <SocialLinks>
                <img
                  src={linkdin}
                  alt="Linkedln"
                  style={{
                    objectFit: "contain",
                    width: "12px",
                    aspectRatio: "1/1",
                    height: "max-content",
                    marginTop: "-5px",
                  }}
                />
                <FooterLinks variant="body1">Linkedln</FooterLinks>
              </SocialLinks>
            </FotterItems>
          </Grid>
          <Grid item xs={12} md={2.4} sm={4}>
            <FotterItems style={{ gap: "5px" }}>
              <FooterHeadings variant="h3">Links</FooterHeadings>
         
      
              <FooterLinks variant="body1">
                <Link to="/Contact">Contact Us</Link>
              </FooterLinks>
              <FooterLinks variant="body1">
                <Link to="/about"> About Us</Link>
              </FooterLinks>
     
            </FotterItems>
          </Grid>
          <Grid item xs={12} md={2.4} sm={4}>
            <FotterItems>
              <FooterHeadings variant="h3">Address</FooterHeadings>
              <FooterAddress>
               Mile One,Kumba
              </FooterAddress>
              <FooterAddress variant="body1">
                Email: contact@empowermentrevolution.org
              </FooterAddress>
              <FooterAddress variant="body1">
                Phone: +237 679015081
              </FooterAddress>
            
            </FotterItems>
          </Grid>
          <Grid item xs={12} md={2.4} sm={4}>
            <FotterItems>
              <FooterHeadings variant="h3">Newsletter</FooterHeadings>
              <FooterAddress variant="body1">
                Subscribe to Our Mail Services
              </FooterAddress>
              <NewsInput>
                <InputField
                  sx={{
                    "&:after": { display: "none" },
                    "&:before": { display: "none" },
                    height: "28px",
                    borderColor: isValidEmail ? "" : "red",
                  }}
                  placeholder="email@gmail.com"
                  type="email"
                  value={email.email}
                  onChange={handleInputChange}
                />

                <Button
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    height: "28px",
                    border: "1px solid blueViolet",
                    textTransform: "none",
                    padding: "0 20px",
                    backgroundColor :'blueviolet'
                  }}
                  disableRipple={true}
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                  variant="contained"
                  onClick={handleSubmit} 
                  
                  // disabled={isSubmitting}
                  disabled={!isValidEmail}
                >
                  {isSubmitting ? <Progress size={24} /> : "Subscribe"}
                </Button>
              </NewsInput>
            </FotterItems>
          </Grid>
          <Grid item xs={12} md={2.4} sm={8}>
            <FotterItems>
              <FooterHeadings variant="h3">Location</FooterHeadings>

              <Map />
            </FotterItems>
          </Grid>
        </Grid>
      </FooterContainer>
      <FooterWrapper>
        <Typography
          variant="body1"
          sx={{
            fontSize: "12px",
            fontWeight: "300",
            fontFamily: "Montserrat",
          }}
        >
          © Copyright The Empowerment Revolution 2024. All Rights Reserved
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "12px",
            fontWeight: "300",
            fontFamily: "Montserrat", 
          }}
        >
          Designed by <span style={{ color: "#21AAE1" }}> <Link to={'#'}>Infinite Web Revolution @+237652718184</Link> </span> 
        </Typography>
      </FooterWrapper>
    </Box>
  );
};

export default Footer;
