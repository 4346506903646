import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "15px",
  fontWeight: 500,
  borderRadius: "14px",
  height: "40px",
  minWidth: "110px",
  "&.Mui-disabled": {
    backgroundColor: "#eee",
  },
  [theme.breakpoints.down("sm")]: {
    height: "32px",
    width: "78px",
  },
}));
