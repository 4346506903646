import { useEffect, useState } from "react";
import { useField } from "formik";
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  TextField,
  Box,
} from "@mui/material";
import SimpleCheckbox from "../../inputs/ListSelect/simplchechbox";


interface DataItem {
  id: string;
  name: string;
  [key: string]: any;
}

interface Props {
  data: DataItem[];
  name: string;
  label: string;
  type?: "students" | "subjects" | "other";
}

const ListSelectDirect = ({ data, name, label, type }: Props) => {
  const [items, setItems] = useState<DataItem[]>([]);
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    setItems([...data]);
  }, [data]);

  const handleToggle = (itemId: string) => {
    helpers.setValue(
      field.value?.includes(itemId)
        ? field.value.filter((id: string) => id !== itemId)
        : [...(field.value || []), itemId]
    );
  };

  return (
    <Box>
      <List>
        {items.map((item) => (
          <ListItem key={item.id} disablePadding  onClick={() => handleToggle(item.id.toString())}>
            <ListItemAvatar>
              <Avatar>{item.name.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.name} />
            <SimpleCheckbox
              checked={field.value?.includes(item.id.toString()) || false}
              onChange={() => handleToggle(item.id.toString())}
              label=""
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ListSelectDirect;