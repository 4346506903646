import { Box, Button, Card, Input, MenuItem, Select, styled, Typography } from "@mui/material";
import img1 from '../../../assets/images/tec.png'
export const GiveContainer = styled(Card)(({theme})=>({marginTop:'2%',
    display:'flex',marginLeft:'10%',marginBottom:'4%',
    flexDirection:'column',minWidth:'50%',maxWidth:'100%',width:'80%',borderRadius:'10px', backgroundColor: 'rgba(138,43,226,0.2)',
}))

export const GiveTitle = styled(Typography)(({theme})=>({
    fontSize:'2rem',fontWeight:'bold',color:'blueviolet',textAlign:'center',padding:'2%',marginBottom:'2%',
    [theme.breakpoints.down('md')]: {
      fontSize:'1rem'
    }
}))
export const Giveinput = styled(Input)(({theme})=>({
    height: '7vh',
    marginTop :'1%',
    marginBottom :'0%',
    boxShadow :'0px 2px 5px rgba(0,0,0,0.2)',
    marginRight : '1rem',
    width :'80%',minWidth:'30%',maxWidth:'90%',
    backgroundColor :'white',
    border :'1px solid blueViolet',
    marginLeft :'10%',
    paddingLeft :'1%',
    borderRadius :'0px',
     [theme.breakpoints.down('md')]: {
       borderRadius:'1px',fontSize:'0.5rem',paddingLeft:'2%'
      } 
  }))

  export const GiveSelect = styled(Select)(({theme})=>({
    height: '7vh',
    marginTop :'1%',
    marginBottom :'0%',
    boxShadow :'0px 2px 5px rgba(0,0,0,0.2)',
    marginRight : '1rem',
    width :'80%',minWidth:'30%',maxWidth:'90%',
    backgroundColor :'white',
    border :'1px solid blueViolet',
    marginLeft :'10%',
    paddingLeft :'1%',
    borderRadius :'0px',
     [theme.breakpoints.down('md')]: {
       borderRadius:'1px',fontSize:'0.5rem',paddingLeft:'2%'
      } 
  }))
  
  export const GiveOption = styled(MenuItem)(({theme})=>({
    height: '7vh',
    marginTop :'1%',
    marginBottom :'0%',
    boxShadow :'0px 2px 5px rgba(0,0,0,0.2)',
    marginRight : '1rem',
    width :'80%',minWidth:'30%',maxWidth:'90%',
    backgroundColor :'white',
   
    marginLeft :'7%',
    paddingLeft :'1%',
    borderRadius :'0px',
     [theme.breakpoints.down('md')]: {
       borderRadius:'1px',fontSize:'0.5rem',paddingLeft:'2%'
      } 
  }))
  export const GiveButtontwo = styled(Button)(({ theme }) => ({
    color: 'white',marginTop:'3%',marginBottom:'6%',
    fontSize: '1rem',
    height: '5%',
    border: '1px solid white',
    width: '30%',
    minWidth: '10%',
    maxWidth: '80%', 
    paddingLeft: '1%',marginLeft:'34%',background:'linear-gradient(to bottom,black,white)',
    [theme.breakpoints.down('md')]: {
        width: '45%', // Make buttons full-width on smaller screens
        marginLeft:'25%',height:'6vh',fontSize:'0.8rem', paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));
export const Giveimage = styled(Box)(({theme})=>({marginLeft:'45%',marginTop:'2%',
    height:'20vh',minWidth:'10%',maxWidth:'30%',width:'30%',backgroundImage:`url(${img1})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'

  ,  [theme.breakpoints.down('md')]: {
  // Make buttons full-width on smaller screens
      marginLeft:'30%',
  }

  }))
