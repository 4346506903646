import { AddButton } from "./Dashboard.styles";
import { Typography } from "@mui/material";

interface IAddUserButtonProps {
  onClick: () => void;
  text: string;
}

const AddUserButton = ({ onClick, text }: IAddUserButtonProps) => {
  return (
    <AddButton disableRipple onClick={onClick}>
      <Typography>Add {text}</Typography>
    </AddButton>
  );
};

export default AddUserButton;
