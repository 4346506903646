import { ReactNode } from "react";

import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

interface Props {
  children?: ReactNode;
  path: string;
  active?: boolean;
}

const NavLink = ({ children, path, active }: Props) => {

  return (
    <CustomLink to={path} data-active={active ? "true" : "false"}> 
      {children}
    </CustomLink>
  );
};
export default NavLink;

const CustomLink = styled(Link)<{ active?: string }>(({ active }) => ({
  color: active === "true" ? "#ff0000" : "#000000",
}));