import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@mui/material";

export const Label = styled(Typography)(() => ({
  color: "#8A9099",
  fontFamily: "Poppins",
  fontSize: "14px",
  marginBottom: "6px",
}));

export const Container = styled(Box)(() => ({
  width: "100%",
}));

export const Input = styled(TextField)(() => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    width: "100%",
    borderColor: "#E8E9EB",
    borderRadius: "14px",
    height: "40px",
    paddingLeft: "16px",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#3F434A",
    "::placeholder": {
      color: "#8A9099",
    },
  },
  input: {
    padding: "0px",
  },
}));

export const InputAdornmentText = styled(InputAdornment)(() => ({
  p: {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
}));
