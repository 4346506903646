import { Box, Card, styled, Typography } from "@mui/material";

export const EventTitle = styled(Typography)(({ theme }) => ({
    color: 'blueViolet',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'serif',
    [theme.breakpoints.down('md')]: {
        fontSize: '2rem', // Adjust font size for smaller screens
    },
}));

export const EventsText = styled(Typography)(({ theme }) => ({
    color: 'black',
    fontSize: '1rem',
    fontWeight: '200',
    textAlign: 'start',
    marginLeft: '2%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem', // Adjust font size for smaller screens
    },
}));

export const EventsImage = styled(Box)(({ theme }) => ({
    marginLeft: '2%',
    padding: '3%',
    height: '45vh',
    backgroundSize: 'cover', // Ensure image scales proportionally
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: '10px',
    minWidth: '90%',
    borderBottomLeftRadius: '10px',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        height: '45vh', // Adjust height for smaller screens
    },
}));

export const EventsContainer = styled(Box)(({theme}) => ({
    width: "100%",
    minWidth: '80%',
    maxWidth: '100%',
    margin: "3rem",
    height: '50vh',
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: 'space-between',
    marginLeft: '-30%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack elements vertically on smaller screens
        alignItems: 'center',
        marginLeft: '0%',
    },
}));

export const EventsSlide = styled(Box)(({theme}) => ({
    width: "100%",
    minWidth: '10%',
    maxWidth: '100%',
    height: '70vh',
    marginLeft: '-20%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: '0%',
    },
}));

export const EventsColumn = styled(Box)(({ theme }) => ({
    marginLeft: '12%',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '95%',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        marginLeft: '0%',
    },
}));

export const EventCard = styled(Card)(({ theme }) => ({
    height: '60vh',
    maxHeight: '70vh',
    minHeight: '20vh',
    marginBottom: '7%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'creamWhite',
    borderRadius: '10px',
    minWidth: '50%',
    maxWidth: '100%',
    width: '75%',
    marginLeft: '15%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
        width: '100%',height:'90vh', // Make cards full-width on smaller screens
        marginLeft: '0%',
    },
}));