import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  addUser,
  deleteUser,
  getUsers,
  modifyUser,

} from "./userManagementService";
import { UsersState } from "../../utils/types/userManagement";

const initialState: UsersState = {
  users: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = null;
        state.users = action.payload || [];
      })
      .addCase(getUsers.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to fetch users!";
      })

      .addCase(addUser.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.hasError = false;
        state.hasWarning = false;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "Users created successfully!";
        toast.success("Users created successfully!");
        const user = action.payload;
        state.users = [...state.users, user];
      })
      .addCase(addUser.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to create User!";
      })
      .addCase(modifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(modifyUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "User modified successfully!";
        toast.success("User modified successfully!");

        const modifiedUser = action.payload;
        const index = state.users.findIndex(
          (user) => user.id === modifiedUser.id
        );
        if (index !== -1) {
          state.users[index] = modifiedUser;
        }
      })
      .addCase(modifyUser.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to modify User";
      })

      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.hasWarning = false;
        state.hasError = false;
        state.error = null;
        state.successMessage = "User deleted successfully!";

        const deletedUserId = action.payload?.id;

        if (deletedUserId) {
          state.users = state.users.filter((user) => user.id !== deletedUserId);
        }
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = "Failed to delete User";
      });
  },
});

export default usersSlice.reducer;
